$speed: .5s;
$function: linear;
$property: all;

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 999;

  &_logo {
    fill: #E6EAEB;
    width: 40px;
    position: relative;
  }
  i {
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: 100%;
    background: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-property: $property;
    transition-timing-function: $function;
    transition-duration: $speed;
    &:nth-child(2) {
      left: 20%;
      transition-duration: $speed * .9;
    }
    &:nth-child(3) {
      left: 40%;
      transition-duration: $speed * .8;
    }
    &:nth-child(4) {
      left: 60%;
      transition-duration: $speed * .9;
    }
    &:nth-child(5) {
      left: 80%;
      transition-duration: $speed;
    }
  }
  &.load-done {
    pointer-events: none;
    //visibility: hidden;
    i {
      transform: translateY(-100%);
      background: #E6EAEB  !important;
    }
  }
}

.header_logo {
  position: fixed;
  z-index: 110;
  width: 142px;
  left: 65px;
  top: 61px;
  transition: all 0.5s;
  @include respond-to(d) {
    width: 102px;
    left: 42px;
    top: 43px;
  }
  @include respond-to(t) {
    background: #175059;
    height: 64px;
    top: 25px;
    left: 27px;
    width: calc(100% - 54px);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
  }
  @include respond-to(m) {
    height: 48px;
    left: 7px;
    top: 8px;
    width: calc(100% - 14px);
  }
  svg {
    display: block;
    fill: #fff;
    transition: fill .5s;
    .hide {
      transition: opacity .5s;
    }
    @include respond-to(t) {
      width: 100px;
      height: 40px;
    }
    @include respond-to(t) {
      width: 80px;
      height: 30px;
    }
  }


  &.active {
    @include respond-to(desc) {
      svg {
        fill: #175059;
      }
    }
  }
  &.scroll {
    @include respond-to(desc) {
      svg {
        fill: #fff;
      }
      .hide {
        opacity: 0;
        visibility: hidden;
      }

    }
  }
  &.open-menu {
    @include respond-to(desc) {
      svg {
        fill: #fff;
      }
      .hide {
        opacity: 1;
        visibility: visible;
      }

    }
  }
}

.header_callback {
  position: fixed;
  z-index: 105;
  right: 29px;
  top: 28px;
  font-size: 20px;
  // color: #7E9091;
  cursor: pointer;
  font-family: $FontIBMPlexSansSemiBold;
  // transition: opacity .5s;
  width: 100px;
  height: 100px;
  background: #175059;
  @include hover {
    background: #326B74;
  }
  @include respond-to(d) {
    // font-size: 14px;
     right: 19px;
     top: 18px;
     width: 74px;
     height: 74px;
  }
  @include respond-to(t) {
    background: none;
    height: 64px;
    width: 64px;
    right: 27px;
    top: 25px;
    color: #fff;
  }
  @include respond-to(m) {
    height: 48px;
    width: 48px;
    right: 7px;
    top: 8px;
  }
  svg {
    display: block;
    fill: #fff;
    width: 24px;
    height: 24px;
    @include position(center);
    // fill: #175059;
    @include respond-to(m) {
      // display: block;
      width: 15px;
      height: 15px;
      // fill: #fff;
    }
  }
  span {
    display: block;
    @include respond-to(m) {
      display: none;
    }
  }

}

.header-menu {
  position: fixed;
  z-index: 105;
  width: 108px;
  left: 29px;
  top: 28px;
  height: calc(100% - 56px);
  transition: background .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond-to(d) {
    width: 74px;
    left: 19px;
    top: 18px;
    height: calc(100% - 36px);
  }
  @include respond-to(t) {
    width: 64px;
    height: 64px;
    left: 27px;
    top: 25px;
  }
  @include respond-to(m) {
    width: 48px;
    height: 48px;
    left: 7px;
    top: 8px;
  }
  &_btn {
    width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 10;
    @include respond-to(d) {
      height: 74px;
    }
    @include respond-to(t) {
      height: 100%;
      background: #175059;
    }
    @include respond-to(m) {
      height: 100%;
    }
    i {
      display: block;
      width: 13px;
      height: 3px;
      background: #7E9091;
      margin-bottom: 5px;
      transition: all .3s;
      
      &.not-news {
        background: #fff;
      }
      @include respond-to(d) {
        width: 10px;
        height: 2px;
        margin-bottom: 4px;
      }

      @include respond-to(t) {
        width: 14px;
        height: 3px;
        margin-bottom: 5px;
        background: #E6EAEB;
      }
      @include respond-to(m) {
        width: 10px;
        height: 2px;
        margin-bottom: 4px;
        background: #E6EAEB;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

  }
  &_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    visibility: hidden;
    background: #0B363C;
    transition: width .5s .25s, height 0s 1s, visibility 1s;
  }
  &_content {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 58px);
    height: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 35px -25px #000;
    @include respond-to(d) {
      width: calc(100vw - 38px);
    }
    @include respond-to(t) {
      overflow: auto;
      display: block;
      width: calc(100vw - 54px);
      height: calc(100vh - 50px);
    }
    @include respond-to(m) {
      width: calc(100vw - 14px);
      height: 100%;

    }
  }
  &.scroll {
    background: #12474F;
    @include respond-to(t) {
      background: #326B74;
    }
    .header-menu_btn i {
      @include respond-to(t) {
        background: #E6EAEB;
      }
      background: #3E717F;
    }
  }
  &.active {
    .header-menu {
      &_btn {
        background: none;
        i {
          background: #3E717F;
          margin: 0;
          width: 20px;
          &:first-child {
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            height: 0;
          }
          &:last-child {
            transform: rotate(45deg);
            margin-top: -3px;
            @include respond-to(d) {
              margin-top: -2px;
            }
            @include respond-to(t) {
              margin-top: -3px;
            }
            @include respond-to(m) {
              margin-top: -2px;
            }
          }
        }
      }
      &_li {
        transform: none;
        flex: 1;
        &:nth-child(1) {
          transition: transform .5s;
        }
        &:nth-child(2) {
          transition: transform .5s .05s;
        }
        &:nth-child(3) {
          transition: transform .5s .1s;
        }
        &:nth-child(4) {
          transition: transform .5s .15s;
        }
        &:nth-child(5) {
          transition: transform .5s .2s;
        }
        &:nth-child(6) {
          transition: transform .5s .25s;
        }

      }
      &_lang_li {
        transform: none;
        transition: transform .5s .5s, opacity .5s .5s;
        opacity: 1;
      }
    }

    .header-menu_wrap {
      transition: width .5s, height 0s, visibility 0s;
      visibility: visible;
      width: calc(100vw - 58px);
      @include respond-to(d) {
        width: calc(100vw - 38px);
      }
      @include respond-to(t) {
        width: calc(100vw - 54px);
        height: calc(100vh - 50px);

      }
      @include respond-to(m) {
        width: calc(100vw - 14px);
        height: calc(100vh - 16px);

      }
    }
    .header-menu_content {
      opacity: 1;
      transition: opacity .5s;
    }
  }
  &_ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    line-height: 54px;
    height: 54px;
    padding: 0 100px;
    @include respond-to(d) {
      line-height: 34px;
      height: 34px;
    }
    @include respond-to(t) {
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding: 80px 0;
      min-height: 100vw;
    }
    @include respond-to(m) {
      padding: 40px 0;
      padding-bottom: 0;
      height: calc(100% - 160px);
      min-height: calc(100vw - 160px);
    }
  }
  &_li {
    display: block;
    transform: translateY(50px);
    position: relative;
    i {
      @include position(center);
      z-index: -1;
      pointer-events: none;
      @include respond-to(d) {
        @include position(center, .7);
      }
      @include respond-to(t) {
        display: none;
      }
      &:before, &:after {
        content: '';
        position: absolute;
        background-size: cover;
        background-position: center top;
        width: 100px;
        height: 100px;
        opacity: 0;
        transform: translatey(50px);
        transition: opacity .5s, transform .5s;
      }
      &:after {
        transform: translatey(-50px);
      }
    }
    &:nth-child(6) {
      transition: transform .5s;
      i {
        &:before {
          top: -36px;
          right: calc(50% + 5px);
          width: 49px;
          height: 157px;
          background-image: url("../img/general/menu/6-1.png");
        }
        &:after {
          top: -177px;
          left: calc(50% + 19px);
          width: 107px;
          height: 267px;
          background-image: url("../img/general/menu/6-2.png");
        }
      }
    }
    &:nth-child(5) {
      transition: transform .5s .05s;
      i {
        &:before {
          top: -190px;
          right: calc(50% - 8px);
          width: 128px;
          height: 232px;
          background-image: url("../img/general/menu/5-1.png");
        }
        &:after {
          top: -90px;
          left: calc(50% + 22px);
          width: 64px;
          height: 182px;
          background-image: url("../img/general/menu/5-2.png");
        }
      }
    }
    &:nth-child(4) {
      transition: transform .5s .1s;

      i {
        &:before {
          top: -100px;
          right: calc(50% + 10px);
          width: 125px;
          height: 243px;
          background-image: url("../img/general/menu/4-1.png");
        }
        &:after {
          top: -30px;
          left: calc(50% + 10px);
          width: 54px;
          height: 147px;
          background-image: url("../img/general/menu/4-2.png");
        }
      }
    }
    &:nth-child(3) {
      transition: transform .5s .15s;

      i {
        &:before {
          top: -94px;
          right: calc(50% + 32px);
          width: 64px;
          height: 192px;
          background-image: url("../img/general/menu/3-1.png");
        }
        &:after {
          top: -150px;
          left: calc(50% - 20px);
          width: 117px;
          height: 272px;
          background-image: url("../img/general/menu/3-2.png");
        }
      }
    }
    &:nth-child(2) {
      transition: transform .5s .2s;

      i {
        &:before {
          top: -194px;
          right: calc(50% - 47px);
          width: 117px;
          height: 293px;
          background-image: url("../img/general/menu/2-1.png");
        }
        &:after {
          top: -150px;
          left: calc(50% + 72px);
          width: 54px;
          height: 172px;
          background-image: url("../img/general/menu/2-2.png");
        }
      }

    }
    &:nth-child(1) {
      transition: transform .5s .25s;
      i {
        &:before {
          top: -60px;
          right: calc(50% + 37px);
          width: 64px;
          height: 182px;
          background-image: url("../img/general/menu/1-1.png");
        }
        &:after {
          top: -160px;
          left: calc(50% - 16px);
          width: 128px;
          height: 232px;
          background-image: url("../img/general/menu/1-2.png");
        }
      }

    }
    &.active {
      .header-menu_a {
        color: #fff;
        &:after {
          width: 65px;
          @include respond-to(m) {
            width: 45px;
          }
        }
        i {
          &:before, &:after {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }
  &_a {
    display: block;
    text-align: center;
    color: #326B74;
    font-size: 34px;
    font-family: $FontIBMPlexSansMedium;
    transition: color .5s;
    position: relative;
    z-index: 2;
    @include respond-to(d) {
      font-size: 24px;

    }
    @include respond-to(t) {
      br {
        display: none;
      }
    }
    @include respond-to(m) {
      font-size: 16px;

    }
    &:after {
      content: '';
      display: block;
      height: 4px;
      width: 0;
      background: #fff;
      transition: width .5s;
      margin: auto;
      margin-top: 10px;
      @include respond-to(d) {
        height: 3px;
      }
      @include respond-to(m) {
        height: 2px;
        margin-top: 0;
      }
    }
    @include hover {
      color: #ffffff;
      &:after {
        width: 65px;
      }
      i {
        &:before, &:after {
          opacity: 1;
          transform: none;
        }
      }
    }

  }
  &_lang {
    position: absolute;
    bottom: 35px;
    right: 38px;
    display: flex;
    z-index: 2;
    @include respond-to(d) {
      bottom: 23px;
      right: 30px;
    }
    @include respond-to(t) {
      position: fixed;
      bottom: 70px;
      right: 73px;
    }
    @include respond-to(m) {
      position: relative;
      bottom: auto;
      right: auto;
      margin: auto;
      height: 60px;
      justify-content: center;
      align-items: center;
    }
    &_li {
      display: block;
      margin-right: 30px;
      transition: transform .5s, opacity .5s;
      transform: translateY(50px);
      opacity: 0;
      @include respond-to(d) {
        margin-right: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        .header-menu_lang_a {
          color: #3E717F;
        }
      }
    }
    &_a {
      display: block;
      color: #175059;
      font-size: 20px;
      font-family: $FontIBMPlexSansSemiBold;
      transition: color .5s;
      @include respond-to(d) {
        font-size: 14px;
      }
      @include hover {
        color: #3E717F;
      }

    }
  }
}

.id-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: rotate(-90deg);
  white-space: nowrap;
  z-index: 10;
  @include respond-to(t) {
    display: none;
  }
  span {
    margin-right: 18px;
  }
  &-item {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity .5s;
    font-size: 17px;
    line-height: 18px;
    font-family: $FontIBMPlexSansSemiBold;
    text-transform: uppercase;
    color: #447076;
    @include respond-to(d) {
      font-size: 13px;
    }
    &.active {
      opacity: 1;
    }
  }
  &.open-menu {
    .id-text-item {
      opacity: 0;
      &.id-menu {
        opacity: 1;
      }
    }
  }
}
