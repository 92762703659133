.social-resp_section {
  height: auto;
  margin-bottom: 250px;
  @include respond-to(d) {
    margin-bottom: 125px;
  }
  @include respond-to(t) {
    margin-bottom: 90px;
  }
  @include respond-to(m) {
    margin-bottom: 140px;
  }
}

.social-resp {
  position: relative;
  height: auto;
  display: flex;
  justify-content: space-between;
  width: 1170px;
  margin: auto;
  @include respond-to(d) {
    width: 810px;
  }
  @include respond-to(t) {
    flex-direction: column;
    width: 470px;
  }
  @include respond-to(m) {
    width: 230px;
  }
  &_content {
    width: 490px;
    margin-top: 310px;
    @include respond-to(d) {
      width: 350px;
      margin-top: 160px;
    }
    @include respond-to(t) {
      margin-top: 0;
    }
    @include respond-to(m) {
      width: 100%;
    }
  }
  &_subheader {
    color: #0B363C;
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 24px;
    line-height: 36px;
    margin-top: 80px;
    margin-bottom: 65px;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 25px;
      margin-top: 60px;
      margin-bottom: 35px;
    }
    @include respond-to(m) {
      margin-top: 40px;
      margin-bottom: 15px;
    }
  }
  &_text {
    font-size: 20px;
    line-height: 30px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 21px;
    }

  }
  &_image {
    margin-right: -300px;
    @include respond-to(d) {
      margin-right: -120px;
    }
    @include respond-to(t) { 
      margin-top: 100px;
    }
    @include respond-to(m) {
      margin-top: 60px;
      margin-right: 0;
    }
  }
  &_bg {
    position: absolute;
    top: 0;
    left: 35px;
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
    pointer-events: none;
    svg {
      opacity: 0.2;
      fill: #C7CEDB;
    }
    @include respond-to(m) {
      left: 0;
    }
  }
  &_img {
    width: 699px;
    height: 732px;
    position: relative;
    transition: opacity .5s;
    @include respond-to(d) {
      width: 427px;
      height: 446px;
      margin-left: -25px;
    }
    @include respond-to(t) {
      width: 477px;
      height: 534px;
      margin-left: -40px;
    }
    @include respond-to(m) {
      width: 287px;
      height: 252px;
      margin-left: -30px;
      margin-right: 0;
      position: relative;
    }
  }
  .about_quote {
    width: 340px;
    font-size: 22px;
    line-height: 33px;
    margin-top: 180px;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 120px;
    }
    @include respond-to(m) {
      display: none;
    }
  }
}