.footer {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #0B363C;

    @include respond-to(d) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include respond-to(t) {
        padding-top: 95px;
        padding-bottom: 50px;
    }

    @include respond-to(m) {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    &_wrap {
        margin: auto;
        display: flex;
        justify-content: space-between;
        width: 1536px;
        position: relative;

        @include respond-to(m) {
            display: block;
        }

        @include respond-to(dm) {
            width: 1050px;
        }

        @include respond-to(t) {
            width: 500px;
        }

        @include respond-to(m) {
            width: 100%;
            padding: 0 45px;
        }
    }

    &_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;

        @include respond-to(t) {
            display: block;
        }
    }

    &_logo {
        width: 132px;
        margin-right: 150px;
        margin-top: 5px;
        display: block;

        @include respond-to(dm) {
            width: 102px;
            margin-right: 100px;
            margin-top: 15px;
        }

        @include respond-to(t) {
            width: 102px;
            margin-bottom: 40px;
            margin-top: 0;
        }

        @include respond-to(m) {
            margin-bottom: 55px;
        }

        svg {
            fill: #547276;
            transition: fill .5s;
        }

        @include hover {
            svg {
                fill: #6A9DA5;
            }
        }
    }

    .facility-logo {
        display: block;
        position: absolute;
        left: 282px;
        bottom: 0;

        svg {
            fill: #547276;
            transition: all .5s;
        }

        &:hover {
            svg {
                fill: #6A9DA5;

            }
        }
        @include respond-to(d) {
          left: 202px;
          bottom: 20px;
        }
        @include respond-to(t) {
          left: 0px;
          bottom: 75px;
        }
        @include respond-to(m) {
          display: none;
        }
        &.mobile {
          display: none;
          @include respond-to(m) {
            display: block;
            bottom: 15px;
            left: 45px;
          }
        }
    }

    &_menu {
        font-size: 22px;
        line-height: 66px;
        font-family: $FontIBMPlexSansSemiBold;

        @include respond-to(d) {
            font-size: 16px;
            line-height: 48px;
        }

        li {
            display: block;
        }

        a {
            display: block;
            color: #547276;
            transition: color .5s;

            @include hover {
                color: #6A9DA5;
            }

        }
    }

    &_right {
        margin-top: 15px;

        @include respond-to(m) {
            margin-top: 50px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-left: 135px;

            @include respond-to(d) {
                margin-left: 92px;
            }

            @include respond-to(t) {
                margin-left: 60px;
            }

            @include respond-to(m) {
                margin-left: 0;
            }

        }
    }

    &_lable {
        font-size: 22px;
        font-family: $FontIBMPlexSansSemiBold;
        color: #547276;
        margin-bottom: 20px;

        @include respond-to(d) {
            font-size: 16px;
        }
    }

    &_row {
        margin-bottom: 20px;
        min-height: 150px;

        @include respond-to(d) {
            margin-bottom: 0px;
        }

        @include respond-to(t) {
            margin-bottom: 55px;
            min-height: unset;
        }

        &.soc {
            min-height: 50px;
        }

        p,
        a {
            color: #3B5E63;
            font-size: 21px;
            font-family: $FontIBMPlexSansMedium;
            line-height: 34px;

            @include respond-to(d) {
                font-size: 14px;
                line-height: 32px;
            }

            a {
                transition: color .5s;
                display: block;

                @include hover {
                    color: #6A9DA5;
                }
            }
        }

    }
}
