.project-top_section {
  background-size: cover;
  background-position: center;
  min-height: auto;
  @include respond-to(t) {
    height: 100vh;
    min-height: 100vw;
  }
  @include respond-to(m) {
    min-height: 680px;
  }
}

.project-top {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1280px;
  margin: auto;
  @include respond-to(d) {
    width: 890px;
  }
  @include respond-to(t) {
    width: 550px;
    justify-content: flex-end;
  }
  @include respond-to(m) {
    width: 220px;
  }
  &_section {
    background-size: cover;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(16, 41, 53, 0.5);
    }
  }
  &_text {
    color: #fff;
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 36px;
    line-height: 54px;
    margin-top: 90px;
    transition: opacity .5s, transform .5s;
    @include respond-to(d) {
      font-size: 30px;
      line-height: 42px;
      margin-top: 40px;
    }
    @include respond-to(m) {
      font-size: 14px;
      line-height: 22px;
      margin-top: 30px;
    }
    &.anim {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  &_content {
    position: absolute;
    right: -130px;
    bottom: -205px;
    width: 620px;
    transition: opacity 1s, transform .5s;
    @include respond-to(d) {
      width: 530px;
      right: -65px;
      bottom: -125px;
    }
    @include respond-to(t) {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin-top: 90px;
      margin-bottom: -95px;
    }
    @include respond-to(m) {
      width: 280px;
      margin-left: -30px;
      margin-top: 90px;
      margin-bottom: -175px;
    }
    &.anim {
      opacity: 0;
    }
  }
  &_content-top {
    display: flex;
    background: rgba(2, 40, 45, 0.6);
    margin-bottom: 6px;
    @include respond-to(m) {
      margin-bottom: 4px;
    }
  }
  &_right {
    width: 100%;
    display: flex;
    padding-left: 72px;
    align-items: center;
    font-size: 20px;
    line-height: 40px;
    font-family: $FontIBMPlexSansMedium;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 32px;
    }
    @include respond-to(m) {
      font-size: 16px;
      line-height: 32px;
      padding-left: 0;
      justify-content: center;
    }
  }
  &_right-label {
    color: #fff;
  }
  &_right-link {
    color: #78B4BD;
    text-decoration: underline;
  }
  &_logo {
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
    background-color: #FFFFFF;
    flex: 0 0 auto;
    @include respond-to(d) {
      width: 150px;
      height: 150px;
    }
    @include respond-to(m) {
      width: 93px;
      height: 93px;
    }
  }
  &_content-bottom {
    background: #164046;
    padding: 65px 45px 85px 75px;
    @include respond-to(d) {
      padding: 30px 35px 55px 55px;
    }
    @include respond-to(m) {
      padding: 25px 30px 40px 30px
    }
    .socials {
      justify-content: flex-end;
      @include respond-to(m) {
        justify-content: center;
      }
    }
  }
  &_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    @include respond-to(d) {
      margin-bottom: 0;
    }
  }
  &_item {
    font-family: $FontIBMPlexSansMedium;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 50px;
    //margin-left: 30px;
    width: 48%;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 30px;
      margin-top: 25px;
    }
    @include respond-to(t) {
      margin-left: 0;
      font-size: 14px;
      line-height: 18px;
      margin-top: 5px;
      margin-bottom: 35px;
    }
    @include respond-to(m) {
      width: 100%;
    }
  }
  &_label {
    margin-bottom: 10px;
    color: #fff;
  }
  &_info {
    color: #4E838C;
  }
  &_scroll-title {
    background: #194349;
    position: absolute;
    top: 260px;
    left: 130px;
    width: 82px;
    height: 191px;
    opacity: .2;
    z-index: -1;
    @include respond-to(d) {
      top: 190px;
      left: -50px;
      width: 67px;
      height: 156px;
    }
    @include respond-to(t) {
      top: 270px;
      left: -20px;
      width: 52px;
      height: 206px;
    }
    @include respond-to(m) {
      display: none;
    }
  }
  &_scroll-text {
    background: #194349;
    position: absolute;
    top: 180px;
    left: 310px;
    width: 95px;
    height: 373px;
    opacity: .3;
    z-index: -1;
    @include respond-to(d) {
      top: 100px;
      left: 120px;
      width: 78px;
      height: 305px;
    }
    @include respond-to(t) {
      display: none;
    }
  }
  &_scroll-content {
    background: #194349;
    position: absolute;
    top: -450px;
    left: 210px;
    width: 239px;
    height: 782px;
    opacity: .3;
    z-index: -1;
    @include respond-to(d) {
      top: -230px;
      left: 250px;
      width: 195px;
      height: 638px;
    }

    @include respond-to(t) {
      top: -450px;
      left: 460px;
      width: 153px;
      height: 502px;
    }
    @include respond-to(m) {
      display: none;
    }
  }
  &_scroll-content-bottom {
    background: #194349;
    position: absolute;
    top: -110px;
    right: -10px;
    width: 66px;
    height: 156px;
    opacity: .3;
    z-index: -1;
    @include respond-to(d) {
      top: 40px;
      right: -40px;
      width: 54px;
      height: 127px;
    }
    @include respond-to(t) {
      display: none;
    }
  }

  &_gradient {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(0deg, #194248 10%, rgba(25, 67, 73, 0) 76%);
      transition: top 1s ease;
      z-index: 2;
    }
    @include respond-to(t) {
      height: 750px;
      min-height: auto;
    }
    @include respond-to(m) {
      height: 550px;
      min-height: auto;
    }
    .project-top {
      @include respond-to(t) {
        justify-content: center;
      }
    }
    .project-top_content {
      bottom: 50%;
    }
  }
  &_text-line {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    width: 483px;
    position: relative;
    font-family: $FontIBMPlexSansMedium;
    margin-top: 140px;
    margin-left: 40px;
    transition: opacity .5s, transform .5s;
    @include respond-to(d) {
      font-size: 17px;
      line-height: 25.5px;
      width: 321px;
    }
    @include respond-to(t) {
      width: 289px;
    }
    @include respond-to(m) {
      width: 223px;
      font-size: 14px;
      line-height: 22.4px;
      margin-left: 0;
      padding-left: 20px;
      margin-top: 90px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      height: 100%;
      min-height: 120px;
      transform: translateY(-50%);
      width: 8px;
      background: rgba(255, 255, 255, 0.2);
      left: -35px;
      @include respond-to(d) {
        min-height: 98px;
        left: -30px;
      }
      @include respond-to(m) {
        min-height: 70px;
        width: 5px;
        left: 0;
      }

    }
    &.anim {
      opacity: 0;
      transform: translateY(10px);
    }
  }
}

.project-adv_section {
  @include respond-to(m) {
    overflow: hidden;
  }
}

.project-adv {
  @include respond-to(t) {
    padding-top: 210px;
  }
  @include respond-to(m) {
    padding-top: 260px;
  }
  &_section {
    display: flex;
    align-items: center;
  }
  &_text {
    margin-top: 110px;
    font-size: 20px;
    line-height: 30px;
    opacity: .43;
    width: 360px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 22.4px;
      margin-top: 65px;
      width: 230px;
    }
    @include respond-to(t) {
      margin-top: 25px;
      width: 345px;
    }
    @include respond-to(m) {
      margin-top: 22px;
      width: 100%;
    }
  }
  &_list {
    padding-left: 38%;
    margin-top: 60px;
    counter-reset: item;
    margin-right: -262px;
    @include respond-to(d) {
      padding-left: 25%;
      margin-right: -185px;
      margin-top: 40px;
    }
    @include respond-to(t) {
      padding-left: 0;
      margin-right: 0;
      margin-top: 90px;
      margin-bottom: 40px;
      width: 120%;
    }
    @include respond-to(m) {
      padding-left: 25px;
      margin-top: 30px;
    }
    .list {
      width: 100%;
      @include respond-to(t) {
        padding-left: 40px;
      }
      @include respond-to(m) {
        padding-left: 0;
      }
    }
  }
  .list_li {
    margin-right: 100px;
    width: 24%;
    @include respond-to(d) {
      margin-right: 30px;
      margin-bottom: 45px;
      width: 28%;
    }
    @include respond-to(t) {
      width: 40%;
      margin-right: 50px;
    }
    @include respond-to(m) {
      width: 100%
    }
    .list_text {
      position: relative;
      @include respond-to(d) {
        font-size: 16px;
        line-height: 24px;
      }
      @include respond-to(m) {
        font-size: 14px;
        line-height: 22px;
      }
      &:before {
        content: counter(item, decimal-leading-zero) " ";
        counter-increment: item;
        position: absolute;
        left: -40px;
        top: 0;
        color: #4E838C;
        font-family: $FontIBMPlexSansSemiBold;
        @include respond-to(m) {
          left: -25px;
        }
      }
    }
  }
  .list_text {
    font-family: $FontIBMPlexSansSemiBold;
    color: #fff;
    opacity: 1;
    width: auto;
  }
  &_cut-image {
    position: absolute;
    bottom: 10%;
    right: 0;
    width: 1087px;
    height: 751px;
    @include respond-to(d) {
      width: 744px;
      height: 500px;
    }
    @include respond-to(t) {
      bottom: 20%;
    }
    @include respond-to(m) {
      width: 398px;
      height: 267px;
      right: -140px;
      top: 20%;
    }
  }
}

.project-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 1480px;
  @include respond-to(d) {
    width: 980px;
  }
  @include respond-to(t) {
    flex-direction: column-reverse;
    width: 100%;
    padding-top: 130px;
  }
  @include respond-to(m) {
    padding-top: 65px;
    width: 220px;
  }
  &_left {
    width: 699px;
    height: 674px;
    position: relative;
    @include respond-to(d) {
      width: 430px;
      height: 415px;
    }
    @include respond-to(t) {
      width: 500px;
      height: 482px;
    }
    @include respond-to(m) {
      width: 280px;
      height: 270px;
    }
    &:after {
      content: '';
      @extend %bg-dot;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 80px;
      left: 60px;
      @include respond-to(m) {
        width: 50px;
        height: 60px;
        top: 40px;
        left: 20px;
      }
    }
    .scroll-rect {
      width: 579px;
      height: 491px;
      background: #4E838C;
      opacity: .1;
      position: absolute;
      left: 70px;
      top: 40px;
      z-index: -1;
      @include respond-to(d) {
        width: 356px;
        height: 302px;
        left: 40px;
      }
      @include respond-to(m) {
        width: 232px;
        height: 196px;
        left: 30px;
      }
    }
  }
  &_right {
    width: 45%;
    position: relative;
    left: 0;
    @include respond-to(t) {
      width: 470px;
    }
    @include respond-to(m) {
      margin-bottom: 55px;
      width: 100%;
    }
  }

  &_list {
    display: flex;
    justify-content: space-between;
    margin-right: -100px;
    margin-top: 108px;
    width: 730px;
    //margin-left: 25px;
    @include respond-to(d) {
      margin-right: -30px;
      width: 460px;
      margin-top: 50px;
    }
    @include respond-to(t) {
      margin-right: -10px;
      margin-bottom: 120px;
    }
    @include respond-to(m) {
      margin: auto;
      width: 100%;
      flex-direction: column;
      padding-left: 25px;
    }
  }
  &_list-left {
    width: 50%;
    @include respond-to(d) {
      width: 60%;
    }
    @include respond-to(m) {
      margin-top: 45px;
      width: 100%;
    }
  }
  &_list-right {
    width: 60%;
    @include respond-to(d) {
      width: 65%;
    }
    @include respond-to(m) {
      margin-top: 45px;
      width: 100%;
    }
  }
  &_r {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: #C7CEDB;
      opacity: .15;
      width: 749px;
      height: 808px;
      @include respond-to(d) {
        width: 387px;
        height: 538px;
      }
      @include respond-to(t) {
        width: 466px;
        height: 502px;
      }
      @include respond-to(m) {
        width: 302px;
        height: 326px;
      }
    }
  }
  &_list-item {
    display: flex;
    align-items: baseline;
    &:first-child {
      .project-info_list-label {
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: -40px;
          width: 11px;
          height: 12px;
          transform: translateY(-50%);
          background: #98C1D9;
          @include respond-to(d) {
            width: 8px;
            height: 8px;
            left: -35px;
          }
          @include respond-to(m) {
            left: -25px;
          }
        }
      }
    }
  }
  &_list-label {
    font-family: $FontIBMPlexSansMedium;
    font-size: 20px;
    color: #546467;
    line-height: 60px;
    width: 200px;
    position: relative;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 42px;
      width: 150px;
    }
    @include respond-to(m) {
      width: 40%;
    }

  }
  &_list-text {
    font-family: $FontIBMPlexSansBold;
    font-size: 20px;
    color: #0B363C;
    line-height: 30px;
    width: 250px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 42px;
      width: 200px;
    }
    @include respond-to(m) {
      width: 65%;
    }
  }

}

.project-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 140px;
  height: 100%;
  @include respond-to(d) {
    padding-left: 70px;
  }
  @include respond-to(t) {
    padding-left: 0;
    flex-direction: column;
    padding-bottom: 130px;
    padding-top: 130px;
  }
  @include respond-to(m) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .title-h3 {
    width: 500px;
    margin-left: 35px;
    @include respond-to(d) {
      width: 340px;
      margin-left: 30px;
    }
    @include respond-to(t) {
      width: 100%;
      margin-left: 35px;
    }
    @include respond-to(m) {
      margin-left: 0px;
    }
  }
  &_text {
    font-size: 20px;
    line-height: 30px;
    color: #546467;
    width: 554px;
    margin-top: 60px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 22px;
      width: 340px;
    }
    @include respond-to(t) {
      width: 100%;
      margin-bottom: 110px;
      margin-top: 30px;
    }
    @include respond-to(m) {
      margin-top: 20px;
      margin-bottom: 60px;
    }
    p {
      margin-bottom: 30px;
      @include respond-to(d) {
        margin-bottom: 23px;
      }
    }
  }
  &_logo {
    width: 490px;
    height: 433px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    background-color: #F5F5F5;
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
    position: relative;
    @include respond-to(d) {
      width: 352px;
      height: 311px;
    }
    @include respond-to(t) {
      width: 208px;
      height: 183px;
    }
    @include respond-to(m) {
      width: 220px;
      height: 194px;
    }
    &:after {
      content: '';
      @extend %bg-dot;
      width: 217px;
      height: 209px;
      position: absolute;
      top: -50px;
      right: -90px;
      opacity: 0.2;
      @include respond-to(d) {
        right: -130px;
      }
      @include respond-to(t) {
        right: -380px;
        top: -280px;
        width: 77px;
        height: 150px;
      }
      @include respond-to(m) {
        display: none;
      }
    }
  }
  &_link {
    @include respond-to(t) {
      margin-right: -25px;
      width: 55%;
    }
    @include respond-to(m) {
      width: unset;
      // margin-right: 0;
    }
    
  }
  &_link-prev {
    color: #0B363C;
    font-size: 22px;
    line-height: 33px;
    font-family: $FontIBMPlexSansSemiBold;
    margin-top: 67px;
    margin-bottom: 31px;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 23px;
    }
    @include respond-to(t) {
      margin-top: 0;
    }
    @include respond-to(m) {
      margin-top: 60px;
      margin-bottom: 40px;
    }
  }
  &_right {
    @include respond-to(t) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    @include respond-to(m) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.project-item_section {
  height: auto;
  overflow: hidden;
  background: #194248;

}

.project-item {
  display: flex;
  justify-content: space-between;
  width: 1673px;
  margin: auto;
  margin-bottom: 200px;
  margin-top: 350px;
  padding-left: 128px;
  @include respond-to(d) {
    width: 1200px;
    padding-left: 97px;
  }
  @include respond-to(t) {
    width: 540px;
    padding-left: 0;
    flex-direction: column !important;
    margin-bottom: 130px;
    margin-top: 170px;
  }
  @include respond-to(m) {
    width: 100%;
    align-items: center;
    margin-bottom: 110px;
    margin-top: 120px;
  }
  &:first-child {
    margin-top: 200px;
    @include respond-to(d) {
      margin-top: 91px;
    }
    @include respond-to(t) {
      margin-top: 0px;
    }
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  .project-top {
    width: 1400px;
    @include respond-to(d) {
      width: 1034px;
    }
  }
  &_image {
    width: 744px;
    height: 654px;
    position: relative;
    transition: opacity .5s;
    @include respond-to(d) {
      width: 539px;
      height: 473px;
    }
    @include respond-to(m) {
      width: 287px;
      height: 252px;
    }
    @include hover {
      opacity: .7;
    }
    &.anim {
      .cut-image-in {
        width: 0;
      }
    }
  }
  .scroll-rect {
    width: 665px;
    height: 508px;
    background: #4E838C;
    opacity: .2;
    position: absolute;
    top: 80px;
    left: 40px;
    @include respond-to(d) {
      width: 481px;
      height: 367px;
    }
    @include respond-to(m) {
      width: 246px;
      height: 196px;
      left: 30px;
    }
  }
  &_content {
    padding-top: 30px;
    width: 689px;
    @include respond-to(d) {
      width: 528px;
      padding-top: 0;
    }
    @include respond-to(t) {
      margin-top: 70px;
    }
    @include respond-to(m) {
      width: 251px;
    }
  }
  &_sub-title {
    color: #4E838C;
    font-size: 24px;
    letter-spacing: -0.33px;
    text-transform: uppercase;
    line-height: 45px;
    margin-top: 12px;
    @include respond-to(d) {
      font-size: 20px;
    }
    @include respond-to(m) {
      font-size: 14px;
      line-height: 35px;
      margin-top: 0px;
    }
  }
  .title-h2 {
    font-size: 48px;
    line-height: 70px;
    margin-left: 65px;
    @include respond-to(d) {
      font-size: 42px;
      margin-left: 55px;
    }
    @include respond-to(m) {
      font-size: 26px;
      line-height: 36px;
      margin-left: 14px;
    }
    &:before {
      height: 100%;
      min-height: 130px;
      @include respond-to(m) {
        min-height: 104px;
      }
    }
    br {
      display: none;
    }
  }
  .btn {
    margin-left: 60px;
    @include respond-to(d) {
      margin-left: 80px;
    }
    @include respond-to(t) {
      margin-left: 70px;
    }
    @include respond-to(m) {
      margin: auto;
    }
  }

  &_flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    margin-top: 90px;
    transition: opacity 0.5s;
    @include respond-to(d) {
      margin-bottom: 50px;
      margin-top: 70px;
    }
    @include respond-to(m) {
      flex-direction: column;
      align-items: center;
    }
    &.anim {
      opacity: 0;
    }
  }
  &_row {
    background: #133B41;
    width: 340px;
    height: 278px;
    font-size: 20px;
    line-height: 30px;
    font-family: $FontIBMPlexSansMedium;
    padding: 54px 66px;
    @include respond-to(d) {
      width: 251px;
      height: 194px;
      font-size: 14px;
      line-height: 21px;
      padding: 34px 39px;
    }
    &:nth-child(2) {
      @include respond-to(m) {
        margin-top: 21px;
      }
      //.project-item_label {
      //  margin-bottom: 45px;
      //  @include respond-to(d) {
      //    margin-bottom: 32px;
      //  }
      //}
    }
  }
  &_label {
    color: #fff;
    margin-bottom: 30px;
    font-size: 16px;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 21px;
    }
  }
  &_text {
    color: #4E838C;
  }
}