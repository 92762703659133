button {
  background: none;
  border: none;
}

.btn {
  width: 250px;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: $FontIBMPlexSansSemiBold;
  color: #fff;
  justify-content: flex-end;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color .5s;
  background: none;

  @include respond-to(d) {
    width: 180px;
    height: 54px;
    font-size: 15px;
  }
  span {
    margin-right: -8px;
    margin-left: 15px;
    transition: margin .5s;
    position: relative;
    pointer-events: none;
    @include respond-to(d) {
      margin-right: -4px;
      margin-left: 10px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px rgba(255, 255, 255, 0.1) solid;
    left: 0;
    top: 0;
    box-sizing: border-box;
    z-index: -1;
    @include respond-to(d) {
      border-width: 2px;
    }
  }
  &:after {
    content: '';
    background: #326B74;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    z-index: -1;
    transition: width .5s;
  }
  @include hover {
    span {
      margin-left: 15px;
      margin-right: 0;
    }
    &:after {
      width: 100%;
    }
  }
  &_white {
    @extend .btn;
    color: #0B363C;
    &:before {
      border-color: #E6EAEB !important;
    }
    @include hover {
      color: #fff;
    }
  }
  &_prev {
    @extend .btn;
    width: 119px;
    justify-content: flex-start;
    &:before {
      border-right-width: 2px;
      border-color: #2B5156;
    }
    &:after {
      left: auto;
      right: 0;
    }
    span {
      margin-right: 15px;
      margin-left: -8px;
    }
    @include hover {
      span {
        margin-right: 15px;
        margin-left: 0px;
      }
    }
    @include respond-to(d) {
      width: 90px;
    }
  }
  &_next {
    @extend .btn;
    width: 120px;
    &:before {
      border-left-width: 2px;
      border-color: #2B5156;
    }
    @include respond-to(d) {
      width: 90px;
    }
  }
}

.controls {
  display: flex;
  justify-content: center;
}

.news-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1200px;
  margin: auto;
  margin-top: 130px;
  margin-bottom: 155px;
  @include respond-to(d) {
    margin-top: 113px;
    margin-bottom: 68px;
    width: 880px;
  }
  @include respond-to(t) {
    width: 540px;
    margin-top: 83px;
    margin-bottom: 24px;
  }
  @include respond-to(m) {
    width: 100%;
    margin-top: 86px;
    margin-bottom: 0px;
    flex-direction: column;
    align-items: center;
  }
}

.news-item {
  position: relative;
  width: 420px;
  margin-bottom: 60px;
  transition: opacity .5s;
  @include respond-to(d) {
    width: 310px;
  }
  @include respond-to(m) {
    width: 240px;
  }
  &.hide {
    opacity: 0;
  }
  .scroll-rect {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;

  }
  &:nth-child(4n -3) {
    @include respond-to(t) {
      margin-left: 20px;
      margin-top: 0;
      margin-bottom: 124px;
    }
    @include respond-to(m) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 90px;
    }
    .news-item_img {
      border-color: rgba(187, 173, 160, 0.5);
    }
    .scroll-rect {
      width: 493px;
      height: 303px;
      background: #BBADA0;
      left: -13px;
      top: 40px;
      @include respond-to(d) {
        width: 366px;
        height: 225px;
        top: 20px;
      }
      @include respond-to(m) {
        width: 278px;
        height: 184px;
      }
    }
    .news-item_date {
      background: #BBADA0;
    }
  }
  &:nth-child(4n -2) {
    margin-top: 90px;
    margin-right: 66px;
    @include respond-to(d) {
      margin-right: 84px;
      margin-top: 60px;
    }
    @include respond-to(t) {
      margin-left: auto;
      margin-right: 57px;
      margin-top: 0;
      margin-bottom: 124px;
    }
    @include respond-to(m) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 90px;
    }
    .news-item_img {
      border-color: rgba(160, 170, 178, 0.5);
    }
    .news-item_date {
      background: #A0AAB2;
    }
    .scroll-rect {
      width: 430px;
      height: 310px;
      background: #A0AAB2;
      left: 50px;
      top: -40px;
      @include respond-to(d) {
        width: 306px;
        height: 225px;
        left: 40px;
        top: -20px;
      }
      @include respond-to(m) {
        width: 217px;
        height: 184px;
        left: 50px;
      }
    }
  }
  &:nth-child(4n -1) {
    margin-top: 74px;
    margin-left: 150px;
    @include respond-to(d) {
      margin-left: 87px;
      margin-top: 0px;
    }
    @include respond-to(t) {
      margin-left: 44px;
      margin-top: 0;
      margin-bottom: 124px;
    }
    @include respond-to(m) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 90px;
    }
    .news-item_img {
      border-color: rgba(159, 174, 162, 0.5);
    }
    .news-item_date {
      background: #9FAEA2;
    }
    .scroll-rect {
      width: 401px;
      height: 345px;
      background: #9FAEA2;
      left: -83px;
      top: -70px;
      @include respond-to(d) {
        width: 326px;
        height: 245px;
        left: -39px;
        top: -35px;
      }
      @include respond-to(m) {
        width: 240px;
        height: 184px;
        left: -30px;
      }
    }
  }
  &:nth-child(4n) {
    margin-top: 175px;
    @include respond-to(d) {
      margin-top: 60px;
    }
    @include respond-to(t) {
      margin-left: auto;
      margin-right: 57px;
      margin-top: 0;
      margin-bottom: 124px;
    }
    @include respond-to(m) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 90px;
    }
    .news-item_img {
      border-color: rgba(209, 198, 173, 0.5);
    }
    .news-item_date {
      background: #D1C6AD;
    }
    .scroll-rect {
      width: 282px;
      height: 372px;
      background: #D1C6AD;
      left: 75px;
      top: -40px;
      @include respond-to(d) {
        width: 206px;
        height: 285px;
        top: -20px;

      }
      @include respond-to(m) {
        width: 161px;
        height: 224px;
        left: 50px;
      }
    }
  }
  &_date {

    width: 125px;
    height: 40px;
    font-size: 16px;
    font-family: $FontIBMPlexSansBold;
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 13px;
    position: absolute;
    top: -13px;
    left: -18px;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: left top;
    @include respond-to(d) {
      width: 103px;
      height: 34px;
      font-size: 13px;
    }
  }
  &_img {
    width: 100%;
    height: 310px;
    box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.5);
    position: relative;
    transition: border .5s;
    border: 0px rgba(187, 173, 160, 0.5) solid;
    @include respond-to(d) {
      height: 228px;
    }
    @include respond-to(m) {
      height: 180px;
    }
  }
  &_title {
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 24px;
    line-height: 39px;
    color: #102935;
    width: 100%;
    position: relative;
    padding-left: 30px;
    margin-top: 45px;
    transition: opacity .5s;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 50px;
    }
    @include respond-to(m) {
      padding-left: 23px;
      margin-top: 30px;
    }
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #98C1D9;
      position: absolute;
      top: 16px;
      left: 0;
      @include respond-to(d) {
        width: 8px;
        height: 8px;
        top: 8px;
        left: 5px;
      }
      @include respond-to(m) {
        left: 0;
      }
    }
  }
  &_link {
    position: relative;
    display: block;
    @include hover {
      .news-item_img {
        border-width: 22px;
      }
      .news-item_title {
        opacity: 0.5;
      }
    }
  }

}

.news-load {
  font-size: 20px;
  color: #B4BBBC;
  font-family: $FontIBMPlexSansSemiBold;
  text-align: center;
  margin-bottom: 250px;
  @include respond-to(d) {
    font-size: 16px;
    margin-bottom: 130px;
  }
  @include respond-to(t) {
    margin-bottom: 150px;
  }
  @include respond-to(m) {
    margin-bottom: 90px;
  }
}

.news-top {
  height: 650px;
  position: relative;
  padding-top: 90px;
  @include respond-to(d) {
    height: 460px;
    padding-top: 0px;
  }
  @include respond-to(t) {
    height: 490px;
    padding-top: 40px;
  }
  @include respond-to(m) {
    height: 424px;
    padding-top: 0;
    padding-bottom: 130px;
  }
  .title-h1 {
    width: 50%;
    @include respond-to(t) {
      width: 100%;
    }
  }
}

.news-pagination {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #F3F3F3;
  width: 780px;
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  @include respond-to(d) {
    width: 600px;
    height: 64px;
    padding-left: 31px;
  }
  @include respond-to(t) {
    width: 650px;
    height: 64px;

  }
  @include respond-to(m) {
    width: 213px;
    height: 170px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 22px;
    padding-bottom: 29px;

  }
}

.news-pagination-btn {
  margin-right: 56px;
  cursor: pointer;
  color: #ADB4B5;
  transition: color .3s;
  font-family: $FontIBMPlexSansSemiBold;
  font-size: 20px;
  @include respond-to(d) {
    font-size: 16px;
    margin-right: 62px;
  }

  @include respond-to(m) {
    margin-right: 0;
  }
  @include hover {
    color: #738082;
  }
  &.active {
    color: #0B363C;
  }

  &:last-child {
    margin-right: 0;
  }
}

.show-title {
  color: #194349;
  &_row {
    position: relative;
    display: table;
  }
  &_mask {
    opacity: 0.5;
  }
  &_anim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-property: clip-path;
    transition-timing-function: linear;
    clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
  }
}

.title {
  &-h1 {
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 90px;
    line-height: 107px;
    color: #194349;
    letter-spacing: -1.25px;


    @include respond-to(d) {
      font-size: 64px;
      line-height: 77px;
      letter-spacing: -0.89px;
    }
    @include respond-to(t) {
      font-size: 56px;
      line-height: 77px;
      letter-spacing: -0.89px;
    }
    @include respond-to(m) {
      letter-spacing: -0.42px;
      font-size: 30px;
      line-height: 36px;
    }
    &.white {
      color: #fff;
    }
  }

  &-project-h1 {
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 130px;
    line-height: 143px;
    color: #194349;

    letter-spacing: -1.81px;
    width: 50%;

    @include respond-to(d) {
      font-size: 64px;
      line-height: 77px;
      letter-spacing: -0.89px;
    }
    @include respond-to(t) {
      width: 100%;
    }
    @include respond-to(m) {
      letter-spacing: -0.5px;
      font-size: 30px;
      line-height: 47px;
    }
    &.white {
      color: #fff;
    }
  }

  &-h2 {
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 60px;
    line-height: 72px;
    color: #194349;
    position: relative;
    min-height: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    letter-spacing: -0.83px;
    width: 100%;
    @include respond-to(d) {
      font-size: 42px;
      letter-spacing: -0.58px;
      line-height: 55px;
      min-height: 98px;
    }
    @include respond-to(m) {
      font-size: 26px;
      letter-spacing: -0.36px;
      line-height: 36.5px;
      min-height: 70px;
      padding-left: 20px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      height: 120px;
      transform: translateY(-50%);
      width: 7px;
      background: rgba(11, 54, 60, 0.1);
      left: -35px;
      @include respond-to(d) {
        height: 98px;
        left: -30px;
      }
      @include respond-to(m) {
        height: 70px;
        width: 5px;
        left: 0;

      }

    }
    &.white {
      color: #fff;
      &:before {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &-h3 {
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 24px;
    line-height: 36px;
    color: #0B363C;
    position: relative;
    min-height: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 25.6px;
      min-height: 98px;
    }
    @include respond-to(m) {
      font-size: 16px;
      line-height: 26px;
      min-height: 70px;
      padding-left: 20px;
      margin-right: -10px;
      br {
        display: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      height: 100%;
      min-height: 120px;
      transform: translateY(-50%);
      width: 7px;
      background: rgba(11, 54, 60, 0.1);
      left: -35px;
      @include respond-to(d) {
        min-height: 98px;
        left: -30px;
      }
      @include respond-to(m) {
        min-height: 70px;
        width: 5px;
        left: 0;

      }

    }
    &.white {
      color: #fff;
      &:before {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &-news {
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 70px;
    line-height: 84px;
    color: #194349;
    letter-spacing: -0, 97px;
    @include respond-to(d) {
      font-size: 44px;
      letter-spacing: -0.89px;
      line-height: 52.8px;
    }
    @include respond-to(m) {
      font-size: 22px;
      color: #194349;
      letter-spacing: -0.31px;
      line-height: 30.8px;
    }

  }

}

.socials {
  display: flex;
}

.ico {
  //background: #062F36;
  width: 44px;
  height: 44px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include respond-to(d) {
    width: 34px;
    height: 34px;
  }
  &:last-child {
    margin-right: 0;
  }
  svg {
    transition: fill .5s;
    fill: #3B5E63;
  }

  &-telegram {
    width: 20px;
    height: 20px;
    @include respond-to(d) {
      width: 18px;
      height: 18px;
    }
  }
  &-facebook {
    width: 8.36px;
    height: 16.67px;
    @include respond-to(d) {
      width: 7.6px;
      height: 15.15px;
    }
  }
  &-instagram {
    width: 20.17px;
    height: 20.17px;
    @include respond-to(d) {
      width: 18.33px;
      height: 18.33px;
    }
  }
  &-youtube {
    width: 22.66px;
    height: 18px;
    @include respond-to(d) {
      width: 20.6px;
      height: 16.36px;
    }
  }
  @include hover {
    svg {
      fill: #809EA2;
    }
  }
}

.cut-image {
  width: 552px;
  height: 725px;
  position: relative;
  @include respond-to(d) {
    height: 470px;
    width: 360px;
  }
  @include respond-to(m) {
    height: 290px;
    width: 221px;
  }
  .cut-image-in {
    position: absolute;
    transition: width .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 55%;
    }
    svg {
      fill: #fff;

    }
  }
  .cut-image-1 {
    transition-delay: .2s;
  }
  .cut-image-2 {
  }
  .cut-image-3 {
    transition-delay: .1s;
  }
  &-style-1 {
    .cut-image-1 {
      width: 38%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .cut-image-2 {
      width: 28%;
      height: 74%;
      top: 21%;
      left: 47%;
    }
    .cut-image-3 {
      width: 17%;
      height: 55%;
      top: 8%;
      left: 83%;
    }
  }
  &-style-2 {
    width: 100%;
    height: 100%;
    .cut-image-1 {
      width: 13%;
      height: 40%;
      top: 46%;
      left: 0;
    }
    .cut-image-2 {
      width: 59%;
      height: 100%;
      top: 0;
      left: 16%;
    }
    .cut-image-3 {
      width: 20%;
      height: 64%;
      top: 19%;
      left: 80%;
    }
  }
  &-style-project {
    width: 100%;
    height: 100%;
    opacity: 0.15;
    .cut-image-1 {
      width: 7%;
      height: 53%;
      top: 32%;
      left: 0;
    }
    .cut-image-2 {
      width: 14%;
      height: 64%;
      top: 0;
      left: 14%;
    }
    .cut-image-3 {
      width: 65%;
      height: 94%;
      bottom: 0;
      right: 0;
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  right: -120%;
  width: 100%;
  height: 100%;
  z-index: 9999;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .7s;
  @include respond-to(m) {
    overflow: auto;
  }
  &.active {
    right: 0;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    .popup_content {
      transform: none;
      @include respond-to(t) {
        transform: translate(50%, 0px);
      }
    }
  }
  &_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(2, 39, 48, .8);
  }
  &_content {
    position: absolute;
    top: 107px;
    right: 70px;
    padding: 115px 130px 130px 130px;
    background: #124851;
    transform: translateX(50px);
    transition: transform .5s;
    @include respond-to(d) {
      top: 83px;
      right: 44px;
      padding: 75px 95px 95px 95px;
    }
    @include respond-to(t) {
      right: 50%;
      transform: translate(50%, -50px);
    }
    @include respond-to(m) {
      top: 66px;
      padding: 56px 30px 100px 30px;
    }
  }
  &_close {
    position: absolute;
    top: -44px;
    right: -7px;
    width: 16.3px;
    height: 16.3px;
    cursor: pointer;
    @include respond-to(d) {
      width: 14px;
      height: 14px;
      top: -36px;
      right: 0px;
    }
  }
  &_title {
    font-size: 26px;
    line-height: 33px;
    font-family: $FontIBMPlexSansSemiBold;
    width: 470px;
    @include respond-to(d) {
      width: 420px;
      font-size: 22px;
      line-height: 33px;
    }
    @include respond-to(m) {
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      width: 100%;
    }
  }
  .form-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 110px;
    margin-bottom: 74px;
    @include respond-to(d) {
      margin-bottom: 55px;
      margin-top: 80px;
    }
    @include respond-to(m) {
      margin: 50px 10px;
      display: block;
    }
  }
  .form-group {
    width: 310px;
    position: relative;
    @include respond-to(d) {
      width: 230px;
    }
    @include respond-to(m) {
      margin: auto;
    }
    &.tel {
      width: 224px;
      margin-left: 40px;
      @include respond-to(d) {
        width: 180px;
        margin-left: 32px;
      }
      @include respond-to(m) {
        margin: auto;
        margin-top: 30px;
      }
    }
  }
  .help-block {
    position: absolute;
    top: 100%;
    left: 0;
    color: #ED8080;
    font-size: 14px;
    font-family: $FontIBMPlexSansMedium;
    opacity: 0;
    transition: opacity .5s;
    background: rgba(255, 14, 14, 0.2);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 19px;
    margin-top: 8px;
    @include respond-to(d) {
      font-size: 13px;
      height: 40px;
    }
    @include respond-to(m) {
      font-size: 11px;
      margin-top: 4px;
      height: 30px;
    }
  }
  .has-error {
    .help-block {
      opacity: 1;
    }
  }
  .form-control {
    width: 100%;
    border-bottom: 3px rgba(78, 131, 140, 0.2) solid;
    background: none;
    color: #fff;
    font-size: 20px;
    font-family: $FontIBMPlexSansMedium;
    height: 50px;
    &::placeholder {
      color: #4E838C;
    }
    @include respond-to(d) {
      font-size: 16px;
      height: 35px;
    }
    &:focus {
      border-bottom: 3px #4E838C solid;
    }
  }
  .success-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 115px 130px 130px 130px;
    background: #124851;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    @include respond-to(d) {
      padding: 75px 95px 95px 95px;
    }
    @include respond-to(m) {
      padding: 56px 30px 70px 30px;
    }
  }
  .btn {
    @include respond-to(m) {
      margin: auto;
      position: absolute;
    }
  }
  button {
    @include respond-to(m) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &_li {
    margin-bottom: 110px;
    @include respond-to(d) {
      margin-bottom: 72px;
    }
    @include respond-to(m) {
      margin-bottom: 40px;
    }
  }
  &_title {
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    font-family: $FontIBMPlexSansSemiBold;
    position: relative;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 20px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -40px;
      width: 11px;
      height: 12px;
      transform: translateY(-50%);
      background: #779EB5;
      @include respond-to(d) {
        width: 8px;
        height: 8px;
        left: -30px;
      }
    }

  }
  &_text {
    font-size: 20px;
    line-height: 30px;
    opacity: .43;
    margin-top: 22px;
    width: 330px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 22.4px;
      width: 230px;
      margin-top: 18px;
    }
    @include respond-to(m) {
      width: 100%;
    }
  }
}

.footer-news {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 1190px;
  @include respond-to(d) {
    width: 790px;
  }
  @include respond-to(t) {
    padding-top: 150px;
    padding-bottom: 170px;
    width: 475px;
  }
  @include respond-to(m) {
    width: 100%;
    padding-top: 70px;
    padding-bottom: 90px;
  }
  &_link {
    @extend %link;
    align-items: flex-end;
    bottom: 0;
    top: auto;
    @include respond-to(d) {
      right: -40px;
    }
  }
  &_top {
    position: relative;
    @include respond-to(t) {
      .footer-news_link {
        display: none;
      }
    }
    @include respond-to(m) {
      width: 220px;
      margin: auto;
    }

  }
  &_bottom {
    display: none;
    @include respond-to(t) {
      display: flex;
      justify-content: center;
    }
  }
  &_item {
    display: flex;
    justify-content: space-between;
    margin-top: 130px;
    width: 100%;
    margin-left: 77px;

    @include respond-to(d) {
      margin-left: 35px;
      margin-top: 90px;
    }
    @include respond-to(t) {
      margin-left: 0;

      margin-top: 115px;
      margin-bottom: 60px;
      justify-content: center;
    }
    @include respond-to(m) {
      margin-top: 60px;
      margin-bottom: 0;
    }
  }
  .news-item {
    margin-bottom: 0;
    @include respond-to(m) {
      margin-bottom: 60px !important;
    }
    &:nth-child(1) {
      &:before {
        opacity: .2;
        @extend %bg-dot;
        top: 7px;
        left: -137px;
        width: 217px;
        height: 209px;
        @include respond-to(d) {
          top: -25px;
        }
      }
    }
    &:nth-child(2) {
      margin-top: 40px;
      margin-right: 86px;
      &:before {
        opacity: .2;
        @extend %bg-dot;
        top: 30px;
        left: -70px;
        width: 217px;
        height: 209px;
        @include respond-to(d) {
          top: 5px;
          left: -90px;
        }
      }
      @include respond-to(d) {
        margin-right: -7px;
      }
      @include respond-to(t) {
        display: none;
      }
    }
  }

  &_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    pointer-events: none;
    &:before {
      opacity: .2;
      @extend %bg-dot;
      top: 50%;
      right: -70px;
      width: 217px;
      height: 209px;
      margin-top: -130px;
      @include respond-to(d) {
        right: -150px;
        margin-top: 10px;
      }
    }

  }
  &_r {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: #C7CEDB;
      opacity: .15;
      width: 749px;
      height: 808px;
      @include respond-to(d) {
        width: 387px;
        height: 538px;
      }
      @include respond-to(t) {
        width: 466px;
        height: 502px;
      }
      @include respond-to(m) {
        width: 302px;
        height: 326px;
      }
    }
  }
}

.marker {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  cursor: pointer;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background: #98C1D9;
    box-shadow: 0 0 9px 0 #A4CBD8, 0 0 30px 0 #A4CBD8;
    width: 12px;
    height: 12px;
    transition: all .3s;
  }
  @include respond-to(d) {
    width: 7px;
    height: 7px;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      opacity: 0.05;
      background: #78AAB2;
      border-radius: 100%;
      width: 0;
      height: 0;
      transition: width .3s, height .3s;
    }
    &:after {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      opacity: 0.1;
      background: #98C1D9;
      border-radius: 100%;
      width: 0;
      height: 0;
      transition: width .3s, height .3s;
    }
  }
  &_title {
    position: absolute;
    font-size: 30px;
    color: #fff;
    line-height: 39px;
    letter-spacing: -0.42px;
    font-family: $FontIBMPlexSansSemiBold;
    white-space: nowrap;
    transform: translateX(-50%);
    left: 50%;
    bottom: 50%;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    @include respond-to(d) {
      font-size: 24px;
      line-height: 23px;
    }
    @include respond-to(t) {
      font-size: 16px;
      line-height: 20px;
    }
    svg {
      display: inline-block;
      vertical-align: middle;
      width: 22.67px;
      height: 16px;
      margin-left: 40px;
      fill: #568890;
      @include respond-to(d) {
        width: 14.17px;
        height: 10px;
        margin-left: 20px;
      }

    }
  }
  &_line {
    width: 2px;
    height: 0px;
    background: linear-gradient(180deg, #8BB3B9 0%, rgba(39, 117, 129, 0) 100%);
    margin: auto;
    transition: height .3s;
    margin-top: 35px;
    margin-bottom: 10px;
    @include respond-to(d) {
      margin-top: 25px;
    }
  }
  &.active {
    z-index: 2;
    &:before {
      width: 16px;
      height: 16px;
      @include respond-to(d) {
        width: 10px;
        height: 10px;
      }
    }
    a {
      &:before {
        width: 172px;
        height: 172px;
        @include respond-to(d) {
          width: 101px;
          height: 101px;
        }
      }
      &:after {
        width: 80px;
        height: 80px;
        @include respond-to(d) {
          width: 53px;
          height: 53px;
        }
      }
    }
    .marker {
      &_title {
        opacity: 1;
        pointer-events: auto;
      }
      &_line {
        height: 100px;
        @include respond-to(d) {
          height: 45px;
        }
      }
    }
  }
}

.logo-img {
  display: flex;
  justify-content: space-between;
  @include respond-to(t) {
    flex-direction: column-reverse;
  }
  &_left {
    width: 699px;
    height: 732px;
    position: relative;
    transition: opacity .5s;
    margin-left: -50px;
    @include respond-to(d) {
      width: 427px;
      height: 446px;
      margin-left: -25px;
    }
    @include respond-to(t) {
      width: 577px;
      height: 604px;
      margin-left: 0px;
    }
    @include respond-to(m) {
      width: 287px;
      height: 252px;
      margin-left: -30px;
      margin-right: 0;
      position: relative;
    }

    &.anim {
      .cut-image-in {
        width: 0;
      }
    }
  }
  &_right {
    width: 520px;
    padding-top: 269px;
    margin-right: -20px;
    @include respond-to(d) {
      width: 390px;
      margin-right: -60px;
      padding-top: 189px;
    }
    @include respond-to(t) {
      margin-bottom: 100px;
    }
    @include respond-to(m) {
      width: 240px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
      padding-top: 90px;
    }
  }
  &_ul {
    margin-top: 100px;
    @include respond-to(d) {
      margin-top: 70px;
    }
    @include respond-to(m) {
      margin-top: 30px;
    }
  }
  &_li {
    display: flex;
    margin-bottom: 60px;
    @include respond-to(d) {
      margin-bottom: 40px;
    }
    @include respond-to(m) {
      flex-direction: column;

    }
  }
  &_li-label {
    position: relative;
    font-size: 22px;
    color: #0B363C;
    line-height: 31px;
    margin-right: 25px;
    font-family: $FontIBMPlexSansSemiBold;
    width: 125px;
    flex: 0 0 auto;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 24px;
      width: 100px;
      margin-right: 15px;
    }
    @include respond-to(m) {
      width: 100%;
      padding-left: 25px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -40px;
      width: 11px;
      height: 12px;
      transform: translateY(-50%);
      background: #98C1D9;
      @include respond-to(d) {
        width: 8px;
        height: 8px;
        left: -35px;
      }
      @include respond-to(m) {

        left: 0px;
      }
    }
  }
  &_li-text {
    color: #546467;
    font-size: 20px;
    line-height: 30px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 21px;
    }
    @include respond-to(m) {
      width: 100%;
      padding-left: 25px;
    }
  }

  .scroll-rect {
    position: absolute;
    background: #4E838C;
    opacity: .1;
    width: 579px;
    height: 624px;
    left: 70px;
    top: 80px;

    @include respond-to(d) {
      width: 354px;
      height: 380px;
      left: 40px;
      top: 50px;
    }
    @include respond-to(d) {
      width: 223px;
      height: 235px;
    }
  }
  .cut-image {
    &:after {
      @extend %bg-dot;
      position: absolute;
      top: -50px;
      right: 100px;
      width: 100px;
      height: 100px;
      @include respond-to(m) {
        top: -30px;
        right: 30px;
        width: 60px;
        height: 60px;
      }
    }
  }
  &_text {
    font-size: 20px;
    line-height: 30px;
    margin-top: 80px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 21px;
      width: 350px;
    }
    @include respond-to(m) {
      width: 100%;
      margin-top: 30px;
    }
    p {
      margin-bottom: 30px;
      @include respond-to(d) {
        margin-bottom: 21px;
      }
    }
  }
}