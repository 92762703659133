.contact-top_section {
  background-size: cover;
  background-position: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #194248 15%, rgba(25, 67, 73, 0) 100%);
    transition: top 1s ease;
    z-index: 2;

  }
  @include respond-to(t) {
    height: 660px;
  }
  @include respond-to(m) {
    height: 400px;
  }
}

.contact-top {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1350px;
  margin: auto;
  padding-bottom: 300px;
  @include respond-to(d) {
    width: 890px;
    padding-bottom: 200px;
  }
  @include respond-to(t) {
    width: 550px;
    padding-bottom: 270px;
  }
  @include respond-to(m) {
    width: 220px;
    padding-bottom: 170px;
  }


  &_scroll-title {
    background: #194349;
    position: absolute;
    top: 211px;
    left: -30px;
    width: 160px;
    height: 523px;
    opacity: .2;
    z-index: -1;
    @include respond-to(d) {
      width: 160px;
      height: 423px;
      left: -65px;
      top: 141px;
    }

    @include respond-to(t) {}
    @include respond-to(m) {
      width: 57px;
      height: 159px;
      left: -15px;

      top: 81px;
    }
  }
  &_scroll-min {
    background: #194349;
    position: absolute;
    left: 710px;
    width: 66px;
    height: 156px;
    opacity: .25;
    z-index: -1;
    top: 511px;

    @include respond-to(d) {
      width: 66px;
      height: 156px;
      left: 410px;
      top: 431px;
    }
    @include respond-to(t) {
      left: 240px;
    }

    @include respond-to(m) {
      width: 27px;
      height: 57px;
      left: 140px;
      top: 201px;
    }
  }
  &_scroll-min2 {
    background: #194349;
    position: absolute;
    top: 271px;
    left: 949px;
    width: 82px;
    height: 191px;
    opacity: .2;
    z-index: -1;
    @include respond-to(d) {
      width: 82px;
      height: 191px;
      left: 669px;
      top: 181px;
    }
    @include respond-to(t) {
      left: 429px;
    }

    @include respond-to(m) {
      width: 35px;
      height: 83px;
      left: 209px;
      top: 101px;
    }
  }
}

.contact-map_section {
  background: #194248;
  height: auto;
}

.contact-map {
  padding-top: 250px;
  padding-bottom: 230px;
  @include respond-to(d) {
    padding-top: 410px;
    padding-bottom: 160px;
  }
  @include respond-to(t) {
    padding-top: 960px;
    padding-bottom: 90px;
  }
  @include respond-to(m) {
    padding-top: 780px;
  }
  &_content-wrap {
    position: relative;
    width: 1300px;
    margin: auto;
    z-index: 2;

    @include respond-to(d) {
      width: 880px;
    }
    @include respond-to(t) {
      width: 520px;
      flex-direction: column;
    }
    @include respond-to(m) {
      width: 277px;
    }
  }
  &_content {
    position: absolute;
    top: -420px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include respond-to(d) {
      top: -214px;
    }
    @include respond-to(t) {
      flex-direction: column;
    }

  }
  &_form {
    background: #13393E;
    width: 650px;
    height: 760px;
    padding: 133px 200px 100px 140px;
    position: relative;
    color: #fff;
    @include respond-to(d) {
      width: 514px;
      height: 676px;
      padding: 109px 166px 100px 104px;
    }
    @include respond-to(t) {
      width: 514px;
      height: 676px;
      padding: 109px 146px 100px 124px;
    }
    @include respond-to(m) {
      width: 277px;
      height: 495px;
      padding: 65px 25px 20px 32px;
    }
    &_right {
      display: flex;
      justify-content: flex-end;
      @include respond-to(m) {
        justify-content: center;
      }
    }
    .form-group {
      width: 100%;
      position: relative;
      margin-bottom: 80px;
      @include respond-to(d) {
        margin-bottom: 97px;

      }
      @include respond-to(m) {
        margin-bottom: 65px;
      }
      &.tel {
        width: 242px;
        @include respond-to(d) {
          width: 180px;
        }
        @include respond-to(m) {

        }
      }
    }
    .help-block {
      position: absolute;
      top: 100%;
      left: 0;
      color: #ED8080;
      font-size: 14px;
      font-family: $FontIBMPlexSansMedium;
      opacity: 0;
      transition: opacity .5s;
      background: rgba(255, 14, 14, 0.2);
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 19px;
      margin-top: 8px;
      @include respond-to(d) {
        font-size: 13px;
        height: 40px;
      }
      @include respond-to(m) {
        font-size: 11px;
        margin-top: 4px;
        height: 30px;
      }
    }
    .has-error {
      .help-block {
        opacity: 1;
      }
    }
    .form-control {
      width: 100%;
      border-bottom: 3px rgba(78, 131, 140, 0.2) solid;
      background: none;
      color: #fff;
      font-size: 20px;
      font-family: $FontIBMPlexSansMedium;
      height: 50px;
      &::placeholder {
        color: #4E838C;
      }
      @include respond-to(d) {
        font-size: 16px;
        height: 35px;
        border-bottom: 3px rgba(78, 131, 140, 0.2) solid;
      }
      @include respond-to(m) {
        font-size: 14px;
      }
      &:focus {
        border-bottom: 3px #4E838C solid;
      }
    }
    .success-form {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 115px 130px 130px 130px;
      background: #124851;
      z-index: 3;
      opacity: 0;
      visibility: hidden;
      transition: all .5s;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      @include respond-to(d) {
        padding: 75px 95px 95px 95px;
      }
      @include respond-to(m) {
        padding: 56px 30px 70px 30px;
      }
    }
    .btn {
      margin-top: 30px;
      margin-right: -40px;
      @include respond-to(d) {
        margin-top: 0;
        margin-right: -54px;
      }
      @include respond-to(m) {
        margin: auto;
      }
    }
  }
  &_left {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 90px;
    @include respond-to(t) {
      margin-bottom: 40px;
    }
    @include respond-to(m) {
      margin-bottom: 20px;
      width: 220px;
    }
  }
  &_item {
    width: 100%;
    font-size: 20px;
    font-family: $FontIBMPlexSansMedium;
    line-height: 40px;
    margin-bottom: 57px;
    &:nth-child(1) {
      width: 50%;
      @include respond-to(d) {
        width: 100%;
      }
      @include respond-to(t) {
        width: 50%;
      }
      @include respond-to(m) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 50%;
      @include respond-to(d) {
        width: 100%;
      }
      @include respond-to(t) {
        width: 50%;
      }
      @include respond-to(m) {
        width: 100%;
      }
    }
    @include respond-to(d) {
      font-size: 16px;
      line-height: 40px;
      margin-bottom: 45px;
    }
    @include respond-to(t) {
      width: 50%;
    }
    @include respond-to(m) {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  &_label {
    color: #4E838C;
  }
  &_text {
    color: #fff;
    a {
      color: #fff;
    }
    span {
      color: #4E838C;
    }
  }
}

#contact-map {
  width: 1468px;
  height: 765px;
  margin: auto;
  @include respond-to(d) {
    width: 1078px;
    height: 562px;
  }
  @include respond-to(t) {
    width: 651px;
  }
  @include respond-to(m) {
    width: 100%;
    height: 485px;
  }
}