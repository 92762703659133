html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, textarea, button {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  outline: none;
  border-radius: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: touch;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a:hover, a:active {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
  height: 100%;
  left: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s,visibility 0s linear .25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity .25s,visibility 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  text-align: center;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide:before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: -.25em;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block;
}

.fancybox-slide--next {
  z-index: 99995;
}

.fancybox-slide--image {
  overflow: visible;
  padding: 44px 0;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px 6px 0;
}

.fancybox-slide--iframe {
  padding: 44px 44px 0;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px;
  max-width: 100%;
  overflow: auto;
  padding: 0;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  margin: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-drag .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-dragging .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
  cursor: text;
}

.fancybox-image, .fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px;
}

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--video .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
  height: calc(100% - 44px);
  margin-bottom: 44px;
}

.fancybox-iframe, .fancybox-video {
  background: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.fancybox-iframe {
  vertical-align: top;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  outline: none;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  width: 44px;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc;
}

.fancybox-button:focus, .fancybox-button:hover {
  color: #fff;
}

.fancybox-button.disabled, .fancybox-button.disabled:hover, .fancybox-button[disabled], .fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
}

.fancybox-button svg {
  display: block;
  overflow: visible;
  position: relative;
  shape-rendering: geometricPrecision;
}

.fancybox-button svg path {
  fill: transparent;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3;
}

.fancybox-button--pause svg path:nth-child(1), .fancybox-button--play svg path:nth-child(2) {
  display: none;
}

.fancybox-button--play svg path, .fancybox-button--share svg path, .fancybox-button--thumbs svg path {
  fill: currentColor;
}

.fancybox-button--share svg path {
  stroke-width: 1;
}

.fancybox-navigation .fancybox-button {
  height: 38px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  width: 38px;
}

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity .25s,visibility 0s,color .25s;
}

.fancybox-navigation .fancybox-button:after {
  content: "";
  left: -25px;
  padding: 50px;
  position: absolute;
  top: -25px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px;
}

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #555;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10;
}

.fancybox-close-small svg {
  fill: transparent;
  opacity: .8;
  stroke: currentColor;
  stroke-width: 1.5;
  transition: stroke .1s;
}

.fancybox-close-small:focus {
  outline: none;
}

.fancybox-close-small:hover svg {
  opacity: 1;
}

.fancybox-slide--iframe .fancybox-close-small, .fancybox-slide--image .fancybox-close-small, .fancybox-slide--video .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px;
}

.fancybox-slide--iframe .fancybox-close-small:hover svg, .fancybox-slide--image .fancybox-close-small:hover svg, .fancybox-slide--video .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff;
}

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none;
}

.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px;
  right: 0;
}

.fancybox-caption:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1;
}

.fancybox-caption:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: "";
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0;
}

.fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

.fancybox-loading {
  -webkit-animation: a .8s infinite linear;
  animation: a .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(99, 99, 99, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
}

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}

.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:link, .fancybox-share__button:visited {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs > ul {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x > ul {
  overflow: hidden;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs > ul > li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.fancybox-thumbs > ul > li:before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity: 1;
}

@media (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px);
  }
}

.home-top_bg:after, .home-dev-section:after, .home-why-section:after, .footer-news .news-item:nth-child(1):before, .footer-news .news-item:nth-child(2):before, .footer-news_bg:before, .logo-img .cut-image:after, .client-dbl_left:before, .client-dbl_right:before, .project-info_left:after, .project-bottom_logo:after {
  content: '';
  position: absolute;
  background: url("../img/general/background/bgDot.png") left top repeat;
}

@media only screen and (max-width: 750px) {
  .home-top_bg:after, .home-dev-section:after, .home-why-section:after, .footer-news .news-item:nth-child(1):before, .footer-news .news-item:nth-child(2):before, .footer-news_bg:before, .logo-img .cut-image:after, .client-dbl_left:before, .client-dbl_right:before, .project-info_left:after, .project-bottom_logo:after {
    background: url("../img/general/background/bgDot-mob.png") left top repeat;
  }
}

@font-face {
  font-family: 'Roboto Mono Thin';
  src: url("../fonts/RobotoMono-Thin.eot");
  src: url("../fonts/RobotoMono-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-Thin.woff") format("woff"), url("../fonts/RobotoMono-Thin.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src: url("../fonts/IBMPlexSans-Bold.eot");
  src: url("../fonts/IBMPlexSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Bold.woff") format("woff"), url("../fonts/IBMPlexSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans Thin';
  src: url("../fonts/IBMPlexSans-Thin.eot");
  src: url("../fonts/IBMPlexSans-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Thin.woff") format("woff"), url("../fonts/IBMPlexSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono ThinItalic';
  src: url("../fonts/RobotoMono-ThinItalic.eot");
  src: url("../fonts/RobotoMono-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-ThinItalic.woff") format("woff"), url("../fonts/RobotoMono-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans Medium';
  src: url("../fonts/IBMPlexSans-Medium.eot");
  src: url("../fonts/IBMPlexSans-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Medium.woff") format("woff"), url("../fonts/IBMPlexSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono MediumItalic';
  src: url("../fonts/RobotoMono-MediumItalic.eot");
  src: url("../fonts/RobotoMono-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-MediumItalic.woff") format("woff"), url("../fonts/RobotoMono-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans Italic';
  src: url("../fonts/IBMPlexSans-Italic.eot");
  src: url("../fonts/IBMPlexSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Italic.woff") format("woff"), url("../fonts/IBMPlexSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono LightItalic';
  src: url("../fonts/RobotoMono-LightItalic.eot");
  src: url("../fonts/RobotoMono-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-LightItalic.woff") format("woff"), url("../fonts/RobotoMono-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans ExtraLightItalic';
  src: url("../fonts/IBMPlexSans-ExtraLightItalic.eot");
  src: url("../fonts/IBMPlexSans-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-ExtraLightItalic.woff") format("woff"), url("../fonts/IBMPlexSans-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans SemiBoldItalic';
  src: url("../fonts/IBMPlexSans-SemiBoldItalic.eot");
  src: url("../fonts/IBMPlexSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-SemiBoldItalic.woff") format("woff"), url("../fonts/IBMPlexSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans SemiBold';
  src: url("../fonts/IBMPlexSans-SemiBold.eot");
  src: url("../fonts/IBMPlexSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-SemiBold.woff") format("woff"), url("../fonts/IBMPlexSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono Medium';
  src: url("../fonts/RobotoMono-Medium.eot");
  src: url("../fonts/RobotoMono-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-Medium.woff") format("woff"), url("../fonts/RobotoMono-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono Italic';
  src: url("../fonts/RobotoMono-Italic.eot");
  src: url("../fonts/RobotoMono-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-Italic.woff") format("woff"), url("../fonts/RobotoMono-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono Light';
  src: url("../fonts/RobotoMono-Light.eot");
  src: url("../fonts/RobotoMono-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-Light.woff") format("woff"), url("../fonts/RobotoMono-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono Regular';
  src: url("../fonts/RobotoMono-Regular.eot");
  src: url("../fonts/RobotoMono-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-Regular.woff") format("woff"), url("../fonts/RobotoMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono BoldItalic';
  src: url("../fonts/RobotoMono-BoldItalic.eot");
  src: url("../fonts/RobotoMono-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-BoldItalic.woff") format("woff"), url("../fonts/RobotoMono-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans ThinItalic';
  src: url("../fonts/IBMPlexSans-ThinItalic.eot");
  src: url("../fonts/IBMPlexSans-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-ThinItalic.woff") format("woff"), url("../fonts/IBMPlexSans-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans BoldItalic';
  src: url("../fonts/IBMPlexSans-BoldItalic.eot");
  src: url("../fonts/IBMPlexSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-BoldItalic.woff") format("woff"), url("../fonts/IBMPlexSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono Bold';
  src: url("../fonts/RobotoMono-Bold.eot");
  src: url("../fonts/RobotoMono-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMono-Bold.woff") format("woff"), url("../fonts/RobotoMono-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans MediumItalic';
  src: url("../fonts/IBMPlexSans-MediumItalic.eot");
  src: url("../fonts/IBMPlexSans-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-MediumItalic.woff") format("woff"), url("../fonts/IBMPlexSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans ExtraLight';
  src: url("../fonts/IBMPlexSans-ExtraLight.eot");
  src: url("../fonts/IBMPlexSans-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-ExtraLight.woff") format("woff"), url("../fonts/IBMPlexSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans Regular';
  src: url("../fonts/IBMPlexSans.eot");
  src: url("../fonts/IBMPlexSans.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans.woff") format("woff"), url("../fonts/IBMPlexSans.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans LightItalic';
  src: url("../fonts/IBMPlexSans-LightItalic.eot");
  src: url("../fonts/IBMPlexSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-LightItalic.woff") format("woff"), url("../fonts/IBMPlexSans-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans Light';
  src: url("../fonts/IBMPlexSans-Light.eot");
  src: url("../fonts/IBMPlexSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Light.woff") format("woff"), url("../fonts/IBMPlexSans-Light.ttf") format("truetype");
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

html, body {
  height: 100%;
  font-family: "IBM Plex Sans Regular";
  font-size: 20px;
  color: #546467;
}

body.hidden {
  overflow: hidden;
}

.content {
  min-height: 50%;
}

.wrapper {
  width: 1340px;
  margin: auto;
}

@media only screen and (max-width: 1800px) {
  .wrapper {
    width: 885px;
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    width: 470px;
  }
}

@media only screen and (max-width: 750px) {
  .wrapper {
    width: 220px;
  }
}

.wrapper-top {
  height: 100%;
  display: flex;
  align-items: center;
  width: 1286px;
  margin: auto;
}

@media only screen and (max-width: 1800px) {
  .wrapper-top {
    width: 912px;
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper-top {
    width: 540px;
  }
}

@media only screen and (max-width: 750px) {
  .wrapper-top {
    width: 240px;
  }
}

.p404_section {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
}

@media only screen and (max-width: 1800px) {
  .p404_section {
    padding-top: 0;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .p404_section {
    height: 670px !important;
    padding-top: 100px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 750px) {
  .p404_section {
    padding-top: 10px;
    padding-bottom: 0;
    height: 460px !important;
  }
}

.p404_section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #194248 15%, rgba(25, 67, 73, 0) 100%);
  transition: top 1s ease;
  z-index: 2;
}

.p404 {
  color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.p404 .btn, .p404 .btn_white, .p404 .btn_prev, .p404 .btn_next {
  width: 320px;
}

@media only screen and (max-width: 1800px) {
  .p404 .btn, .p404 .btn_white, .p404 .btn_prev, .p404 .btn_next {
    width: 230px;
  }
}

.p404-status {
  font-size: 300px;
  font-family: "IBM Plex Sans SemiBold";
}

@media only screen and (max-width: 1800px) {
  .p404-status {
    font-size: 200px;
  }
}

@media only screen and (max-width: 750px) {
  .p404-status {
    font-size: 140px;
  }
}

.p404-text {
  font-size: 22px;
  font-family: "IBM Plex Sans SemiBold";
  margin-top: 30px;
  margin-bottom: 150px;
}

@media only screen and (max-width: 1800px) {
  .p404-text {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 750px) {
  .p404-text {
    margin-top: 0px;
    margin-bottom: 60px;
  }
}

article {
  width: 555px;
  margin: auto;
  padding-bottom: 120px;
  padding-top: 120px;
}

@media only screen and (max-width: 1800px) {
  article {
    padding-bottom: 70px;
    padding-top: 70px;
  }
}

@media only screen and (max-width: 1200px) {
  article {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

article *:first-child {
  margin-top: 0 !important;
}

@media only screen and (max-width: 1800px) {
  article {
    width: 420px;
  }
}

@media only screen and (max-width: 750px) {
  article {
    width: 240px;
  }
}

article h1, article h2, article h3, article h4, article h5, article h6 {
  font-size: 24px;
  line-height: 36px;
  margin: 60px 0;
  position: relative;
  font-family: "IBM Plex Sans SemiBold";
  color: #0B363C;
}

@media only screen and (max-width: 1800px) {
  article h1, article h2, article h3, article h4, article h5, article h6 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 750px) {
  article h1, article h2, article h3, article h4, article h5, article h6 {
    padding-left: 20px;
  }
}

article h1:before, article h2:before, article h3:before, article h4:before, article h5:before, article h6:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: -35px;
  background: #DDE4E3;
}

@media only screen and (max-width: 750px) {
  article h1:before, article h2:before, article h3:before, article h4:before, article h5:before, article h6:before {
    left: 0;
  }
}

article p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1800px) {
  article p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 21px;
  }
}

article b, article strong {
  font-family: "IBM Plex Sans SemiBold";
}

article i {
  font-family: "IBM Plex Sans Italic";
}

article ul {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1800px) {
  article ul {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 21px;
  }
}

article ul li {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

article ul li:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #779EB5;
  margin-right: 20px;
  margin-left: 0;
  flex: 0 0 auto;
}

@media only screen and (max-width: 1800px) {
  article ul li:before {
    width: 6px;
    height: 6px;
  }
}

article ol {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1800px) {
  article ol {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 21px;
  }
}

article ol li {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  counter-increment: item;
}

article ol li:before {
  content: counter(item) ".";
  width: 28px;
  height: 8px;
  margin-left: 0;
  flex: 0 0 auto;
  font-family: "IBM Plex Sans Bold";
  font-size: .8em;
}

@media only screen and (max-width: 1800px) {
  article ol li:before {
    width: 26px;
    height: 6px;
  }
}

article a {
  color: #78B4BD;
  text-decoration: underline;
  transition: color .5s;
}

@media only screen and (min-width: 1201px) {
  article a:hover {
    color: #12474F;
  }
}

article img {
  display: block;
  margin: 50px auto;
  position: relative;
  width: 100%;
}

article .slider {
  margin: 120px 0 100px;
  width: 100%;
}

article .slider-item {
  height: 450px;
}

@media only screen and (max-width: 1800px) {
  article .slider-item {
    height: 350px;
  }
}

@media only screen and (max-width: 750px) {
  article .slider-item {
    height: 170px;
  }
}

article .slider-item img {
  display: block;
  margin: auto;
  width: 100%;
  left: unset;
}

article .slider-control {
  justify-content: center;
  padding-left: 260px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1800px) {
  article .slider-control {
    margin-top: 0;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 750px) {
  article .slider-control {
    padding-right: 20px;
    padding-left: 20px;
  }
}

article .slider-control .btn_prev, article .slider-control .btn_next {
  cursor: pointer;
}

.news-head {
  margin: auto;
  margin-top: 300px;
  width: 1417px;
  padding-left: 67px;
}

@media only screen and (max-width: 1800px) {
  .news-head {
    width: 890px;
    padding-left: 0;
    margin-top: 160px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-head {
    width: 616px;
  }
}

@media only screen and (max-width: 750px) {
  .news-head {
    width: 100%;
    margin-top: 100px;
  }
}

.news-head_title {
  padding-left: 100px;
  position: relative;
  padding-right: 200px;
}

@media only screen and (max-width: 1800px) {
  .news-head_title {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-head_title {
    margin-right: -30px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .news-head_title {
    padding-right: 55px;
    padding-left: 55px;
    margin: 0 20px;
    min-height: 120px;
  }
}

.news-head_category {
  color: #546467;
  opacity: .44;
  font-size: 20px;
  line-height: 25px;
  font-family: "IBM Plex Sans SemiBold";
  margin-bottom: 30px;
}

@media only screen and (max-width: 1800px) {
  .news-head_category {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 750px) {
  .news-head_category {
    font-size: 16px;
  }
}

.news-head_date {
  width: 206px;
  height: 40px;
  font-size: 16px;
  font-family: "IBM Plex Sans Bold";
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 13px;
  position: absolute;
  top: 10px;
  left: 0px;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left top;
  background: #BBADA0;
}

@media only screen and (max-width: 1800px) {
  .news-head_date {
    left: -25px;
    width: 134px;
    height: 34px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 750px) {
  .news-head_date {
    left: 0;
    width: 116px;
    font-size: 13px;
  }
}

.news-head .scroll-rect {
  width: 1494px;
  height: 429px;
  position: absolute;
  background: #A0AAB2;
  opacity: 0.2;
  z-index: -1;
  top: 50px;
  left: -40px;
}

@media only screen and (max-width: 1800px) {
  .news-head .scroll-rect {
    width: 986px;
    height: 283px;
    left: -30px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-head .scroll-rect {
    width: 682px;
    height: 195px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-head .scroll-rect {
    display: none;
  }
}

.news-head_imege {
  width: 100%;
  height: 590px;
  background-position: center;
  background-size: cover;
  margin-top: 190px;
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .news-head_imege {
    height: 390px;
    margin-top: 95px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-head_imege {
    height: 270px;
  }
}

@media only screen and (max-width: 750px) {
  .news-head_imege {
    height: 200px;
    margin-top: 45px;
  }
}

.news-bottom {
  border-top: 1px #E9E8E4 solid;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 115px;
  width: 870px;
}

@media only screen and (max-width: 1800px) {
  .news-bottom {
    width: 720px;
    height: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-bottom {
    width: 670px;
  }
}

@media only screen and (max-width: 750px) {
  .news-bottom {
    width: 290px;
    height: 60px;
  }
}

.news-bottom .ico svg {
  fill: #B4BBBC;
}

.news-bottom_date {
  font-size: 20px;
  color: #B4BBBC;
  font-family: "IBM Plex Sans SemiBold";
}

@media only screen and (max-width: 1800px) {
  .news-bottom_date {
    font-size: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .news-bottom_date {
    font-size: 15px;
  }
}

.news-form {
  background: #175059;
  margin: auto;
  width: 870px;
  padding: 107px 119px 112px 135px;
  position: relative;
  color: #fff;
  margin-bottom: 110px;
  margin-top: 70px;
}

@media only screen and (max-width: 1800px) {
  .news-form {
    width: 720px;
    margin-top: 40px;
    margin-bottom: 130px;
    padding: 107px 99px 102px 105px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-form {
    width: 670px;
    padding: 107px 79px 102px 77px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 750px) {
  .news-form {
    width: 290px;
    padding: 50px 35px 50px 35px;
    margin-top: 30px;
  }
}

.news-form_right {
  display: flex;
  justify-content: flex-end;
}

.news-form .form-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 76px;
  margin-bottom: 74px;
}

@media only screen and (max-width: 1800px) {
  .news-form .form-wrap {
    margin-bottom: 55px;
    margin-top: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .news-form .form-wrap {
    margin: 0px;
    display: block;
  }
}

.news-form .form-wrap .form-group {
  width: 310px;
}

@media only screen and (max-width: 1800px) {
  .news-form .form-wrap .form-group {
    width: 300px;
  }
}

@media only screen and (max-width: 750px) {
  .news-form .form-wrap .form-group {
    width: 100%;
  }
}

.news-form .form-group {
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .news-form .form-group {
    margin-bottom: 47px;
  }
}

.news-form .form-group.tel {
  width: 242px;
}

@media only screen and (max-width: 1800px) {
  .news-form .form-group.tel {
    width: 180px;
  }
}

.news-form .help-block {
  position: absolute;
  top: 100%;
  left: 0;
  color: #ED8080;
  font-size: 14px;
  font-family: "IBM Plex Sans Medium";
  opacity: 0;
  transition: opacity .5s;
  background: rgba(255, 14, 14, 0.2);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 19px;
  margin-top: 8px;
}

@media only screen and (max-width: 1800px) {
  .news-form .help-block {
    font-size: 13px;
    height: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .news-form .help-block {
    font-size: 11px;
    margin-top: 4px;
    height: 30px;
  }
}

.news-form .has-error .help-block {
  opacity: 1;
}

.news-form .form-control {
  width: 100%;
  border-bottom: 4px rgba(78, 131, 140, 0.2) solid;
  background: none;
  color: #fff;
  font-size: 20px;
  font-family: "IBM Plex Sans Medium";
  height: 50px;
}

@media only screen and (max-width: 1800px) {
  .news-form .form-control {
    font-size: 16px;
    height: 35px;
    border-bottom: 3px rgba(78, 131, 140, 0.2) solid;
  }
}

.news-form .form-control::placeholder {
  color: #4E838C;
}

.news-form .form-control:focus {
  border-bottom: 3px #4E838C solid;
}

.news-form .success-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 115px 130px 130px 130px;
  background: #124851;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
}

.news-form .success-form.active {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1800px) {
  .news-form .success-form {
    padding: 75px 95px 95px 95px;
  }
}

@media only screen and (max-width: 750px) {
  .news-form .success-form {
    padding: 56px 30px 70px 30px;
  }
}

@media only screen and (max-width: 1800px) {
  .news-form .btn, .news-form .btn_white, .news-form .btn_prev, .news-form .btn_next {
    width: 200px;
  }
}

@media only screen and (max-width: 750px) {
  .news-form .btn, .news-form .btn_white, .news-form .btn_prev, .news-form .btn_next {
    margin: auto;
  }
}

.footer {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #0B363C;
}

@media only screen and (max-width: 1800px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer {
    padding-top: 95px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 750px) {
  .footer {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.footer_wrap {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 1536px;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .footer_wrap {
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .footer_wrap {
    width: 1050px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer_wrap {
    width: 500px;
  }
}

@media only screen and (max-width: 750px) {
  .footer_wrap {
    width: 100%;
    padding: 0 45px;
  }
}

.footer_item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .footer_item {
    display: block;
  }
}

.footer_logo {
  width: 132px;
  margin-right: 150px;
  margin-top: 5px;
  display: block;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .footer_logo {
    width: 102px;
    margin-right: 100px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer_logo {
    width: 102px;
    margin-bottom: 40px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 750px) {
  .footer_logo {
    margin-bottom: 55px;
  }
}

.footer_logo svg {
  fill: #547276;
  transition: fill .5s;
}

@media only screen and (min-width: 1201px) {
  .footer_logo:hover svg {
    fill: #6A9DA5;
  }
}

.footer .facility-logo {
  display: block;
  position: absolute;
  left: 282px;
  bottom: 0;
}

.footer .facility-logo svg {
  fill: #547276;
  transition: all .5s;
}

.footer .facility-logo:hover svg {
  fill: #6A9DA5;
}

@media only screen and (max-width: 1800px) {
  .footer .facility-logo {
    left: 202px;
    bottom: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer .facility-logo {
    left: 0px;
    bottom: 75px;
  }
}

@media only screen and (max-width: 750px) {
  .footer .facility-logo {
    display: none;
  }
}

.footer .facility-logo.mobile {
  display: none;
}

@media only screen and (max-width: 750px) {
  .footer .facility-logo.mobile {
    display: block;
    bottom: 15px;
    left: 45px;
  }
}

.footer_menu {
  font-size: 22px;
  line-height: 66px;
  font-family: "IBM Plex Sans SemiBold";
}

@media only screen and (max-width: 1800px) {
  .footer_menu {
    font-size: 16px;
    line-height: 48px;
  }
}

.footer_menu li {
  display: block;
}

.footer_menu a {
  display: block;
  color: #547276;
  transition: color .5s;
}

@media only screen and (min-width: 1201px) {
  .footer_menu a:hover {
    color: #6A9DA5;
  }
}

.footer_right {
  margin-top: 15px;
}

@media only screen and (max-width: 750px) {
  .footer_right {
    margin-top: 50px;
  }
}

.footer_right-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 135px;
}

@media only screen and (max-width: 1800px) {
  .footer_right-item {
    margin-left: 92px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer_right-item {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 750px) {
  .footer_right-item {
    margin-left: 0;
  }
}

.footer_lable {
  font-size: 22px;
  font-family: "IBM Plex Sans SemiBold";
  color: #547276;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1800px) {
  .footer_lable {
    font-size: 16px;
  }
}

.footer_row {
  margin-bottom: 20px;
  min-height: 150px;
}

@media only screen and (max-width: 1800px) {
  .footer_row {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer_row {
    margin-bottom: 55px;
    min-height: unset;
  }
}

.footer_row.soc {
  min-height: 50px;
}

.footer_row p,
.footer_row a {
  color: #3B5E63;
  font-size: 21px;
  font-family: "IBM Plex Sans Medium";
  line-height: 34px;
}

@media only screen and (max-width: 1800px) {
  .footer_row p,
  .footer_row a {
    font-size: 14px;
    line-height: 32px;
  }
}

.footer_row p a,
.footer_row a a {
  transition: color .5s;
  display: block;
}

@media only screen and (min-width: 1201px) {
  .footer_row p a:hover,
  .footer_row a a:hover {
    color: #6A9DA5;
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.preloader_logo {
  fill: #E6EAEB;
  width: 40px;
  position: relative;
}

.preloader i {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: linear;
  transition-duration: 0.5s;
}

.preloader i:nth-child(2) {
  left: 20%;
  transition-duration: 0.45s;
}

.preloader i:nth-child(3) {
  left: 40%;
  transition-duration: 0.4s;
}

.preloader i:nth-child(4) {
  left: 60%;
  transition-duration: 0.45s;
}

.preloader i:nth-child(5) {
  left: 80%;
  transition-duration: 0.5s;
}

.preloader.load-done {
  pointer-events: none;
}

.preloader.load-done i {
  transform: translateY(-100%);
  background: #E6EAEB  !important;
}

.header_logo {
  position: fixed;
  z-index: 110;
  width: 142px;
  left: 65px;
  top: 61px;
  transition: all 0.5s;
}

@media only screen and (max-width: 1800px) {
  .header_logo {
    width: 102px;
    left: 42px;
    top: 43px;
  }
}

@media only screen and (max-width: 1200px) {
  .header_logo {
    background: #175059;
    height: 64px;
    top: 25px;
    left: 27px;
    width: calc(100% - 54px);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
  }
}

@media only screen and (max-width: 750px) {
  .header_logo {
    height: 48px;
    left: 7px;
    top: 8px;
    width: calc(100% - 14px);
  }
}

.header_logo svg {
  display: block;
  fill: #fff;
  transition: fill .5s;
}

.header_logo svg .hide {
  transition: opacity .5s;
}

@media only screen and (max-width: 1200px) {
  .header_logo svg {
    width: 100px;
    height: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .header_logo svg {
    width: 80px;
    height: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .header_logo.active svg {
    fill: #175059;
  }
}

@media only screen and (min-width: 1200px) {
  .header_logo.scroll svg {
    fill: #fff;
  }
  .header_logo.scroll .hide {
    opacity: 0;
    visibility: hidden;
  }
}

@media only screen and (min-width: 1200px) {
  .header_logo.open-menu svg {
    fill: #fff;
  }
  .header_logo.open-menu .hide {
    opacity: 1;
    visibility: visible;
  }
}

.header_callback {
  position: fixed;
  z-index: 105;
  right: 29px;
  top: 28px;
  font-size: 20px;
  cursor: pointer;
  font-family: "IBM Plex Sans SemiBold";
  width: 100px;
  height: 100px;
  background: #175059;
}

@media only screen and (min-width: 1201px) {
  .header_callback:hover {
    background: #326B74;
  }
}

@media only screen and (max-width: 1800px) {
  .header_callback {
    right: 19px;
    top: 18px;
    width: 74px;
    height: 74px;
  }
}

@media only screen and (max-width: 1200px) {
  .header_callback {
    background: none;
    height: 64px;
    width: 64px;
    right: 27px;
    top: 25px;
    color: #fff;
  }
}

@media only screen and (max-width: 750px) {
  .header_callback {
    height: 48px;
    width: 48px;
    right: 7px;
    top: 8px;
  }
}

.header_callback svg {
  display: block;
  fill: #fff;
  width: 24px;
  height: 24px;
  position: absolute;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  top: 50%;
}

@media only screen and (max-width: 750px) {
  .header_callback svg {
    width: 15px;
    height: 15px;
  }
}

.header_callback span {
  display: block;
}

@media only screen and (max-width: 750px) {
  .header_callback span {
    display: none;
  }
}

.header-menu {
  position: fixed;
  z-index: 105;
  width: 108px;
  left: 29px;
  top: 28px;
  height: calc(100% - 56px);
  transition: background .5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1800px) {
  .header-menu {
    width: 74px;
    left: 19px;
    top: 18px;
    height: calc(100% - 36px);
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu {
    width: 64px;
    height: 64px;
    left: 27px;
    top: 25px;
  }
}

@media only screen and (max-width: 750px) {
  .header-menu {
    width: 48px;
    height: 48px;
    left: 7px;
    top: 8px;
  }
}

.header-menu_btn {
  width: 100%;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  z-index: 10;
}

@media only screen and (max-width: 1800px) {
  .header-menu_btn {
    height: 74px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu_btn {
    height: 100%;
    background: #175059;
  }
}

@media only screen and (max-width: 750px) {
  .header-menu_btn {
    height: 100%;
  }
}

.header-menu_btn i {
  display: block;
  width: 13px;
  height: 3px;
  background: #7E9091;
  margin-bottom: 5px;
  transition: all .3s;
}

.header-menu_btn i.not-news {
  background: #fff;
}

@media only screen and (max-width: 1800px) {
  .header-menu_btn i {
    width: 10px;
    height: 2px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu_btn i {
    width: 14px;
    height: 3px;
    margin-bottom: 5px;
    background: #E6EAEB;
  }
}

@media only screen and (max-width: 750px) {
  .header-menu_btn i {
    width: 10px;
    height: 2px;
    margin-bottom: 4px;
    background: #E6EAEB;
  }
}

.header-menu_btn i:last-child {
  margin-bottom: 0;
}

.header-menu_wrap {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  visibility: hidden;
  background: #0B363C;
  transition: width .5s .25s, height 0s 1s, visibility 1s;
}

.header-menu_content {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 58px);
  height: 100%;
  z-index: 2;
  opacity: 0;
  transition: opacity .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 35px -25px #000;
}

@media only screen and (max-width: 1800px) {
  .header-menu_content {
    width: calc(100vw - 38px);
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu_content {
    overflow: auto;
    display: block;
    width: calc(100vw - 54px);
    height: calc(100vh - 50px);
  }
}

@media only screen and (max-width: 750px) {
  .header-menu_content {
    width: calc(100vw - 14px);
    height: 100%;
  }
}

.header-menu.scroll {
  background: #12474F;
}

@media only screen and (max-width: 1200px) {
  .header-menu.scroll {
    background: #326B74;
  }
}

.header-menu.scroll .header-menu_btn i {
  background: #3E717F;
}

@media only screen and (max-width: 1200px) {
  .header-menu.scroll .header-menu_btn i {
    background: #E6EAEB;
  }
}

.header-menu.active .header-menu_btn {
  background: none;
}

.header-menu.active .header-menu_btn i {
  background: #3E717F;
  margin: 0;
  width: 20px;
}

.header-menu.active .header-menu_btn i:first-child {
  transform: rotate(-45deg);
}

.header-menu.active .header-menu_btn i:nth-child(2) {
  height: 0;
}

.header-menu.active .header-menu_btn i:last-child {
  transform: rotate(45deg);
  margin-top: -3px;
}

@media only screen and (max-width: 1800px) {
  .header-menu.active .header-menu_btn i:last-child {
    margin-top: -2px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu.active .header-menu_btn i:last-child {
    margin-top: -3px;
  }
}

@media only screen and (max-width: 750px) {
  .header-menu.active .header-menu_btn i:last-child {
    margin-top: -2px;
  }
}

.header-menu.active .header-menu_li {
  transform: none;
  flex: 1;
}

.header-menu.active .header-menu_li:nth-child(1) {
  transition: transform .5s;
}

.header-menu.active .header-menu_li:nth-child(2) {
  transition: transform .5s .05s;
}

.header-menu.active .header-menu_li:nth-child(3) {
  transition: transform .5s .1s;
}

.header-menu.active .header-menu_li:nth-child(4) {
  transition: transform .5s .15s;
}

.header-menu.active .header-menu_li:nth-child(5) {
  transition: transform .5s .2s;
}

.header-menu.active .header-menu_li:nth-child(6) {
  transition: transform .5s .25s;
}

.header-menu.active .header-menu_lang_li {
  transform: none;
  transition: transform .5s .5s, opacity .5s .5s;
  opacity: 1;
}

.header-menu.active .header-menu_wrap {
  transition: width .5s, height 0s, visibility 0s;
  visibility: visible;
  width: calc(100vw - 58px);
}

@media only screen and (max-width: 1800px) {
  .header-menu.active .header-menu_wrap {
    width: calc(100vw - 38px);
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu.active .header-menu_wrap {
    width: calc(100vw - 54px);
    height: calc(100vh - 50px);
  }
}

@media only screen and (max-width: 750px) {
  .header-menu.active .header-menu_wrap {
    width: calc(100vw - 14px);
    height: calc(100vh - 16px);
  }
}

.header-menu.active .header-menu_content {
  opacity: 1;
  transition: opacity .5s;
}

.header-menu_ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  line-height: 54px;
  height: 54px;
  padding: 0 100px;
}

@media only screen and (max-width: 1800px) {
  .header-menu_ul {
    line-height: 34px;
    height: 34px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu_ul {
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 80px 0;
    min-height: 100vw;
  }
}

@media only screen and (max-width: 750px) {
  .header-menu_ul {
    padding: 40px 0;
    padding-bottom: 0;
    height: calc(100% - 160px);
    min-height: calc(100vw - 160px);
  }
}

.header-menu_li {
  display: block;
  transform: translateY(50px);
  position: relative;
}

.header-menu_li i {
  position: absolute;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  top: 50%;
  z-index: -1;
  pointer-events: none;
}

@media only screen and (max-width: 1800px) {
  .header-menu_li i {
    position: absolute;
    transform: translate(-50%, -50%) scale(0.7);
    left: 50%;
    top: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu_li i {
    display: none;
  }
}

.header-menu_li i:before, .header-menu_li i:after {
  content: '';
  position: absolute;
  background-size: cover;
  background-position: center top;
  width: 100px;
  height: 100px;
  opacity: 0;
  transform: translatey(50px);
  transition: opacity .5s, transform .5s;
}

.header-menu_li i:after {
  transform: translatey(-50px);
}

.header-menu_li:nth-child(6) {
  transition: transform .5s;
}

.header-menu_li:nth-child(6) i:before {
  top: -36px;
  right: calc(50% + 5px);
  width: 49px;
  height: 157px;
  background-image: url("../img/general/menu/6-1.png");
}

.header-menu_li:nth-child(6) i:after {
  top: -177px;
  left: calc(50% + 19px);
  width: 107px;
  height: 267px;
  background-image: url("../img/general/menu/6-2.png");
}

.header-menu_li:nth-child(5) {
  transition: transform .5s .05s;
}

.header-menu_li:nth-child(5) i:before {
  top: -190px;
  right: calc(50% - 8px);
  width: 128px;
  height: 232px;
  background-image: url("../img/general/menu/5-1.png");
}

.header-menu_li:nth-child(5) i:after {
  top: -90px;
  left: calc(50% + 22px);
  width: 64px;
  height: 182px;
  background-image: url("../img/general/menu/5-2.png");
}

.header-menu_li:nth-child(4) {
  transition: transform .5s .1s;
}

.header-menu_li:nth-child(4) i:before {
  top: -100px;
  right: calc(50% + 10px);
  width: 125px;
  height: 243px;
  background-image: url("../img/general/menu/4-1.png");
}

.header-menu_li:nth-child(4) i:after {
  top: -30px;
  left: calc(50% + 10px);
  width: 54px;
  height: 147px;
  background-image: url("../img/general/menu/4-2.png");
}

.header-menu_li:nth-child(3) {
  transition: transform .5s .15s;
}

.header-menu_li:nth-child(3) i:before {
  top: -94px;
  right: calc(50% + 32px);
  width: 64px;
  height: 192px;
  background-image: url("../img/general/menu/3-1.png");
}

.header-menu_li:nth-child(3) i:after {
  top: -150px;
  left: calc(50% - 20px);
  width: 117px;
  height: 272px;
  background-image: url("../img/general/menu/3-2.png");
}

.header-menu_li:nth-child(2) {
  transition: transform .5s .2s;
}

.header-menu_li:nth-child(2) i:before {
  top: -194px;
  right: calc(50% - 47px);
  width: 117px;
  height: 293px;
  background-image: url("../img/general/menu/2-1.png");
}

.header-menu_li:nth-child(2) i:after {
  top: -150px;
  left: calc(50% + 72px);
  width: 54px;
  height: 172px;
  background-image: url("../img/general/menu/2-2.png");
}

.header-menu_li:nth-child(1) {
  transition: transform .5s .25s;
}

.header-menu_li:nth-child(1) i:before {
  top: -60px;
  right: calc(50% + 37px);
  width: 64px;
  height: 182px;
  background-image: url("../img/general/menu/1-1.png");
}

.header-menu_li:nth-child(1) i:after {
  top: -160px;
  left: calc(50% - 16px);
  width: 128px;
  height: 232px;
  background-image: url("../img/general/menu/1-2.png");
}

.header-menu_li.active .header-menu_a {
  color: #fff;
}

.header-menu_li.active .header-menu_a:after {
  width: 65px;
}

@media only screen and (max-width: 750px) {
  .header-menu_li.active .header-menu_a:after {
    width: 45px;
  }
}

.header-menu_li.active .header-menu_a i:before, .header-menu_li.active .header-menu_a i:after {
  opacity: 1;
  transform: none;
}

.header-menu_a {
  display: block;
  text-align: center;
  color: #326B74;
  font-size: 34px;
  font-family: "IBM Plex Sans Medium";
  transition: color .5s;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 1800px) {
  .header-menu_a {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu_a br {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .header-menu_a {
    font-size: 16px;
  }
}

.header-menu_a:after {
  content: '';
  display: block;
  height: 4px;
  width: 0;
  background: #fff;
  transition: width .5s;
  margin: auto;
  margin-top: 10px;
}

@media only screen and (max-width: 1800px) {
  .header-menu_a:after {
    height: 3px;
  }
}

@media only screen and (max-width: 750px) {
  .header-menu_a:after {
    height: 2px;
    margin-top: 0;
  }
}

@media only screen and (min-width: 1201px) {
  .header-menu_a:hover {
    color: #ffffff;
  }
  .header-menu_a:hover:after {
    width: 65px;
  }
  .header-menu_a:hover i:before, .header-menu_a:hover i:after {
    opacity: 1;
    transform: none;
  }
}

.header-menu_lang {
  position: absolute;
  bottom: 35px;
  right: 38px;
  display: flex;
  z-index: 2;
}

@media only screen and (max-width: 1800px) {
  .header-menu_lang {
    bottom: 23px;
    right: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-menu_lang {
    position: fixed;
    bottom: 70px;
    right: 73px;
  }
}

@media only screen and (max-width: 750px) {
  .header-menu_lang {
    position: relative;
    bottom: auto;
    right: auto;
    margin: auto;
    height: 60px;
    justify-content: center;
    align-items: center;
  }
}

.header-menu_lang_li {
  display: block;
  margin-right: 30px;
  transition: transform .5s, opacity .5s;
  transform: translateY(50px);
  opacity: 0;
}

@media only screen and (max-width: 1800px) {
  .header-menu_lang_li {
    margin-right: 20px;
  }
}

.header-menu_lang_li:last-child {
  margin-right: 0;
}

.header-menu_lang_li.active .header-menu_lang_a {
  color: #3E717F;
}

.header-menu_lang_a {
  display: block;
  color: #175059;
  font-size: 20px;
  font-family: "IBM Plex Sans SemiBold";
  transition: color .5s;
}

@media only screen and (max-width: 1800px) {
  .header-menu_lang_a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1201px) {
  .header-menu_lang_a:hover {
    color: #3E717F;
  }
}

.id-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: rotate(-90deg);
  white-space: nowrap;
  z-index: 10;
}

@media only screen and (max-width: 1200px) {
  .id-text {
    display: none;
  }
}

.id-text span {
  margin-right: 18px;
}

.id-text-item {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity .5s;
  font-size: 17px;
  line-height: 18px;
  font-family: "IBM Plex Sans SemiBold";
  text-transform: uppercase;
  color: #447076;
}

@media only screen and (max-width: 1800px) {
  .id-text-item {
    font-size: 13px;
  }
}

.id-text-item.active {
  opacity: 1;
}

.id-text.open-menu .id-text-item {
  opacity: 0;
}

.id-text.open-menu .id-text-item.id-menu {
  opacity: 1;
}

.section {
  height: 100vh;
  position: relative;
  min-height: 720px;
}

@media only screen and (max-width: 1200px) {
  .section {
    min-height: auto;
    height: auto;
  }
}

.home-project {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  pointer-events: none;
}

@media only screen and (min-width: 1200px) {
  .home-project:not(.active) .cut-image-in {
    width: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .home-project {
    pointer-events: auto;
    justify-content: center;
    position: relative;
    padding-left: 270px;
  }
}

@media only screen and (max-width: 750px) {
  .home-project {
    padding-left: 0;
    padding-bottom: 160px;
  }
}

.home-project .cut-image {
  transition: opacity .5s;
}

.home-project_wrap {
  position: relative;
  height: 725px;
  width: 740px;
  transition: all .5s;
}

@media only screen and (max-width: 1800px) {
  .home-project_wrap {
    height: 470px;
    width: 450px;
  }
}

@media only screen and (max-width: 750px) {
  .home-project_wrap {
    height: 290px;
    width: 221px;
  }
}

@media only screen and (min-width: 1201px) {
  .home-project_wrap:hover .cut-image {
    opacity: .7;
  }
  .home-project_wrap:hover .home-project_text {
    opacity: .7;
  }
}

.home-project_content {
  position: absolute;
  bottom: 0;
  right: 100%;
  text-align: right;
  color: rgba(255, 255, 255, 0);
  transition: color .5s, padding .5s;
  padding-right: 65px;
}

@media only screen and (max-width: 1800px) {
  .home-project_content {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-project_content {
    color: #fff;
  }
}

@media only screen and (max-width: 750px) {
  .home-project_content {
    right: 0;
    top: 100%;
  }
}

.home-project_number {
  font-family: "IBM Plex Sans Bold";
  font-size: 23px;
  position: relative;
  line-height: 30px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1800px) {
  .home-project_number {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .home-project_number {
    margin-bottom: 0;
  }
}

.home-project_number:after {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(100% + 30px);
  height: 3px;
  width: 0;
  margin-top: -2px;
  background: #fff;
  transition: width .5s;
}

@media only screen and (max-width: 1800px) {
  .home-project_number:after {
    left: calc(100% + 20px);
  }
}

@media only screen and (max-width: 1200px) {
  .home-project_number:after {
    width: 35px;
    left: calc(100% + 25px);
  }
}

@media only screen and (max-width: 750px) {
  .home-project_number:after {
    left: calc(100% + 20px);
    width: 25px;
  }
}

.home-project_text {
  font-size: 20px;
  opacity: .23;
  font-family: "IBM Plex Sans SemiBold";
  line-height: 25px;
  white-space: nowrap;
  transition: opacity .5s;
}

@media only screen and (max-width: 1800px) {
  .home-project_text {
    font-size: 14px;
    line-height: 22.4px;
  }
}

@media only screen and (max-width: 750px) {
  .home-project_text {
    margin-top: 0;
    white-space: unset;
  }
}

.home-project_bg {
  position: absolute;
  top: 0;
  left: -95px;
  width: 100%;
  height: 100%;
  display: block;
  align-items: center;
  pointer-events: none;
}

.home-project_bg svg {
  opacity: 0.05;
  fill: #FEFEFE;
}

@media only screen and (max-width: 750px) {
  .home-project_bg {
    left: 0;
  }
}

.home-project .scroll-rect-r {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-project.active {
  z-index: 6;
  pointer-events: auto;
}

.home-project.active .home-project_content {
  color: #fff;
  transition: color .5s .3s, padding .5s;
}

.home-project.active .home-project_number:after {
  width: 65px;
  transition: width .5s .3s;
}

@media only screen and (max-width: 1800px) {
  .home-project.active .home-project_number:after {
    width: 55px;
  }
}

.project-2 .home-top_scroll-project {
  width: 276px;
  height: 1001px;
  opacity: .6;
  background: #082F35;
  left: 320px;
  top: -110px;
}

@media only screen and (max-width: 1800px) {
  .project-2 .home-top_scroll-project {
    width: 186px;
    height: 599px;
    left: 210px;
  }
}

@media only screen and (max-width: 750px) {
  .project-2 .home-top_scroll-project {
    width: 86px;
    height: 277px;
    left: 130px;
    top: -20px;
  }
}

.dark {
  background: #0B363C;
  color: #fff;
}

.home-top {
  width: 1530px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 1800px) {
  .home-top {
    width: 1020px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-top {
    width: 560px;
    height: 550px;
    padding-top: 160px;
  }
}

@media only screen and (max-width: 750px) {
  .home-top {
    padding: 47px;
    width: 100%;
    height: 360px;
    padding-bottom: 0;
    padding-top: 70px;
    max-width: 400px;
    z-index: 8;
  }
}

.home-top_bg {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.home-top_bg:after {
  width: 100%;
  height: 296px;
  z-index: -1;
  bottom: 50%;
  opacity: 0.4;
  margin-bottom: -360px;
  pointer-events: none;
}

@media only screen and (max-width: 750px) {
  .home-top .title-h1 br {
    display: none;
  }
}

.home-top_text {
  font-size: 20px;
  font-family: "IBM Plex Sans Medium";
  line-height: 30px;
  width: 360px;
  margin-top: 110px;
  opacity: 1;
  transition: opacity .5s, transform .5s;
}

@media only screen and (max-width: 1800px) {
  .home-top_text {
    font-size: 17px;
    line-height: 25.5px;
    width: 270px;
    margin-top: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .home-top_text {
    width: 100%;
    font-size: 14px;
    line-height: 22.4px;
    margin-top: 20px;
  }
}

.home-top_text.anim {
  opacity: 0;
  transform: translateY(10px);
}

.home-top_scroll-title {
  position: absolute;
  background: #194349;
  opacity: 0.1;
  width: 82px;
  height: 191px;
  left: 240px;
  top: 250px;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .home-top_scroll-title {
    width: 59px;
    height: 138px;
    left: 155px;
    top: 170px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-top_scroll-title {
    width: 48px;
    height: 112px;
    left: -30px;
    top: 170px;
  }
}

@media only screen and (max-width: 750px) {
  .home-top_scroll-title {
    width: 28px;
    height: 70px;
    left: 33px;
    top: 120px;
  }
}

.home-top_scroll-text {
  position: absolute;
  background: #194349;
  opacity: 0.1;
  width: 66px;
  height: 156px;
  left: 550px;
  top: 430px;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .home-top_scroll-text {
    width: 48px;
    height: 113px;
    left: 390px;
    top: 290px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-top_scroll-text {
    width: 39px;
    height: 91px;
    left: 140px;
    top: 480px;
  }
}

@media only screen and (max-width: 750px) {
  .home-top_scroll-text {
    width: 22px;
    height: 57px;
    left: 100px;
    top: 250px;
  }
}

.home-top_scroll-project {
  position: absolute;
  background: #194349;
  opacity: 0.2;
  width: 239px;
  height: 782px;
  left: 120px;
  top: -10px;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .home-top_scroll-project {
    width: 172px;
    height: 566px;
    left: 81px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-top_scroll-project {
    width: 139px;
    height: 458px;
    left: 261px;
    top: -220px;
  }
}

@media only screen and (max-width: 750px) {
  .home-top_scroll-project {
    width: 105px;
    height: 196px;
    right: -20px;
    top: -120px;
    left: auto;
  }
}

.home-top_scroll-content {
  position: absolute;
  background: #194349;
  opacity: 0.3;
  width: 95px;
  height: 373px;
  right: 190px;
  top: 470px;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .home-top_scroll-content {
    width: 64px;
    height: 274px;
    right: 90px;
    top: 340px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-top_scroll-content {
    width: 45px;
    height: 222px;
    right: -20px;
    top: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-top_scroll-content {
    width: 0px;
    height: 0px;
    right: 0px;
    top: 0px;
  }
}

.home-top_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0B363C;
  background-size: cover;
  background-position: center;
  transition: top 1s ease;
  z-index: 1;
}

.home-top-section.anim {
  background-position: center -100px;
}

.home-top-section.anim:before, .home-top-section.anim .home-top_image {
  top: -100px;
}

.home-top-section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #0B363C 10%, rgba(25, 67, 73, 0) 76%);
  transition: top 1s ease;
  z-index: 2;
}

.home-dev {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.home-dev_content {
  opacity: 1;
  transition: opacity .5s;
}

.home-dev_content.anim {
  opacity: 0;
}

@media only screen and (max-width: 1200px) {
  .home-dev_content {
    padding-top: 100px;
    padding-bottom: 160px;
  }
}

@media only screen and (max-width: 750px) {
  .home-dev_content {
    padding-top: 0px;
    padding-bottom: 108px;
  }
}

.home-dev_text {
  font-size: 20px;
  font-family: "IBM Plex Sans Regular";
  line-height: 30px;
  width: 510px;
  margin-top: 130px;
  margin-bottom: 80px;
  opacity: .43;
  transition: opacity .5s;
}

@media only screen and (max-width: 1800px) {
  .home-dev_text {
    font-size: 14px;
    line-height: 22.4px;
    width: 360px;
    margin-top: 105px;
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 750px) {
  .home-dev_text {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 45px;
  }
}

.home-dev-section {
  background: #0B363C url("../img/general/background/home-dev.png");
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1200px) {
  .home-dev-section {
    background-position: center bottom;
  }
}

.home-dev-section:after {
  width: 321px;
  height: 303px;
  top: 100px;
  opacity: 1;
  right: 347px;
  z-index: 12;
  pointer-events: none;
}

.home-why {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  opacity: 1;
  transition: opacity .5s;
}

.home-why.anim {
  opacity: 0;
}

@media only screen and (max-width: 1200px) {
  .home-why {
    padding-top: 170px;
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 750px) {
  .home-why {
    padding-top: 0;
    padding-bottom: 30px;
  }
}

.home-why_list {
  margin-top: 130px;
  width: 750px;
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .home-why_list {
    margin-top: 100px;
    width: 535px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-why_list {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .home-why_list {
    width: 100%;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 750px) {
  .home-why .list {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .home-why .list_title:before {
    left: -20px;
  }
}

.home-why_scroll-1 {
  position: absolute;
  top: -300px;
  left: 0;
  width: 99px;
  height: 360px;
  background: #000000;
  opacity: 0.05;
}

@media only screen and (max-width: 1800px) {
  .home-why_scroll-1 {
    width: 76px;
    height: 274px;
    top: -220px;
    left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .home-why_scroll-1 {
    width: 76px;
    height: 274px;
    top: -220px;
    left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .home-why_scroll-1 {
    display: none;
  }
}

.home-why_scroll-2 {
  position: absolute;
  top: 180px;
  left: 240px;
  width: 77px;
  height: 185px;
  background: #000000;
  opacity: 0.05;
}

@media only screen and (max-width: 1800px) {
  .home-why_scroll-2 {
    width: 59px;
    height: 141px;
    top: 110px;
    left: 160px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-why_scroll-2 {
    width: 59px;
    height: 141px;
    top: 110px;
    left: 160px;
  }
}

@media only screen and (max-width: 750px) {
  .home-why_scroll-2 {
    display: none;
  }
}

.home-why_scroll-3 {
  position: absolute;
  top: -160px;
  left: 500px;
  width: 77px;
  height: 249px;
  background: #000000;
  opacity: 0.05;
}

@media only screen and (max-width: 1800px) {
  .home-why_scroll-3 {
    width: 59px;
    height: 190px;
    top: -140px;
    left: 370px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-why_scroll-3 {
    width: 59px;
    height: 190px;
    top: -140px;
    left: 370px;
  }
}

@media only screen and (max-width: 750px) {
  .home-why_scroll-3 {
    display: none;
  }
}

.home-map_link, .footer-news_link {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 120px;
  align-items: center;
}

.home-map {
  position: relative;
  pointer-events: none;
}

.home-map_bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 100px;
}

@media only screen and (max-width: 1800px) {
  .home-map_bottom {
    bottom: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-map_bottom {
    bottom: 55px;
  }
}

@media only screen and (max-width: 750px) {
  .home-map_bottom {
    bottom: 35px;
  }
}

.home-map_bottom .home-map_link {
  display: none;
}

@media only screen and (max-width: 750px) {
  .home-map_bottom .home-map_link {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-map_bottom .wrapper {
    width: 550px;
  }
}

@media only screen and (max-width: 750px) {
  .home-map_bottom .wrapper {
    width: 100%;
  }
}

.home-map_link {
  pointer-events: auto;
}

@media only screen and (max-width: 1200px) {
  .home-map_link {
    right: -50px;
    height: 100px;
  }
}

@media only screen and (max-width: 750px) {
  .home-map_link {
    display: none;
    right: auto;
  }
}

.home-map_slider {
  position: relative;
  width: 1420px;
}

@media only screen and (max-width: 1800px) {
  .home-map_slider {
    width: 920px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-map_slider {
    width: 100%;
  }
}

.home-map_slide {
  width: 275px;
  height: 200px;
  background: #000;
  background-size: cover;
  position: relative;
  margin: 0 4px;
  cursor: pointer;
}

@media only screen and (max-width: 1800px) {
  .home-map_slide {
    width: 178px;
    height: 130px;
    margin: 0 3px;
  }
}

@media only screen and (max-width: 750px) {
  .home-map_slide {
    width: 109px;
    height: 80px;
    margin: 0 3px;
  }
}

.home-map_slide:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(1, 36, 41, 0.75);
  opacity: 1;
  transition: opacity .5s;
}

.home-map_slide.slick-center:after {
  opacity: 0;
}

@media only screen and (min-width: 1201px) {
  .home-map_slide:hover:after {
    opacity: 0;
  }
}

@media only screen and (max-width: 750px) {
  .home-map_controls {
    display: none !important;
  }
}

.home-map_controls .btn_prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -50px;
}

.home-map_controls .btn_next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
}

@media only screen and (max-width: 1200px) {
  .home-why-section {
    padding-bottom: 150px;
  }
}

@media only screen and (max-width: 750px) {
  .home-why-section {
    padding-bottom: 0;
  }
}

.home-why-section:after {
  width: 321px;
  height: 303px;
  bottom: 0px;
  opacity: 1;
  right: 347px;
  z-index: 12;
  pointer-events: none;
}

.home-map-section {
  padding-top: 130px;
}

@media only screen and (max-width: 1800px) {
  .home-map-section {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-map-section {
    padding-top: 90px;
    min-height: 978px;
  }
}

@media only screen and (max-width: 750px) {
  .home-map-section {
    min-height: 768px;
  }
}

#home-map {
  background: #092124;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#home-map:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(90% 120%, rgba(9, 34, 38, 0) 0%, #092124 100%);
}

button {
  background: none;
  border: none;
}

.btn, .btn_white, .btn_prev, .btn_next {
  width: 250px;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: "IBM Plex Sans SemiBold";
  color: #fff;
  justify-content: flex-end;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color .5s;
  background: none;
}

@media only screen and (max-width: 1800px) {
  .btn, .btn_white, .btn_prev, .btn_next {
    width: 180px;
    height: 54px;
    font-size: 15px;
  }
}

.btn span, .btn_white span, .btn_prev span, .btn_next span {
  margin-right: -8px;
  margin-left: 15px;
  transition: margin .5s;
  position: relative;
  pointer-events: none;
}

@media only screen and (max-width: 1800px) {
  .btn span, .btn_white span, .btn_prev span, .btn_next span {
    margin-right: -4px;
    margin-left: 10px;
  }
}

.btn:before, .btn_white:before, .btn_prev:before, .btn_next:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px rgba(255, 255, 255, 0.1) solid;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .btn:before, .btn_white:before, .btn_prev:before, .btn_next:before {
    border-width: 2px;
  }
}

.btn:after, .btn_white:after, .btn_prev:after, .btn_next:after {
  content: '';
  background: #326B74;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  z-index: -1;
  transition: width .5s;
}

@media only screen and (min-width: 1201px) {
  .btn:hover span, .btn_white:hover span, .btn_prev:hover span, .btn_next:hover span {
    margin-left: 15px;
    margin-right: 0;
  }
  .btn:hover:after, .btn_white:hover:after, .btn_prev:hover:after, .btn_next:hover:after {
    width: 100%;
  }
}

.btn_white {
  color: #0B363C;
}

.btn_white:before {
  border-color: #E6EAEB !important;
}

@media only screen and (min-width: 1201px) {
  .btn_white:hover {
    color: #fff;
  }
}

.btn_prev {
  width: 119px;
  justify-content: flex-start;
}

.btn_prev:before {
  border-right-width: 2px;
  border-color: #2B5156;
}

.btn_prev:after {
  left: auto;
  right: 0;
}

.btn_prev span {
  margin-right: 15px;
  margin-left: -8px;
}

@media only screen and (min-width: 1201px) {
  .btn_prev:hover span {
    margin-right: 15px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1800px) {
  .btn_prev {
    width: 90px;
  }
}

.btn_next {
  width: 120px;
}

.btn_next:before {
  border-left-width: 2px;
  border-color: #2B5156;
}

@media only screen and (max-width: 1800px) {
  .btn_next {
    width: 90px;
  }
}

.controls {
  display: flex;
  justify-content: center;
}

.news-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1200px;
  margin: auto;
  margin-top: 130px;
  margin-bottom: 155px;
}

@media only screen and (max-width: 1800px) {
  .news-wrap {
    margin-top: 113px;
    margin-bottom: 68px;
    width: 880px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-wrap {
    width: 540px;
    margin-top: 83px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 750px) {
  .news-wrap {
    width: 100%;
    margin-top: 86px;
    margin-bottom: 0px;
    flex-direction: column;
    align-items: center;
  }
}

.news-item {
  position: relative;
  width: 420px;
  margin-bottom: 60px;
  transition: opacity .5s;
}

@media only screen and (max-width: 1800px) {
  .news-item {
    width: 310px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item {
    width: 240px;
  }
}

.news-item.hide {
  opacity: 0;
}

.news-item .scroll-rect {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}

@media only screen and (max-width: 1200px) {
  .news-item:nth-child(4n -3) {
    margin-left: 20px;
    margin-top: 0;
    margin-bottom: 124px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item:nth-child(4n -3) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 90px;
  }
}

.news-item:nth-child(4n -3) .news-item_img {
  border-color: rgba(187, 173, 160, 0.5);
}

.news-item:nth-child(4n -3) .scroll-rect {
  width: 493px;
  height: 303px;
  background: #BBADA0;
  left: -13px;
  top: 40px;
}

@media only screen and (max-width: 1800px) {
  .news-item:nth-child(4n -3) .scroll-rect {
    width: 366px;
    height: 225px;
    top: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item:nth-child(4n -3) .scroll-rect {
    width: 278px;
    height: 184px;
  }
}

.news-item:nth-child(4n -3) .news-item_date {
  background: #BBADA0;
}

.news-item:nth-child(4n -2) {
  margin-top: 90px;
  margin-right: 66px;
}

@media only screen and (max-width: 1800px) {
  .news-item:nth-child(4n -2) {
    margin-right: 84px;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-item:nth-child(4n -2) {
    margin-left: auto;
    margin-right: 57px;
    margin-top: 0;
    margin-bottom: 124px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item:nth-child(4n -2) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 90px;
  }
}

.news-item:nth-child(4n -2) .news-item_img {
  border-color: rgba(160, 170, 178, 0.5);
}

.news-item:nth-child(4n -2) .news-item_date {
  background: #A0AAB2;
}

.news-item:nth-child(4n -2) .scroll-rect {
  width: 430px;
  height: 310px;
  background: #A0AAB2;
  left: 50px;
  top: -40px;
}

@media only screen and (max-width: 1800px) {
  .news-item:nth-child(4n -2) .scroll-rect {
    width: 306px;
    height: 225px;
    left: 40px;
    top: -20px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item:nth-child(4n -2) .scroll-rect {
    width: 217px;
    height: 184px;
    left: 50px;
  }
}

.news-item:nth-child(4n -1) {
  margin-top: 74px;
  margin-left: 150px;
}

@media only screen and (max-width: 1800px) {
  .news-item:nth-child(4n -1) {
    margin-left: 87px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-item:nth-child(4n -1) {
    margin-left: 44px;
    margin-top: 0;
    margin-bottom: 124px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item:nth-child(4n -1) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 90px;
  }
}

.news-item:nth-child(4n -1) .news-item_img {
  border-color: rgba(159, 174, 162, 0.5);
}

.news-item:nth-child(4n -1) .news-item_date {
  background: #9FAEA2;
}

.news-item:nth-child(4n -1) .scroll-rect {
  width: 401px;
  height: 345px;
  background: #9FAEA2;
  left: -83px;
  top: -70px;
}

@media only screen and (max-width: 1800px) {
  .news-item:nth-child(4n -1) .scroll-rect {
    width: 326px;
    height: 245px;
    left: -39px;
    top: -35px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item:nth-child(4n -1) .scroll-rect {
    width: 240px;
    height: 184px;
    left: -30px;
  }
}

.news-item:nth-child(4n) {
  margin-top: 175px;
}

@media only screen and (max-width: 1800px) {
  .news-item:nth-child(4n) {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-item:nth-child(4n) {
    margin-left: auto;
    margin-right: 57px;
    margin-top: 0;
    margin-bottom: 124px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item:nth-child(4n) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 90px;
  }
}

.news-item:nth-child(4n) .news-item_img {
  border-color: rgba(209, 198, 173, 0.5);
}

.news-item:nth-child(4n) .news-item_date {
  background: #D1C6AD;
}

.news-item:nth-child(4n) .scroll-rect {
  width: 282px;
  height: 372px;
  background: #D1C6AD;
  left: 75px;
  top: -40px;
}

@media only screen and (max-width: 1800px) {
  .news-item:nth-child(4n) .scroll-rect {
    width: 206px;
    height: 285px;
    top: -20px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item:nth-child(4n) .scroll-rect {
    width: 161px;
    height: 224px;
    left: 50px;
  }
}

.news-item_date {
  width: 125px;
  height: 40px;
  font-size: 16px;
  font-family: "IBM Plex Sans Bold";
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 13px;
  position: absolute;
  top: -13px;
  left: -18px;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left top;
}

@media only screen and (max-width: 1800px) {
  .news-item_date {
    width: 103px;
    height: 34px;
    font-size: 13px;
  }
}

.news-item_img {
  width: 100%;
  height: 310px;
  box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.5);
  position: relative;
  transition: border .5s;
  border: 0px rgba(187, 173, 160, 0.5) solid;
}

@media only screen and (max-width: 1800px) {
  .news-item_img {
    height: 228px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item_img {
    height: 180px;
  }
}

.news-item_title {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 24px;
  line-height: 39px;
  color: #102935;
  width: 100%;
  position: relative;
  padding-left: 30px;
  margin-top: 45px;
  transition: opacity .5s;
}

@media only screen and (max-width: 1800px) {
  .news-item_title {
    font-size: 16px;
    line-height: 24px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item_title {
    padding-left: 23px;
    margin-top: 30px;
  }
}

.news-item_title:before {
  content: '';
  width: 10px;
  height: 10px;
  background: #98C1D9;
  position: absolute;
  top: 16px;
  left: 0;
}

@media only screen and (max-width: 1800px) {
  .news-item_title:before {
    width: 8px;
    height: 8px;
    top: 8px;
    left: 5px;
  }
}

@media only screen and (max-width: 750px) {
  .news-item_title:before {
    left: 0;
  }
}

.news-item_link {
  position: relative;
  display: block;
}

@media only screen and (min-width: 1201px) {
  .news-item_link:hover .news-item_img {
    border-width: 22px;
  }
  .news-item_link:hover .news-item_title {
    opacity: 0.5;
  }
}

.news-load {
  font-size: 20px;
  color: #B4BBBC;
  font-family: "IBM Plex Sans SemiBold";
  text-align: center;
  margin-bottom: 250px;
}

@media only screen and (max-width: 1800px) {
  .news-load {
    font-size: 16px;
    margin-bottom: 130px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-load {
    margin-bottom: 150px;
  }
}

@media only screen and (max-width: 750px) {
  .news-load {
    margin-bottom: 90px;
  }
}

.news-top {
  height: 650px;
  position: relative;
  padding-top: 90px;
}

@media only screen and (max-width: 1800px) {
  .news-top {
    height: 460px;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-top {
    height: 490px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .news-top {
    height: 424px;
    padding-top: 0;
    padding-bottom: 130px;
  }
}

.news-top .title-h1 {
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  .news-top .title-h1 {
    width: 100%;
  }
}

.news-pagination {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #F3F3F3;
  width: 780px;
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 50px;
}

@media only screen and (max-width: 1800px) {
  .news-pagination {
    width: 600px;
    height: 64px;
    padding-left: 31px;
  }
}

@media only screen and (max-width: 1200px) {
  .news-pagination {
    width: 650px;
    height: 64px;
  }
}

@media only screen and (max-width: 750px) {
  .news-pagination {
    width: 213px;
    height: 170px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 22px;
    padding-bottom: 29px;
  }
}

.news-pagination-btn {
  margin-right: 56px;
  cursor: pointer;
  color: #ADB4B5;
  transition: color .3s;
  font-family: "IBM Plex Sans SemiBold";
  font-size: 20px;
}

@media only screen and (max-width: 1800px) {
  .news-pagination-btn {
    font-size: 16px;
    margin-right: 62px;
  }
}

@media only screen and (max-width: 750px) {
  .news-pagination-btn {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1201px) {
  .news-pagination-btn:hover {
    color: #738082;
  }
}

.news-pagination-btn.active {
  color: #0B363C;
}

.news-pagination-btn:last-child {
  margin-right: 0;
}

.show-title {
  color: #194349;
}

.show-title_row {
  position: relative;
  display: table;
}

.show-title_mask {
  opacity: 0.5;
}

.show-title_anim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: clip-path;
  transition-timing-function: linear;
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
}

.title-h1 {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 90px;
  line-height: 107px;
  color: #194349;
  letter-spacing: -1.25px;
}

@media only screen and (max-width: 1800px) {
  .title-h1 {
    font-size: 64px;
    line-height: 77px;
    letter-spacing: -0.89px;
  }
}

@media only screen and (max-width: 1200px) {
  .title-h1 {
    font-size: 56px;
    line-height: 77px;
    letter-spacing: -0.89px;
  }
}

@media only screen and (max-width: 750px) {
  .title-h1 {
    letter-spacing: -0.42px;
    font-size: 30px;
    line-height: 36px;
  }
}

.title-h1.white {
  color: #fff;
}

.title-project-h1 {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 130px;
  line-height: 143px;
  color: #194349;
  letter-spacing: -1.81px;
  width: 50%;
}

@media only screen and (max-width: 1800px) {
  .title-project-h1 {
    font-size: 64px;
    line-height: 77px;
    letter-spacing: -0.89px;
  }
}

@media only screen and (max-width: 1200px) {
  .title-project-h1 {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .title-project-h1 {
    letter-spacing: -0.5px;
    font-size: 30px;
    line-height: 47px;
  }
}

.title-project-h1.white {
  color: #fff;
}

.title-h2 {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 60px;
  line-height: 72px;
  color: #194349;
  position: relative;
  min-height: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: -0.83px;
  width: 100%;
}

@media only screen and (max-width: 1800px) {
  .title-h2 {
    font-size: 42px;
    letter-spacing: -0.58px;
    line-height: 55px;
    min-height: 98px;
  }
}

@media only screen and (max-width: 750px) {
  .title-h2 {
    font-size: 26px;
    letter-spacing: -0.36px;
    line-height: 36.5px;
    min-height: 70px;
    padding-left: 20px;
  }
}

.title-h2:before {
  content: '';
  position: absolute;
  top: 50%;
  height: 120px;
  transform: translateY(-50%);
  width: 7px;
  background: rgba(11, 54, 60, 0.1);
  left: -35px;
}

@media only screen and (max-width: 1800px) {
  .title-h2:before {
    height: 98px;
    left: -30px;
  }
}

@media only screen and (max-width: 750px) {
  .title-h2:before {
    height: 70px;
    width: 5px;
    left: 0;
  }
}

.title-h2.white {
  color: #fff;
}

.title-h2.white:before {
  background: rgba(255, 255, 255, 0.1);
}

.title-h3 {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 24px;
  line-height: 36px;
  color: #0B363C;
  position: relative;
  min-height: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 1800px) {
  .title-h3 {
    font-size: 16px;
    line-height: 25.6px;
    min-height: 98px;
  }
}

@media only screen and (max-width: 750px) {
  .title-h3 {
    font-size: 16px;
    line-height: 26px;
    min-height: 70px;
    padding-left: 20px;
    margin-right: -10px;
  }
  .title-h3 br {
    display: none;
  }
}

.title-h3:before {
  content: '';
  position: absolute;
  top: 50%;
  height: 100%;
  min-height: 120px;
  transform: translateY(-50%);
  width: 7px;
  background: rgba(11, 54, 60, 0.1);
  left: -35px;
}

@media only screen and (max-width: 1800px) {
  .title-h3:before {
    min-height: 98px;
    left: -30px;
  }
}

@media only screen and (max-width: 750px) {
  .title-h3:before {
    min-height: 70px;
    width: 5px;
    left: 0;
  }
}

.title-h3.white {
  color: #fff;
}

.title-h3.white:before {
  background: rgba(255, 255, 255, 0.1);
}

.title-news {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 70px;
  line-height: 84px;
  color: #194349;
  letter-spacing: -0, 97px;
}

@media only screen and (max-width: 1800px) {
  .title-news {
    font-size: 44px;
    letter-spacing: -0.89px;
    line-height: 52.8px;
  }
}

@media only screen and (max-width: 750px) {
  .title-news {
    font-size: 22px;
    color: #194349;
    letter-spacing: -0.31px;
    line-height: 30.8px;
  }
}

.socials {
  display: flex;
}

.ico {
  width: 44px;
  height: 44px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 1800px) {
  .ico {
    width: 34px;
    height: 34px;
  }
}

.ico:last-child {
  margin-right: 0;
}

.ico svg {
  transition: fill .5s;
  fill: #3B5E63;
}

.ico-telegram {
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 1800px) {
  .ico-telegram {
    width: 18px;
    height: 18px;
  }
}

.ico-facebook {
  width: 8.36px;
  height: 16.67px;
}

@media only screen and (max-width: 1800px) {
  .ico-facebook {
    width: 7.6px;
    height: 15.15px;
  }
}

.ico-instagram {
  width: 20.17px;
  height: 20.17px;
}

@media only screen and (max-width: 1800px) {
  .ico-instagram {
    width: 18.33px;
    height: 18.33px;
  }
}

.ico-youtube {
  width: 22.66px;
  height: 18px;
}

@media only screen and (max-width: 1800px) {
  .ico-youtube {
    width: 20.6px;
    height: 16.36px;
  }
}

@media only screen and (min-width: 1201px) {
  .ico:hover svg {
    fill: #809EA2;
  }
}

.cut-image {
  width: 552px;
  height: 725px;
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .cut-image {
    height: 470px;
    width: 360px;
  }
}

@media only screen and (max-width: 750px) {
  .cut-image {
    height: 290px;
    width: 221px;
  }
}

.cut-image .cut-image-in {
  position: absolute;
  transition: width .5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cut-image .cut-image-in .logo {
  width: 55%;
}

.cut-image .cut-image-in svg {
  fill: #fff;
}

.cut-image .cut-image-1 {
  transition-delay: .2s;
}

.cut-image .cut-image-3 {
  transition-delay: .1s;
}

.cut-image-style-1 .cut-image-1 {
  width: 38%;
  height: 100%;
  top: 0;
  left: 0;
}

.cut-image-style-1 .cut-image-2 {
  width: 28%;
  height: 74%;
  top: 21%;
  left: 47%;
}

.cut-image-style-1 .cut-image-3 {
  width: 17%;
  height: 55%;
  top: 8%;
  left: 83%;
}

.cut-image-style-2 {
  width: 100%;
  height: 100%;
}

.cut-image-style-2 .cut-image-1 {
  width: 13%;
  height: 40%;
  top: 46%;
  left: 0;
}

.cut-image-style-2 .cut-image-2 {
  width: 59%;
  height: 100%;
  top: 0;
  left: 16%;
}

.cut-image-style-2 .cut-image-3 {
  width: 20%;
  height: 64%;
  top: 19%;
  left: 80%;
}

.cut-image-style-project {
  width: 100%;
  height: 100%;
  opacity: 0.15;
}

.cut-image-style-project .cut-image-1 {
  width: 7%;
  height: 53%;
  top: 32%;
  left: 0;
}

.cut-image-style-project .cut-image-2 {
  width: 14%;
  height: 64%;
  top: 0;
  left: 14%;
}

.cut-image-style-project .cut-image-3 {
  width: 65%;
  height: 94%;
  bottom: 0;
  right: 0;
}

.popup {
  position: fixed;
  top: 0;
  right: -120%;
  width: 100%;
  height: 100%;
  z-index: 9999;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .7s;
}

@media only screen and (max-width: 750px) {
  .popup {
    overflow: auto;
  }
}

.popup.active {
  right: 0;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.popup.active .popup_content {
  transform: none;
}

@media only screen and (max-width: 1200px) {
  .popup.active .popup_content {
    transform: translate(50%, 0px);
  }
}

.popup_mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(2, 39, 48, 0.8);
}

.popup_content {
  position: absolute;
  top: 107px;
  right: 70px;
  padding: 115px 130px 130px 130px;
  background: #124851;
  transform: translateX(50px);
  transition: transform .5s;
}

@media only screen and (max-width: 1800px) {
  .popup_content {
    top: 83px;
    right: 44px;
    padding: 75px 95px 95px 95px;
  }
}

@media only screen and (max-width: 1200px) {
  .popup_content {
    right: 50%;
    transform: translate(50%, -50px);
  }
}

@media only screen and (max-width: 750px) {
  .popup_content {
    top: 66px;
    padding: 56px 30px 100px 30px;
  }
}

.popup_close {
  position: absolute;
  top: -44px;
  right: -7px;
  width: 16.3px;
  height: 16.3px;
  cursor: pointer;
}

@media only screen and (max-width: 1800px) {
  .popup_close {
    width: 14px;
    height: 14px;
    top: -36px;
    right: 0px;
  }
}

.popup_title {
  font-size: 26px;
  line-height: 33px;
  font-family: "IBM Plex Sans SemiBold";
  width: 470px;
}

@media only screen and (max-width: 1800px) {
  .popup_title {
    width: 420px;
    font-size: 22px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 750px) {
  .popup_title {
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
}

.popup .form-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 110px;
  margin-bottom: 74px;
}

@media only screen and (max-width: 1800px) {
  .popup .form-wrap {
    margin-bottom: 55px;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 750px) {
  .popup .form-wrap {
    margin: 50px 10px;
    display: block;
  }
}

.popup .form-group {
  width: 310px;
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .popup .form-group {
    width: 230px;
  }
}

@media only screen and (max-width: 750px) {
  .popup .form-group {
    margin: auto;
  }
}

.popup .form-group.tel {
  width: 224px;
  margin-left: 40px;
}

@media only screen and (max-width: 1800px) {
  .popup .form-group.tel {
    width: 180px;
    margin-left: 32px;
  }
}

@media only screen and (max-width: 750px) {
  .popup .form-group.tel {
    margin: auto;
    margin-top: 30px;
  }
}

.popup .help-block {
  position: absolute;
  top: 100%;
  left: 0;
  color: #ED8080;
  font-size: 14px;
  font-family: "IBM Plex Sans Medium";
  opacity: 0;
  transition: opacity .5s;
  background: rgba(255, 14, 14, 0.2);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 19px;
  margin-top: 8px;
}

@media only screen and (max-width: 1800px) {
  .popup .help-block {
    font-size: 13px;
    height: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .popup .help-block {
    font-size: 11px;
    margin-top: 4px;
    height: 30px;
  }
}

.popup .has-error .help-block {
  opacity: 1;
}

.popup .form-control {
  width: 100%;
  border-bottom: 3px rgba(78, 131, 140, 0.2) solid;
  background: none;
  color: #fff;
  font-size: 20px;
  font-family: "IBM Plex Sans Medium";
  height: 50px;
}

.popup .form-control::placeholder {
  color: #4E838C;
}

@media only screen and (max-width: 1800px) {
  .popup .form-control {
    font-size: 16px;
    height: 35px;
  }
}

.popup .form-control:focus {
  border-bottom: 3px #4E838C solid;
}

.popup .success-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 115px 130px 130px 130px;
  background: #124851;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
}

.popup .success-form.active {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1800px) {
  .popup .success-form {
    padding: 75px 95px 95px 95px;
  }
}

@media only screen and (max-width: 750px) {
  .popup .success-form {
    padding: 56px 30px 70px 30px;
  }
}

@media only screen and (max-width: 750px) {
  .popup .btn, .popup .btn_white, .popup .btn_prev, .popup .btn_next {
    margin: auto;
    position: absolute;
  }
}

@media only screen and (max-width: 750px) {
  .popup button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.list_li {
  margin-bottom: 110px;
}

@media only screen and (max-width: 1800px) {
  .list_li {
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 750px) {
  .list_li {
    margin-bottom: 40px;
  }
}

.list_title {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  font-family: "IBM Plex Sans SemiBold";
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .list_title {
    font-size: 16px;
    line-height: 20px;
  }
}

.list_title:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -40px;
  width: 11px;
  height: 12px;
  transform: translateY(-50%);
  background: #779EB5;
}

@media only screen and (max-width: 1800px) {
  .list_title:before {
    width: 8px;
    height: 8px;
    left: -30px;
  }
}

.list_text {
  font-size: 20px;
  line-height: 30px;
  opacity: .43;
  margin-top: 22px;
  width: 330px;
}

@media only screen and (max-width: 1800px) {
  .list_text {
    font-size: 14px;
    line-height: 22.4px;
    width: 230px;
    margin-top: 18px;
  }
}

@media only screen and (max-width: 750px) {
  .list_text {
    width: 100%;
  }
}

.footer-news {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 1190px;
}

@media only screen and (max-width: 1800px) {
  .footer-news {
    width: 790px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-news {
    padding-top: 150px;
    padding-bottom: 170px;
    width: 475px;
  }
}

@media only screen and (max-width: 750px) {
  .footer-news {
    width: 100%;
    padding-top: 70px;
    padding-bottom: 90px;
  }
}

.footer-news_link {
  align-items: flex-end;
  bottom: 0;
  top: auto;
}

@media only screen and (max-width: 1800px) {
  .footer-news_link {
    right: -40px;
  }
}

.footer-news_top {
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .footer-news_top .footer-news_link {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .footer-news_top {
    width: 220px;
    margin: auto;
  }
}

.footer-news_bottom {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .footer-news_bottom {
    display: flex;
    justify-content: center;
  }
}

.footer-news_item {
  display: flex;
  justify-content: space-between;
  margin-top: 130px;
  width: 100%;
  margin-left: 77px;
}

@media only screen and (max-width: 1800px) {
  .footer-news_item {
    margin-left: 35px;
    margin-top: 90px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-news_item {
    margin-left: 0;
    margin-top: 115px;
    margin-bottom: 60px;
    justify-content: center;
  }
}

@media only screen and (max-width: 750px) {
  .footer-news_item {
    margin-top: 60px;
    margin-bottom: 0;
  }
}

.footer-news .news-item {
  margin-bottom: 0;
}

@media only screen and (max-width: 750px) {
  .footer-news .news-item {
    margin-bottom: 60px !important;
  }
}

.footer-news .news-item:nth-child(1):before {
  opacity: .2;
  top: 7px;
  left: -137px;
  width: 217px;
  height: 209px;
}

@media only screen and (max-width: 1800px) {
  .footer-news .news-item:nth-child(1):before {
    top: -25px;
  }
}

.footer-news .news-item:nth-child(2) {
  margin-top: 40px;
  margin-right: 86px;
}

.footer-news .news-item:nth-child(2):before {
  opacity: .2;
  top: 30px;
  left: -70px;
  width: 217px;
  height: 209px;
}

@media only screen and (max-width: 1800px) {
  .footer-news .news-item:nth-child(2):before {
    top: 5px;
    left: -90px;
  }
}

@media only screen and (max-width: 1800px) {
  .footer-news .news-item:nth-child(2) {
    margin-right: -7px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-news .news-item:nth-child(2) {
    display: none;
  }
}

.footer-news_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
}

.footer-news_bg:before {
  opacity: .2;
  top: 50%;
  right: -70px;
  width: 217px;
  height: 209px;
  margin-top: -130px;
}

@media only screen and (max-width: 1800px) {
  .footer-news_bg:before {
    right: -150px;
    margin-top: 10px;
  }
}

.footer-news_r {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.footer-news_r svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #C7CEDB;
  opacity: .15;
  width: 749px;
  height: 808px;
}

@media only screen and (max-width: 1800px) {
  .footer-news_r svg {
    width: 387px;
    height: 538px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-news_r svg {
    width: 466px;
    height: 502px;
  }
}

@media only screen and (max-width: 750px) {
  .footer-news_r svg {
    width: 302px;
    height: 326px;
  }
}

.marker {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  cursor: pointer;
  z-index: 1;
}

.marker:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: #98C1D9;
  box-shadow: 0 0 9px 0 #A4CBD8, 0 0 30px 0 #A4CBD8;
  width: 12px;
  height: 12px;
  transition: all .3s;
}

@media only screen and (max-width: 1800px) {
  .marker {
    width: 7px;
    height: 7px;
  }
}

.marker a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.marker a:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  opacity: 0.05;
  background: #78AAB2;
  border-radius: 100%;
  width: 0;
  height: 0;
  transition: width .3s, height .3s;
}

.marker a:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  opacity: 0.1;
  background: #98C1D9;
  border-radius: 100%;
  width: 0;
  height: 0;
  transition: width .3s, height .3s;
}

.marker_title {
  position: absolute;
  font-size: 30px;
  color: #fff;
  line-height: 39px;
  letter-spacing: -0.42px;
  font-family: "IBM Plex Sans SemiBold";
  white-space: nowrap;
  transform: translateX(-50%);
  left: 50%;
  bottom: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

@media only screen and (max-width: 1800px) {
  .marker_title {
    font-size: 24px;
    line-height: 23px;
  }
}

@media only screen and (max-width: 1200px) {
  .marker_title {
    font-size: 16px;
    line-height: 20px;
  }
}

.marker_title svg {
  display: inline-block;
  vertical-align: middle;
  width: 22.67px;
  height: 16px;
  margin-left: 40px;
  fill: #568890;
}

@media only screen and (max-width: 1800px) {
  .marker_title svg {
    width: 14.17px;
    height: 10px;
    margin-left: 20px;
  }
}

.marker_line {
  width: 2px;
  height: 0px;
  background: linear-gradient(180deg, #8BB3B9 0%, rgba(39, 117, 129, 0) 100%);
  margin: auto;
  transition: height .3s;
  margin-top: 35px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1800px) {
  .marker_line {
    margin-top: 25px;
  }
}

.marker.active {
  z-index: 2;
}

.marker.active:before {
  width: 16px;
  height: 16px;
}

@media only screen and (max-width: 1800px) {
  .marker.active:before {
    width: 10px;
    height: 10px;
  }
}

.marker.active a:before {
  width: 172px;
  height: 172px;
}

@media only screen and (max-width: 1800px) {
  .marker.active a:before {
    width: 101px;
    height: 101px;
  }
}

.marker.active a:after {
  width: 80px;
  height: 80px;
}

@media only screen and (max-width: 1800px) {
  .marker.active a:after {
    width: 53px;
    height: 53px;
  }
}

.marker.active .marker_title {
  opacity: 1;
  pointer-events: auto;
}

.marker.active .marker_line {
  height: 100px;
}

@media only screen and (max-width: 1800px) {
  .marker.active .marker_line {
    height: 45px;
  }
}

.logo-img {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .logo-img {
    flex-direction: column-reverse;
  }
}

.logo-img_left {
  width: 699px;
  height: 732px;
  position: relative;
  transition: opacity .5s;
  margin-left: -50px;
}

@media only screen and (max-width: 1800px) {
  .logo-img_left {
    width: 427px;
    height: 446px;
    margin-left: -25px;
  }
}

@media only screen and (max-width: 1200px) {
  .logo-img_left {
    width: 577px;
    height: 604px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 750px) {
  .logo-img_left {
    width: 287px;
    height: 252px;
    margin-left: -30px;
    margin-right: 0;
    position: relative;
  }
}

.logo-img_left.anim .cut-image-in {
  width: 0;
}

.logo-img_right {
  width: 520px;
  padding-top: 269px;
  margin-right: -20px;
}

@media only screen and (max-width: 1800px) {
  .logo-img_right {
    width: 390px;
    margin-right: -60px;
    padding-top: 189px;
  }
}

@media only screen and (max-width: 1200px) {
  .logo-img_right {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 750px) {
  .logo-img_right {
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    padding-top: 90px;
  }
}

.logo-img_ul {
  margin-top: 100px;
}

@media only screen and (max-width: 1800px) {
  .logo-img_ul {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .logo-img_ul {
    margin-top: 30px;
  }
}

.logo-img_li {
  display: flex;
  margin-bottom: 60px;
}

@media only screen and (max-width: 1800px) {
  .logo-img_li {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .logo-img_li {
    flex-direction: column;
  }
}

.logo-img_li-label {
  position: relative;
  font-size: 22px;
  color: #0B363C;
  line-height: 31px;
  margin-right: 25px;
  font-family: "IBM Plex Sans SemiBold";
  width: 125px;
  flex: 0 0 auto;
}

@media only screen and (max-width: 1800px) {
  .logo-img_li-label {
    font-size: 16px;
    line-height: 24px;
    width: 100px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 750px) {
  .logo-img_li-label {
    width: 100%;
    padding-left: 25px;
  }
}

.logo-img_li-label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -40px;
  width: 11px;
  height: 12px;
  transform: translateY(-50%);
  background: #98C1D9;
}

@media only screen and (max-width: 1800px) {
  .logo-img_li-label:before {
    width: 8px;
    height: 8px;
    left: -35px;
  }
}

@media only screen and (max-width: 750px) {
  .logo-img_li-label:before {
    left: 0px;
  }
}

.logo-img_li-text {
  color: #546467;
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (max-width: 1800px) {
  .logo-img_li-text {
    font-size: 14px;
    line-height: 21px;
  }
}

@media only screen and (max-width: 750px) {
  .logo-img_li-text {
    width: 100%;
    padding-left: 25px;
  }
}

.logo-img .scroll-rect {
  position: absolute;
  background: #4E838C;
  opacity: .1;
  width: 579px;
  height: 624px;
  left: 70px;
  top: 80px;
}

@media only screen and (max-width: 1800px) {
  .logo-img .scroll-rect {
    width: 354px;
    height: 380px;
    left: 40px;
    top: 50px;
  }
}

@media only screen and (max-width: 1800px) {
  .logo-img .scroll-rect {
    width: 223px;
    height: 235px;
  }
}

.logo-img .cut-image:after {
  position: absolute;
  top: -50px;
  right: 100px;
  width: 100px;
  height: 100px;
}

@media only screen and (max-width: 750px) {
  .logo-img .cut-image:after {
    top: -30px;
    right: 30px;
    width: 60px;
    height: 60px;
  }
}

.logo-img_text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 80px;
}

@media only screen and (max-width: 1800px) {
  .logo-img_text {
    font-size: 14px;
    line-height: 21px;
    width: 350px;
  }
}

@media only screen and (max-width: 750px) {
  .logo-img_text {
    width: 100%;
    margin-top: 30px;
  }
}

.logo-img_text p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1800px) {
  .logo-img_text p {
    margin-bottom: 21px;
  }
}

.about-top-section {
  background-size: cover;
  background-position: center;
  min-height: auto;
}

@media only screen and (max-width: 1200px) {
  .about-top-section {
    height: 100vh;
    min-height: 100vw;
  }
}

@media only screen and (max-width: 750px) {
  .about-top-section {
    min-height: 680px;
  }
}

.about-top-section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #194248 10%, rgba(25, 67, 73, 0) 76%);
  transition: top 1s ease;
  z-index: 2;
}

.about-top-section:after {
  content: '';
  position: absolute;
  bottom: 50px;
  right: 0;
  width: 80vw;
  height: 37vh;
  background: url(../img/general/background/bgDot.png) left top repeat;
}

.about-info-section {
  height: auto;
  z-index: 100;
  top: -100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-info-section {
    min-height: 520px;
  }
}

@media only screen and (max-width: 750px) {
  .about-info-section {
    top: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .about-info-section .logo-img_left {
    margin-left: -12%;
  }
}

.about-top {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1280px;
  margin: auto;
}

@media only screen and (max-width: 1800px) {
  .about-top {
    width: 890px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-top {
    width: 550px;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 750px) {
  .about-top {
    width: 220px;
    justify-content: center;
  }
}

.about-top_section {
  background-size: cover;
}

.about-top_section:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 41, 53, 0.5);
}

.about-top_content {
  position: absolute;
  right: -130px;
  bottom: -205px;
  width: 620px;
  height: 60vh;
  transition: opacity 1s, transform .5s;
}

@media only screen and (max-width: 1800px) {
  .about-top_content {
    width: 530px;
    right: -65px;
    bottom: -125px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-top_content {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: 90px;
    margin-bottom: -95px;
  }
}

@media only screen and (max-width: 750px) {
  .about-top_content {
    width: 280px;
    margin-left: -30px;
    margin-top: 90px;
    margin-bottom: -175px;
  }
}

.about-top_content.anim {
  opacity: 0;
}

.about-top_scroll-content {
  background: #194349;
  position: absolute;
  top: -450px;
  left: 210px;
  width: 239px;
  height: 782px;
  opacity: .3;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .about-top_scroll-content {
    top: -230px;
    left: 250px;
    width: 195px;
    height: 638px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-top_scroll-content {
    top: -450px;
    left: 460px;
    width: 153px;
    height: 502px;
  }
}

@media only screen and (max-width: 750px) {
  .about-top_scroll-content {
    display: none;
  }
}

.about-top_scroll-content-bottom {
  background: #194349;
  position: absolute;
  top: -110px;
  right: -10px;
  width: 66px;
  height: 156px;
  opacity: .3;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .about-top_scroll-content-bottom {
    top: 40px;
    right: -40px;
    width: 54px;
    height: 127px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-top_scroll-content-bottom {
    display: none;
  }
}

.about-sliders {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1280px;
  margin: auto;
  padding-top: 100px;
  top: -200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-sliders {
    width: 1090px;
    padding-left: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-sliders {
    width: 100%;
    top: -400px;
  }
}

.about-sliders-section {
  background: #194248;
  height: auto;
  top: -2px;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-sliders-section {
    max-height: 1000px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-sliders-section {
    max-height: 1100px;
  }
}

@media only screen and (max-width: 750px) {
  .about-sliders-section {
    max-height: 880px;
  }
}

.about-sliders .about_slide {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1200px) {
  .about-sliders .about_slide {
    flex-direction: column;
    height: auto;
    margin-bottom: 200px;
  }
}

.about-sliders .about_slide .title-h2 {
  position: absolute;
  top: -75px;
}

@media only screen and (max-width: 1200px) {
  .about-sliders .about_slide .title-h2 {
    top: 0;
    left: 15%;
  }
}

.about-sliders .about_slide:not(.slick-active) .cut-image-in {
  width: 0;
}

.about-sliders .about_slide-content {
  width: 60%;
  display: flex;
  padding-top: 190px;
  line-height: 1.4;
  font-family: "IBM Plex Sans Medium";
}

@media only screen and (max-width: 1800px) {
  .about-sliders .about_slide-content {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-sliders .about_slide-content {
    width: 510px;
    margin: auto;
    flex-direction: column;
    margin-bottom: 150px;
  }
}

@media only screen and (max-width: 750px) {
  .about-sliders .about_slide-content {
    width: 90%;
    padding-top: 120px;
    margin: 0;
    margin-left: 25px;
    min-height: 500px;
  }
}

.about-sliders .about_slide-content .about-subtitle {
  width: 20%;
  position: relative;
  color: #fff;
  font-size: 24px;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-sliders .about_slide-content .about-subtitle {
    font-size: 16px;
    width: 21%;
  }
}

@media only screen and (max-width: 1200px) {
  .about-sliders .about_slide-content .about-subtitle {
    font-size: 16px;
    width: 100%;
    margin-bottom: 25px;
  }
}

.about-sliders .about_slide-content .about-subtitle:before {
  content: '';
  position: absolute;
  top: 10px;
  height: 16px;
  width: 16px;
  background: #779EB5;
  left: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-sliders .about_slide-content .about-subtitle:before {
    height: 10px;
    width: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-sliders .about_slide-content .about-subtitle:before {
    height: 10px;
    width: 10px;
  }
}

@media only screen and (max-width: 750px) {
  .about-sliders .about_slide-content .about-subtitle:before {
    height: 8px;
    width: 8px;
    top: 7px;
    left: -25px;
  }
}

.about-sliders .about_slide-content .about-text {
  width: 80%;
  padding: 0 80px;
  color: #fff;
  opacity: .43;
  font-size: 20px;
  font-family: "IBM Plex Sans Regular";
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-sliders .about_slide-content .about-text {
    font-size: 14px;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-sliders .about_slide-content .about-text {
    font-size: 14px;
    width: 100%;
    padding: 0;
  }
}

.about-sliders .about_slide_image {
  width: 744px;
  height: 654px;
  position: relative;
  transition: opacity .5s;
}

@media only screen and (max-width: 1800px) {
  .about-sliders .about_slide_image {
    width: 539px;
    height: 473px;
    margin: auto;
  }
}

@media only screen and (max-width: 750px) {
  .about-sliders .about_slide_image {
    width: 287px;
    height: 252px;
  }
}

@media only screen and (min-width: 1201px) {
  .about-sliders .about_slide_image:hover {
    opacity: .7;
  }
}

.about-sliders .about_slide_image.anim .cut-image-in {
  width: 0;
}

.about-sliders .about_slide .scroll-rect {
  width: 665px;
  height: 508px;
  background: #4E838C;
  opacity: .2;
  position: absolute;
  top: 80px;
  left: 40px;
}

@media only screen and (max-width: 750px) {
  .about-sliders .about_slide .scroll-rect {
    top: 60px;
    left: 0;
    width: 280px;
    height: 220px;
  }
}

.about-sliders .about_slide-js {
  overflow: hidden;
}

.about-slider_one {
  position: relative;
}

@media only screen and (max-width: 750px) {
  .about-slider_one {
    width: 220px;
    margin: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .about-slider_one .show-title {
    width: 510px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 750px) {
  .about-slider_one .show-title {
    width: 100%;
  }
}

.about-slider_one .nav-block {
  width: 200px;
  left: 250px;
  bottom: 0;
  position: absolute;
  margin: 0;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1800px) {
  .about-slider_one .nav-block {
    width: 200px;
    left: 180px;
    bottom: 100px;
    position: absolute;
    margin: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .about-slider_one .nav-block {
    position: absolute;
    top: 450px;
    right: 20%;
    left: unset;
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .about-slider_one .nav-block {
    top: 500px;
    right: 0;
    left: 0;
  }
}

.about-slider_one .slick-list {
  overflow: visible;
}

.about-slider_two {
  height: 300px;
  width: 100%;
  margin-bottom: 150px;
}

@media only screen and (max-width: 750px) {
  .about-slider_two {
    height: 150px;
    width: 100%;
    margin-bottom: 0;
  }
}

.about-slider_two .partner-item {
  height: 300px;
  margin: 0 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-slider_two .partner-item {
    height: 150px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-slider_two .partner-item {
    height: 100px;
    margin: 0 30px;
  }
}

@media only screen and (max-width: 750px) {
  .about-slider_two .partner-item {
    margin: 0 5px;
    height: 60px;
  }
}

.about_quote {
  margin-bottom: 80px;
  position: relative;
  font-size: 22px;
  line-height: 1.4;
  font-family: "IBM Plex Sans SemiBold";
  color: #0B363C;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about_quote {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .about_quote {
    font-size: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .about_quote {
    text-align: center;
    margin-bottom: 50px;
  }
}

.about_quote:before {
  content: url("/static/img/general/background/quote-icon.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  height: 25px;
  width: 25px;
  left: -35px;
  color: #0B363C;
  opacity: .3;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about_quote:before {
    height: 15px;
    width: 15px;
    left: -25px;
  }
}

@media only screen and (max-width: 1200px) {
  .about_quote:before {
    height: 15px;
    width: 15px;
    left: -25px;
  }
}

@media only screen and (max-width: 750px) {
  .about_quote:before {
    height: 20px;
    width: 20px;
    left: 47%;
    top: -30px;
  }
}

.about-medal {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  margin: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-medal {
    width: 870px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal {
    width: 100%;
    flex-direction: column;
  }
}

@media only screen and (max-width: 750px) {
  .about-medal {
    padding-top: 70px;
  }
}

.about-medal_content {
  width: 50%;
  max-width: 500px;
  height: 80%;
}

@media only screen and (max-width: 1800px) {
  .about-medal_content {
    padding-right: 75px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal_content {
    height: auto;
    max-width: unset;
    width: 470px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .about-medal_content {
    width: 220px;
  }
}

.about-medal_content .medal_content-header {
  margin-bottom: 80px;
}

@media only screen and (max-width: 750px) {
  .about-medal_content .medal_content-header {
    margin-bottom: 32px;
  }
}

.about-medal_content .medal_content-text {
  margin-bottom: 80px;
  font-size: 20px;
  line-height: 1.2;
  font-family: "IBM Plex Sans Regular";
  color: #546467;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-medal_content .medal_content-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal_content .medal_content-text {
    font-size: 14px;
  }
}

.about-medal_slider {
  width: 60%;
  max-width: 720px;
  height: 800px;
  background: url("/static/img/general/background/about1.jpg");
  background-position: 65px center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: -100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-medal_slider {
    height: 600px;
    background-position: 50px center;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal_slider {
    height: 600px;
    max-width: unset;
    width: 600px;
    position: relative;
    right: -30px;
  }
}

@media only screen and (max-width: 750px) {
  .about-medal_slider {
    width: 100%;
    right: 0;
    height: 300px;
    background-position: center;
  }
}

.about-medal_slider .nav-block {
  margin-top: 56px;
  padding-left: 200px;
}

@media only screen and (max-width: 750px) {
  .about-medal_slider .nav-block {
    padding-left: 0px;
    margin-top: -20px;
  }
}

.about-medal_slider .nav-block .controls {
  justify-content: center;
}

.about-medal_slider .social-resp_bg {
  position: absolute;
  margin-top: 24%;
  left: -40%;
  width: 90%;
  height: 100%;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
  padding-bottom: 100%;
  opacity: .5;
}

.about-medal .medal_slider {
  height: 100%;
  padding: 40px 0;
}

@media only screen and (max-width: 750px) {
  .about-medal .medal_slider {
    padding: 0;
    position: relative;
    height: 130%;
    top: -15%;
  }
}

.about-medal .medal-item {
  height: 240px;
  display: flex;
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-medal .medal-item {
    height: 180px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal .medal-item {
    height: 180px;
  }
}

@media only screen and (max-width: 750px) {
  .about-medal .medal-item {
    flex-direction: column;
    height: 100%;
  }
}

.about-medal .medal-item .medal-logo {
  width: 130px;
  height: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-medal .medal-item .medal-logo {
    width: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal .medal-item .medal-logo {
    width: 100px;
  }
}

@media only screen and (max-width: 750px) {
  .about-medal .medal-item .medal-logo {
    margin-top: 5px;
    width: 80px;
  }
}

.about-medal .medal-item .medal-subscribe {
  width: 82%;
  padding: 100px;
  padding-right: 120px;
  line-height: 1.4;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-medal .medal-item .medal-subscribe {
    padding: 60px;
    padding-right: 80px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal .medal-item .medal-subscribe {
    padding: 60px;
    padding-right: 80px;
  }
}

@media only screen and (max-width: 750px) {
  .about-medal .medal-item .medal-subscribe {
    padding: 30px;
    padding-left: 60px;
    width: 100%;
  }
}

.about-medal .medal-item .medal-subscribe .medal-header {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 22px;
  color: #fff;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-medal .medal-item .medal-subscribe .medal-header {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal .medal-item .medal-subscribe .medal-header {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal .medal-item .medal-subscribe .medal-header {
    margin-bottom: 22px;
  }
}

.about-medal .medal-item .medal-subscribe .medal-header:before {
  content: '';
  position: absolute;
  top: 10px;
  height: 11px;
  width: 11px;
  background: #779EB5;
  left: -35px;
}

.about-medal .medal-item .medal-subscribe .medal-text {
  font-family: "IBM Plex Sans Medium";
  font-size: 20px;
  color: #779093;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-medal .medal-item .medal-subscribe .medal-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-medal .medal-item .medal-subscribe .medal-text {
    font-size: 14px;
  }
}

.document-list {
  position: relative;
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 1170px;
  margin: auto;
  padding-top: 300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .document-list {
    width: 870px;
    padding-top: 220px;
  }
}

@media only screen and (max-width: 1200px) {
  .document-list {
    width: 540px;
    padding-top: 350px;
  }
}

@media only screen and (max-width: 750px) {
  .document-list {
    width: 100%;
  }
}

.document-list_section {
  min-height: 100vh;
  height: auto;
}

.document-list_header {
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 1200px) {
  .document-list_header {
    top: 15%;
    left: 30px;
  }
}

@media only screen and (max-width: 750px) {
  .document-list_header {
    top: 21%;
    padding-left: 50px;
    left: 30px;
    width: 230px;
  }
}

.doc-list {
  position: relative;
  width: 1060px;
  margin-bottom: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .doc-list {
    width: 680px;
    margin-right: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .doc-list {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .doc-list {
    width: 94%;
    margin: auto;
    margin-bottom: 100px;
  }
}

.doc-list_title {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 30px;
  margin-bottom: 50px;
  color: #0B363C;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .doc-list_title {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .doc-list_title {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .doc-list_title {
    margin-left: 20px;
    font-size: 16px;
  }
}

.doc-list_wrap {
  overflow: hidden;
  height: auto;
  transition: height 1s ease;
}

.doc-list_wrap.close {
  height: 224px;
  transition: height 1s ease;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .doc-list_wrap.close {
    height: 160px;
  }
}

@media only screen and (max-width: 1200px) {
  .doc-list_wrap.close {
    height: 160px;
  }
}

.doc-list_item {
  height: 112px;
  width: 100%;
  background-color: rgba(78, 131, 140, 0.05);
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 88px;
  transition: all .5s ease;
  color: #546467;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .doc-list_item {
    font-size: 16px;
    height: 80px;
    padding-left: 55px;
  }
}

@media only screen and (max-width: 1200px) {
  .doc-list_item {
    font-size: 16px;
    height: 80px;
    padding-left: 55px;
  }
}

.doc-list_item:hover {
  background-color: #EAF1F3;
}

.doc-list_item:hover:after {
  opacity: .3;
}

.doc-list_item:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  background: #779EB5;
  left: 60px;
  top: 45%;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .doc-list_item:before {
    left: 34px;
  }
}

@media only screen and (max-width: 1200px) {
  .doc-list_item:before {
    left: 34px;
  }
}

.doc-list_item:after {
  content: url("/static/img/general/background/arrow-right.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  height: 25px;
  width: 25px;
  top: 36%;
  right: 5%;
  transform: scale(0.7);
  transition: all .5s ease;
  opacity: 0;
}

.doc-list .btn__open-list {
  position: absolute;
  right: 0;
  margin-top: 40px;
  width: 70px;
  height: 70px;
  background: #326B74;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .1s ease;
  transform: rotateX(0deg);
}

.doc-list .btn__open-list:hover {
  background: rgba(50, 107, 116, 0.3);
}

.doc-list .btn__open-list.close {
  transform: rotateX(180deg);
}

@media only screen and (max-width: 1800px) {
  .doc-list .btn__open-list {
    width: 54px;
    height: 54px;
  }
}

@media only screen and (max-width: 1200px) {
  .doc-list .btn__open-list {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1800px) {
  .doc-list .btn__open-list svg {
    width: 25px;
    height: 25px;
  }
}

.client-top_section {
  background-size: cover;
  display: flex;
  align-items: center;
  padding-top: 80px;
}

@media only screen and (max-width: 1800px) {
  .client-top_section {
    padding-top: 0px;
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 1200px) {
  .client-top_section {
    height: 730px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 750px) {
  .client-top_section {
    height: 510px;
    padding-bottom: 120px;
    padding-top: 0;
  }
}

.client-top_section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #153E44 15%, rgba(25, 67, 73, 0) 100%);
  transition: top 1s ease;
  z-index: 2;
}

.client-top_section .home-top_bg {
  height: 50%;
}

.client-top {
  z-index: 2;
}

@media only screen and (max-width: 1200px) {
  .client-top {
    width: 550px;
  }
}

@media only screen and (max-width: 750px) {
  .client-top {
    width: 230px;
  }
}

.client-terms_section {
  background: #153E44;
  padding: 1px;
  z-index: 3;
  height: auto;
  padding-bottom: 110px;
  min-height: auto;
}

@media only screen and (max-width: 1800px) {
  .client-terms_section {
    padding-bottom: 100px;
  }
}

.client-terms {
  display: flex;
  justify-content: space-between;
  margin-top: -110px;
}

@media only screen and (max-width: 1800px) {
  .client-terms {
    margin-top: -165px;
  }
}

@media only screen and (max-width: 1200px) {
  .client-terms {
    flex-direction: column;
  }
}

.client-terms_bg {
  position: absolute;
  top: 0;
  left: 35px;
  width: 100%;
  height: 100%;
  display: block;
  align-items: center;
  pointer-events: none;
}

.client-terms_bg svg {
  opacity: 0.05;
  fill: #FEFEFE;
}

@media only screen and (max-width: 750px) {
  .client-terms_bg {
    left: 0;
  }
}

.client-terms_left {
  width: 795px;
  padding-left: 28px;
}

@media only screen and (max-width: 1800px) {
  .client-terms_left {
    width: 625px;
  }
}

@media only screen and (max-width: 1200px) {
  .client-terms_left {
    width: 565px;
    margin-left: -15px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .client-terms_left {
    width: 220px;
    margin-left: 0;
  }
  .client-terms_left .list_title:before {
    display: none;
  }
}

.client-terms_list {
  margin-top: 130px;
  color: #fff;
}

@media only screen and (max-width: 1800px) {
  .client-terms_list {
    margin-top: 70px;
  }
}

.client-terms_list .list_text {
  width: 100%;
}

.client-terms_list .list_li {
  width: 50%;
  padding-right: 60px;
}

@media only screen and (max-width: 750px) {
  .client-terms_list .list_li {
    width: 100%;
    padding: 0;
  }
}

.client-terms_scroll {
  position: absolute;
  width: 517px;
  height: 495px;
  opacity: 0.1;
  background: #4E838C;
  left: -40px;
  top: 40px;
}

@media only screen and (max-width: 1800px) {
  .client-terms_scroll {
    width: 341px;
    height: 326px;
  }
}

@media only screen and (max-width: 1800px) {
  .client-terms_scroll {
    width: 225px;
    height: 214px;
  }
}

.client-terms_right {
  width: 639px;
  height: 654px;
  position: relative;
  transition: opacity .5s;
  margin-right: -180px;
  z-index: 2;
}

@media only screen and (max-width: 1800px) {
  .client-terms_right {
    width: 539px;
    height: 473px;
    margin-right: -110px;
  }
}

@media only screen and (max-width: 1200px) {
  .client-terms_right {
    margin-bottom: -210px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .client-terms_right {
    width: 287px;
    height: 252px;
    margin-right: 0;
    margin-left: -30px;
    margin-bottom: -130px;
  }
}

.client-terms_right.anim .cut-image-in {
  width: 0;
}

.client-dbl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 1240px;
  padding-right: 80px;
}

@media only screen and (max-width: 1800px) {
  .client-dbl {
    padding-right: 0;
    padding-left: 70px;
    width: 870px;
  }
}

@media only screen and (max-width: 1200px) {
  .client-dbl {
    flex-direction: column;
    padding-left: 0;
    width: 520px;
  }
}

@media only screen and (max-width: 750px) {
  .client-dbl {
    width: 250px;
  }
}

.client-dbl .title-h2 {
  font-size: 48px;
  letter-spacing: -0.67px;
  line-height: 62.4px;
}

@media only screen and (max-width: 1800px) {
  .client-dbl .title-h2 {
    font-size: 36px;
    letter-spacing: -0.5px;
    line-height: 46.8px;
  }
}

@media only screen and (max-width: 750px) {
  .client-dbl .title-h2 {
    font-size: 26px;
    letter-spacing: -0.36px;
    line-height: 33.8px;
  }
}

.client-dbl_text {
  width: 469px;
  line-height: 30px;
  font-size: 20px;
  margin-top: 55px;
  margin-bottom: 65px;
}

@media only screen and (max-width: 1800px) {
  .client-dbl_text {
    font-size: 14px;
    line-height: 21px;
    width: 340px;
    margin-top: 40px;
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 750px) {
  .client-dbl_text {
    width: 240px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.client-dbl_left {
  position: relative;
}

.client-dbl_left:before {
  content: '';
  position: absolute;
  left: -300px;
  top: 80px;
  width: 217px;
  height: 209px;
  opacity: 0.2;
}

@media only screen and (max-width: 1800px) {
  .client-dbl_left:before {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 750px) {
  .client-dbl_left:before {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .client-dbl_left {
    margin-top: 280px;
    align-self: flex-start;
  }
}

@media only screen and (max-width: 750px) {
  .client-dbl_left {
    margin-top: 160px;
  }
}

.client-dbl_right {
  margin-bottom: -290px;
  position: relative;
}

.client-dbl_right:before {
  content: '';
  position: absolute;
  right: -220px;
  bottom: 20px;
  width: 217px;
  height: 209px;
  opacity: 0.2;
}

@media only screen and (max-width: 1800px) {
  .client-dbl_right:before {
    width: 150px;
    height: 150px;
    right: -120px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 750px) {
  .client-dbl_right:before {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .client-dbl_right {
    margin-top: 180px;
    margin-bottom: 0px;
    align-self: flex-end;
  }
}

@media only screen and (max-width: 750px) {
  .client-dbl_right {
    margin-top: 95px;
    align-self: flex-start;
  }
}

.client-dbl .scroll-rect {
  width: 493px;
  height: 545px;
  background: #EDF2F3;
  position: absolute;
  top: -77px;
  left: 63px;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .client-dbl .scroll-rect {
    width: 340px;
    height: 390px;
    top: -57px;
    left: 23px;
  }
}

@media only screen and (max-width: 750px) {
  .client-dbl .scroll-rect {
    width: 250px;
    height: 330px;
    top: -57px;
    left: 23px;
  }
}

.client-dbl_scroll-right {
  background: #EAF3F7 !important;
}

@media only screen and (max-width: 1200px) {
  .client-why {
    width: 510px;
    margin-bottom: 160px;
  }
}

@media only screen and (max-width: 750px) {
  .client-why {
    width: 240px;
    margin-bottom: 70px;
  }
}

.contact-top_section {
  background-size: cover;
  background-position: center;
}

.contact-top_section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #194248 15%, rgba(25, 67, 73, 0) 100%);
  transition: top 1s ease;
  z-index: 2;
}

@media only screen and (max-width: 1200px) {
  .contact-top_section {
    height: 660px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-top_section {
    height: 400px;
  }
}

.contact-top {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1350px;
  margin: auto;
  padding-bottom: 300px;
}

@media only screen and (max-width: 1800px) {
  .contact-top {
    width: 890px;
    padding-bottom: 200px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-top {
    width: 550px;
    padding-bottom: 270px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-top {
    width: 220px;
    padding-bottom: 170px;
  }
}

.contact-top_scroll-title {
  background: #194349;
  position: absolute;
  top: 211px;
  left: -30px;
  width: 160px;
  height: 523px;
  opacity: .2;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .contact-top_scroll-title {
    width: 160px;
    height: 423px;
    left: -65px;
    top: 141px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-top_scroll-title {
    width: 57px;
    height: 159px;
    left: -15px;
    top: 81px;
  }
}

.contact-top_scroll-min {
  background: #194349;
  position: absolute;
  left: 710px;
  width: 66px;
  height: 156px;
  opacity: .25;
  z-index: -1;
  top: 511px;
}

@media only screen and (max-width: 1800px) {
  .contact-top_scroll-min {
    width: 66px;
    height: 156px;
    left: 410px;
    top: 431px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-top_scroll-min {
    left: 240px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-top_scroll-min {
    width: 27px;
    height: 57px;
    left: 140px;
    top: 201px;
  }
}

.contact-top_scroll-min2 {
  background: #194349;
  position: absolute;
  top: 271px;
  left: 949px;
  width: 82px;
  height: 191px;
  opacity: .2;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .contact-top_scroll-min2 {
    width: 82px;
    height: 191px;
    left: 669px;
    top: 181px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-top_scroll-min2 {
    left: 429px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-top_scroll-min2 {
    width: 35px;
    height: 83px;
    left: 209px;
    top: 101px;
  }
}

.contact-map_section {
  background: #194248;
  height: auto;
}

.contact-map {
  padding-top: 250px;
  padding-bottom: 230px;
}

@media only screen and (max-width: 1800px) {
  .contact-map {
    padding-top: 410px;
    padding-bottom: 160px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-map {
    padding-top: 960px;
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map {
    padding-top: 780px;
  }
}

.contact-map_content-wrap {
  position: relative;
  width: 1300px;
  margin: auto;
  z-index: 2;
}

@media only screen and (max-width: 1800px) {
  .contact-map_content-wrap {
    width: 880px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-map_content-wrap {
    width: 520px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_content-wrap {
    width: 277px;
  }
}

.contact-map_content {
  position: absolute;
  top: -420px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 1800px) {
  .contact-map_content {
    top: -214px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-map_content {
    flex-direction: column;
  }
}

.contact-map_form {
  background: #13393E;
  width: 650px;
  height: 760px;
  padding: 133px 200px 100px 140px;
  position: relative;
  color: #fff;
}

@media only screen and (max-width: 1800px) {
  .contact-map_form {
    width: 514px;
    height: 676px;
    padding: 109px 166px 100px 104px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-map_form {
    width: 514px;
    height: 676px;
    padding: 109px 146px 100px 124px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_form {
    width: 277px;
    height: 495px;
    padding: 65px 25px 20px 32px;
  }
}

.contact-map_form_right {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 750px) {
  .contact-map_form_right {
    justify-content: center;
  }
}

.contact-map_form .form-group {
  width: 100%;
  position: relative;
  margin-bottom: 80px;
}

@media only screen and (max-width: 1800px) {
  .contact-map_form .form-group {
    margin-bottom: 97px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_form .form-group {
    margin-bottom: 65px;
  }
}

.contact-map_form .form-group.tel {
  width: 242px;
}

@media only screen and (max-width: 1800px) {
  .contact-map_form .form-group.tel {
    width: 180px;
  }
}

.contact-map_form .help-block {
  position: absolute;
  top: 100%;
  left: 0;
  color: #ED8080;
  font-size: 14px;
  font-family: "IBM Plex Sans Medium";
  opacity: 0;
  transition: opacity .5s;
  background: rgba(255, 14, 14, 0.2);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 19px;
  margin-top: 8px;
}

@media only screen and (max-width: 1800px) {
  .contact-map_form .help-block {
    font-size: 13px;
    height: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_form .help-block {
    font-size: 11px;
    margin-top: 4px;
    height: 30px;
  }
}

.contact-map_form .has-error .help-block {
  opacity: 1;
}

.contact-map_form .form-control {
  width: 100%;
  border-bottom: 3px rgba(78, 131, 140, 0.2) solid;
  background: none;
  color: #fff;
  font-size: 20px;
  font-family: "IBM Plex Sans Medium";
  height: 50px;
}

.contact-map_form .form-control::placeholder {
  color: #4E838C;
}

@media only screen and (max-width: 1800px) {
  .contact-map_form .form-control {
    font-size: 16px;
    height: 35px;
    border-bottom: 3px rgba(78, 131, 140, 0.2) solid;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_form .form-control {
    font-size: 14px;
  }
}

.contact-map_form .form-control:focus {
  border-bottom: 3px #4E838C solid;
}

.contact-map_form .success-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 115px 130px 130px 130px;
  background: #124851;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
}

.contact-map_form .success-form.active {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1800px) {
  .contact-map_form .success-form {
    padding: 75px 95px 95px 95px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_form .success-form {
    padding: 56px 30px 70px 30px;
  }
}

.contact-map_form .btn, .contact-map_form .btn_white, .contact-map_form .btn_prev, .contact-map_form .btn_next {
  margin-top: 30px;
  margin-right: -40px;
}

@media only screen and (max-width: 1800px) {
  .contact-map_form .btn, .contact-map_form .btn_white, .contact-map_form .btn_prev, .contact-map_form .btn_next {
    margin-top: 0;
    margin-right: -54px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_form .btn, .contact-map_form .btn_white, .contact-map_form .btn_prev, .contact-map_form .btn_next {
    margin: auto;
  }
}

.contact-map_left {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 90px;
}

@media only screen and (max-width: 1200px) {
  .contact-map_left {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_left {
    margin-bottom: 20px;
    width: 220px;
  }
}

.contact-map_item {
  width: 100%;
  font-size: 20px;
  font-family: "IBM Plex Sans Medium";
  line-height: 40px;
  margin-bottom: 57px;
}

.contact-map_item:nth-child(1) {
  width: 50%;
}

@media only screen and (max-width: 1800px) {
  .contact-map_item:nth-child(1) {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-map_item:nth-child(1) {
    width: 50%;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_item:nth-child(1) {
    width: 100%;
  }
}

.contact-map_item:nth-child(2) {
  width: 50%;
}

@media only screen and (max-width: 1800px) {
  .contact-map_item:nth-child(2) {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-map_item:nth-child(2) {
    width: 50%;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_item:nth-child(2) {
    width: 100%;
  }
}

@media only screen and (max-width: 1800px) {
  .contact-map_item {
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-map_item {
    width: 50%;
  }
}

@media only screen and (max-width: 750px) {
  .contact-map_item {
    width: 100%;
    margin-bottom: 30px;
  }
}

.contact-map_label {
  color: #4E838C;
}

.contact-map_text {
  color: #fff;
}

.contact-map_text a {
  color: #fff;
}

.contact-map_text span {
  color: #4E838C;
}

#contact-map {
  width: 1468px;
  height: 765px;
  margin: auto;
}

@media only screen and (max-width: 1800px) {
  #contact-map {
    width: 1078px;
    height: 562px;
  }
}

@media only screen and (max-width: 1200px) {
  #contact-map {
    width: 651px;
  }
}

@media only screen and (max-width: 750px) {
  #contact-map {
    width: 100%;
    height: 485px;
  }
}

.social-resp_section {
  height: auto;
  margin-bottom: 250px;
}

@media only screen and (max-width: 1800px) {
  .social-resp_section {
    margin-bottom: 125px;
  }
}

@media only screen and (max-width: 1200px) {
  .social-resp_section {
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 750px) {
  .social-resp_section {
    margin-bottom: 140px;
  }
}

.social-resp {
  position: relative;
  height: auto;
  display: flex;
  justify-content: space-between;
  width: 1170px;
  margin: auto;
}

@media only screen and (max-width: 1800px) {
  .social-resp {
    width: 810px;
  }
}

@media only screen and (max-width: 1200px) {
  .social-resp {
    flex-direction: column;
    width: 470px;
  }
}

@media only screen and (max-width: 750px) {
  .social-resp {
    width: 230px;
  }
}

.social-resp_content {
  width: 490px;
  margin-top: 310px;
}

@media only screen and (max-width: 1800px) {
  .social-resp_content {
    width: 350px;
    margin-top: 160px;
  }
}

@media only screen and (max-width: 1200px) {
  .social-resp_content {
    margin-top: 0;
  }
}

@media only screen and (max-width: 750px) {
  .social-resp_content {
    width: 100%;
  }
}

.social-resp_subheader {
  color: #0B363C;
  font-family: "IBM Plex Sans SemiBold";
  font-size: 24px;
  line-height: 36px;
  margin-top: 80px;
  margin-bottom: 65px;
}

@media only screen and (max-width: 1800px) {
  .social-resp_subheader {
    font-size: 16px;
    line-height: 25px;
    margin-top: 60px;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 750px) {
  .social-resp_subheader {
    margin-top: 40px;
    margin-bottom: 15px;
  }
}

.social-resp_text {
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (max-width: 1800px) {
  .social-resp_text {
    font-size: 14px;
    line-height: 21px;
  }
}

.social-resp_image {
  margin-right: -300px;
}

@media only screen and (max-width: 1800px) {
  .social-resp_image {
    margin-right: -120px;
  }
}

@media only screen and (max-width: 1200px) {
  .social-resp_image {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 750px) {
  .social-resp_image {
    margin-top: 60px;
    margin-right: 0;
  }
}

.social-resp_bg {
  position: absolute;
  top: 0;
  left: 35px;
  width: 100%;
  height: 100%;
  display: block;
  align-items: center;
  pointer-events: none;
}

.social-resp_bg svg {
  opacity: 0.2;
  fill: #C7CEDB;
}

@media only screen and (max-width: 750px) {
  .social-resp_bg {
    left: 0;
  }
}

.social-resp_img {
  width: 699px;
  height: 732px;
  position: relative;
  transition: opacity .5s;
}

@media only screen and (max-width: 1800px) {
  .social-resp_img {
    width: 427px;
    height: 446px;
    margin-left: -25px;
  }
}

@media only screen and (max-width: 1200px) {
  .social-resp_img {
    width: 477px;
    height: 534px;
    margin-left: -40px;
  }
}

@media only screen and (max-width: 750px) {
  .social-resp_img {
    width: 287px;
    height: 252px;
    margin-left: -30px;
    margin-right: 0;
    position: relative;
  }
}

.social-resp .about_quote {
  width: 340px;
  font-size: 22px;
  line-height: 33px;
  margin-top: 180px;
}

@media only screen and (max-width: 1800px) {
  .social-resp .about_quote {
    font-size: 16px;
    line-height: 24px;
    margin-top: 120px;
  }
}

@media only screen and (max-width: 750px) {
  .social-resp .about_quote {
    display: none;
  }
}

.project-top_section {
  background-size: cover;
  background-position: center;
  min-height: auto;
}

@media only screen and (max-width: 1200px) {
  .project-top_section {
    height: 100vh;
    min-height: 100vw;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_section {
    min-height: 680px;
  }
}

.project-top {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1280px;
  margin: auto;
}

@media only screen and (max-width: 1800px) {
  .project-top {
    width: 890px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top {
    width: 550px;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 750px) {
  .project-top {
    width: 220px;
  }
}

.project-top_section {
  background-size: cover;
}

.project-top_section:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 41, 53, 0.5);
}

.project-top_text {
  color: #fff;
  font-family: "IBM Plex Sans SemiBold";
  font-size: 36px;
  line-height: 54px;
  margin-top: 90px;
  transition: opacity .5s, transform .5s;
}

@media only screen and (max-width: 1800px) {
  .project-top_text {
    font-size: 30px;
    line-height: 42px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_text {
    font-size: 14px;
    line-height: 22px;
    margin-top: 30px;
  }
}

.project-top_text.anim {
  opacity: 0;
  transform: translateY(10px);
}

.project-top_content {
  position: absolute;
  right: -130px;
  bottom: -205px;
  width: 620px;
  transition: opacity 1s, transform .5s;
}

@media only screen and (max-width: 1800px) {
  .project-top_content {
    width: 530px;
    right: -65px;
    bottom: -125px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top_content {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: 90px;
    margin-bottom: -95px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_content {
    width: 280px;
    margin-left: -30px;
    margin-top: 90px;
    margin-bottom: -175px;
  }
}

.project-top_content.anim {
  opacity: 0;
}

.project-top_content-top {
  display: flex;
  background: rgba(2, 40, 45, 0.6);
  margin-bottom: 6px;
}

@media only screen and (max-width: 750px) {
  .project-top_content-top {
    margin-bottom: 4px;
  }
}

.project-top_right {
  width: 100%;
  display: flex;
  padding-left: 72px;
  align-items: center;
  font-size: 20px;
  line-height: 40px;
  font-family: "IBM Plex Sans Medium";
}

@media only screen and (max-width: 1800px) {
  .project-top_right {
    font-size: 16px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_right {
    font-size: 16px;
    line-height: 32px;
    padding-left: 0;
    justify-content: center;
  }
}

.project-top_right-label {
  color: #fff;
}

.project-top_right-link {
  color: #78B4BD;
  text-decoration: underline;
}

.project-top_logo {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  background-color: #FFFFFF;
  flex: 0 0 auto;
}

@media only screen and (max-width: 1800px) {
  .project-top_logo {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_logo {
    width: 93px;
    height: 93px;
  }
}

.project-top_content-bottom {
  background: #164046;
  padding: 65px 45px 85px 75px;
}

@media only screen and (max-width: 1800px) {
  .project-top_content-bottom {
    padding: 30px 35px 55px 55px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_content-bottom {
    padding: 25px 30px 40px 30px;
  }
}

.project-top_content-bottom .socials {
  justify-content: flex-end;
}

@media only screen and (max-width: 750px) {
  .project-top_content-bottom .socials {
    justify-content: center;
  }
}

.project-top_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1800px) {
  .project-top_flex {
    margin-bottom: 0;
  }
}

.project-top_item {
  font-family: "IBM Plex Sans Medium";
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 50px;
  width: 48%;
}

@media only screen and (max-width: 1800px) {
  .project-top_item {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top_item {
    margin-left: 0;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_item {
    width: 100%;
  }
}

.project-top_label {
  margin-bottom: 10px;
  color: #fff;
}

.project-top_info {
  color: #4E838C;
}

.project-top_scroll-title {
  background: #194349;
  position: absolute;
  top: 260px;
  left: 130px;
  width: 82px;
  height: 191px;
  opacity: .2;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .project-top_scroll-title {
    top: 190px;
    left: -50px;
    width: 67px;
    height: 156px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top_scroll-title {
    top: 270px;
    left: -20px;
    width: 52px;
    height: 206px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_scroll-title {
    display: none;
  }
}

.project-top_scroll-text {
  background: #194349;
  position: absolute;
  top: 180px;
  left: 310px;
  width: 95px;
  height: 373px;
  opacity: .3;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .project-top_scroll-text {
    top: 100px;
    left: 120px;
    width: 78px;
    height: 305px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top_scroll-text {
    display: none;
  }
}

.project-top_scroll-content {
  background: #194349;
  position: absolute;
  top: -450px;
  left: 210px;
  width: 239px;
  height: 782px;
  opacity: .3;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .project-top_scroll-content {
    top: -230px;
    left: 250px;
    width: 195px;
    height: 638px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top_scroll-content {
    top: -450px;
    left: 460px;
    width: 153px;
    height: 502px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_scroll-content {
    display: none;
  }
}

.project-top_scroll-content-bottom {
  background: #194349;
  position: absolute;
  top: -110px;
  right: -10px;
  width: 66px;
  height: 156px;
  opacity: .3;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .project-top_scroll-content-bottom {
    top: 40px;
    right: -40px;
    width: 54px;
    height: 127px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top_scroll-content-bottom {
    display: none;
  }
}

.project-top_gradient:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #194248 10%, rgba(25, 67, 73, 0) 76%);
  transition: top 1s ease;
  z-index: 2;
}

@media only screen and (max-width: 1200px) {
  .project-top_gradient {
    height: 750px;
    min-height: auto;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_gradient {
    height: 550px;
    min-height: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top_gradient .project-top {
    justify-content: center;
  }
}

.project-top_gradient .project-top_content {
  bottom: 50%;
}

.project-top_text-line {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  width: 483px;
  position: relative;
  font-family: "IBM Plex Sans Medium";
  margin-top: 140px;
  margin-left: 40px;
  transition: opacity .5s, transform .5s;
}

@media only screen and (max-width: 1800px) {
  .project-top_text-line {
    font-size: 17px;
    line-height: 25.5px;
    width: 321px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-top_text-line {
    width: 289px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_text-line {
    width: 223px;
    font-size: 14px;
    line-height: 22.4px;
    margin-left: 0;
    padding-left: 20px;
    margin-top: 90px;
  }
}

.project-top_text-line:before {
  content: '';
  position: absolute;
  top: 50%;
  height: 100%;
  min-height: 120px;
  transform: translateY(-50%);
  width: 8px;
  background: rgba(255, 255, 255, 0.2);
  left: -35px;
}

@media only screen and (max-width: 1800px) {
  .project-top_text-line:before {
    min-height: 98px;
    left: -30px;
  }
}

@media only screen and (max-width: 750px) {
  .project-top_text-line:before {
    min-height: 70px;
    width: 5px;
    left: 0;
  }
}

.project-top_text-line.anim {
  opacity: 0;
  transform: translateY(10px);
}

@media only screen and (max-width: 750px) {
  .project-adv_section {
    overflow: hidden;
  }
}

@media only screen and (max-width: 1200px) {
  .project-adv {
    padding-top: 210px;
  }
}

@media only screen and (max-width: 750px) {
  .project-adv {
    padding-top: 260px;
  }
}

.project-adv_section {
  display: flex;
  align-items: center;
}

.project-adv_text {
  margin-top: 110px;
  font-size: 20px;
  line-height: 30px;
  opacity: .43;
  width: 360px;
}

@media only screen and (max-width: 1800px) {
  .project-adv_text {
    font-size: 14px;
    line-height: 22.4px;
    margin-top: 65px;
    width: 230px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-adv_text {
    margin-top: 25px;
    width: 345px;
  }
}

@media only screen and (max-width: 750px) {
  .project-adv_text {
    margin-top: 22px;
    width: 100%;
  }
}

.project-adv_list {
  padding-left: 38%;
  margin-top: 60px;
  counter-reset: item;
  margin-right: -262px;
}

@media only screen and (max-width: 1800px) {
  .project-adv_list {
    padding-left: 25%;
    margin-right: -185px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-adv_list {
    padding-left: 0;
    margin-right: 0;
    margin-top: 90px;
    margin-bottom: 40px;
    width: 120%;
  }
}

@media only screen and (max-width: 750px) {
  .project-adv_list {
    padding-left: 25px;
    margin-top: 30px;
  }
}

.project-adv_list .list {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .project-adv_list .list {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .project-adv_list .list {
    padding-left: 0;
  }
}

.project-adv .list_li {
  margin-right: 100px;
  width: 24%;
}

@media only screen and (max-width: 1800px) {
  .project-adv .list_li {
    margin-right: 30px;
    margin-bottom: 45px;
    width: 28%;
  }
}

@media only screen and (max-width: 1200px) {
  .project-adv .list_li {
    width: 40%;
    margin-right: 50px;
  }
}

@media only screen and (max-width: 750px) {
  .project-adv .list_li {
    width: 100%;
  }
}

.project-adv .list_li .list_text {
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .project-adv .list_li .list_text {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 750px) {
  .project-adv .list_li .list_text {
    font-size: 14px;
    line-height: 22px;
  }
}

.project-adv .list_li .list_text:before {
  content: counter(item, decimal-leading-zero) " ";
  counter-increment: item;
  position: absolute;
  left: -40px;
  top: 0;
  color: #4E838C;
  font-family: "IBM Plex Sans SemiBold";
}

@media only screen and (max-width: 750px) {
  .project-adv .list_li .list_text:before {
    left: -25px;
  }
}

.project-adv .list_text {
  font-family: "IBM Plex Sans SemiBold";
  color: #fff;
  opacity: 1;
  width: auto;
}

.project-adv_cut-image {
  position: absolute;
  bottom: 10%;
  right: 0;
  width: 1087px;
  height: 751px;
}

@media only screen and (max-width: 1800px) {
  .project-adv_cut-image {
    width: 744px;
    height: 500px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-adv_cut-image {
    bottom: 20%;
  }
}

@media only screen and (max-width: 750px) {
  .project-adv_cut-image {
    width: 398px;
    height: 267px;
    right: -140px;
    top: 20%;
  }
}

.project-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 1480px;
}

@media only screen and (max-width: 1800px) {
  .project-info {
    width: 980px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-info {
    flex-direction: column-reverse;
    width: 100%;
    padding-top: 130px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info {
    padding-top: 65px;
    width: 220px;
  }
}

.project-info_left {
  width: 699px;
  height: 674px;
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .project-info_left {
    width: 430px;
    height: 415px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-info_left {
    width: 500px;
    height: 482px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_left {
    width: 280px;
    height: 270px;
  }
}

.project-info_left:after {
  content: '';
  width: 100px;
  height: 100px;
  position: absolute;
  top: 80px;
  left: 60px;
}

@media only screen and (max-width: 750px) {
  .project-info_left:after {
    width: 50px;
    height: 60px;
    top: 40px;
    left: 20px;
  }
}

.project-info_left .scroll-rect {
  width: 579px;
  height: 491px;
  background: #4E838C;
  opacity: .1;
  position: absolute;
  left: 70px;
  top: 40px;
  z-index: -1;
}

@media only screen and (max-width: 1800px) {
  .project-info_left .scroll-rect {
    width: 356px;
    height: 302px;
    left: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_left .scroll-rect {
    width: 232px;
    height: 196px;
    left: 30px;
  }
}

.project-info_right {
  width: 45%;
  position: relative;
  left: 0;
}

@media only screen and (max-width: 1200px) {
  .project-info_right {
    width: 470px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_right {
    margin-bottom: 55px;
    width: 100%;
  }
}

.project-info_list {
  display: flex;
  justify-content: space-between;
  margin-right: -100px;
  margin-top: 108px;
  width: 730px;
}

@media only screen and (max-width: 1800px) {
  .project-info_list {
    margin-right: -30px;
    width: 460px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-info_list {
    margin-right: -10px;
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_list {
    margin: auto;
    width: 100%;
    flex-direction: column;
    padding-left: 25px;
  }
}

.project-info_list-left {
  width: 50%;
}

@media only screen and (max-width: 1800px) {
  .project-info_list-left {
    width: 60%;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_list-left {
    margin-top: 45px;
    width: 100%;
  }
}

.project-info_list-right {
  width: 60%;
}

@media only screen and (max-width: 1800px) {
  .project-info_list-right {
    width: 65%;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_list-right {
    margin-top: 45px;
    width: 100%;
  }
}

.project-info_r {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.project-info_r svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #C7CEDB;
  opacity: .15;
  width: 749px;
  height: 808px;
}

@media only screen and (max-width: 1800px) {
  .project-info_r svg {
    width: 387px;
    height: 538px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-info_r svg {
    width: 466px;
    height: 502px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_r svg {
    width: 302px;
    height: 326px;
  }
}

.project-info_list-item {
  display: flex;
  align-items: baseline;
}

.project-info_list-item:first-child .project-info_list-label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -40px;
  width: 11px;
  height: 12px;
  transform: translateY(-50%);
  background: #98C1D9;
}

@media only screen and (max-width: 1800px) {
  .project-info_list-item:first-child .project-info_list-label:before {
    width: 8px;
    height: 8px;
    left: -35px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_list-item:first-child .project-info_list-label:before {
    left: -25px;
  }
}

.project-info_list-label {
  font-family: "IBM Plex Sans Medium";
  font-size: 20px;
  color: #546467;
  line-height: 60px;
  width: 200px;
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .project-info_list-label {
    font-size: 14px;
    line-height: 42px;
    width: 150px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_list-label {
    width: 40%;
  }
}

.project-info_list-text {
  font-family: "IBM Plex Sans Bold";
  font-size: 20px;
  color: #0B363C;
  line-height: 30px;
  width: 250px;
}

@media only screen and (max-width: 1800px) {
  .project-info_list-text {
    font-size: 14px;
    line-height: 42px;
    width: 200px;
  }
}

@media only screen and (max-width: 750px) {
  .project-info_list-text {
    width: 65%;
  }
}

.project-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 140px;
  height: 100%;
}

@media only screen and (max-width: 1800px) {
  .project-bottom {
    padding-left: 70px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-bottom {
    padding-left: 0;
    flex-direction: column;
    padding-bottom: 130px;
    padding-top: 130px;
  }
}

@media only screen and (max-width: 750px) {
  .project-bottom {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

.project-bottom .title-h3 {
  width: 500px;
  margin-left: 35px;
}

@media only screen and (max-width: 1800px) {
  .project-bottom .title-h3 {
    width: 340px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-bottom .title-h3 {
    width: 100%;
    margin-left: 35px;
  }
}

@media only screen and (max-width: 750px) {
  .project-bottom .title-h3 {
    margin-left: 0px;
  }
}

.project-bottom_text {
  font-size: 20px;
  line-height: 30px;
  color: #546467;
  width: 554px;
  margin-top: 60px;
}

@media only screen and (max-width: 1800px) {
  .project-bottom_text {
    font-size: 14px;
    line-height: 22px;
    width: 340px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-bottom_text {
    width: 100%;
    margin-bottom: 110px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 750px) {
  .project-bottom_text {
    margin-top: 20px;
    margin-bottom: 60px;
  }
}

.project-bottom_text p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1800px) {
  .project-bottom_text p {
    margin-bottom: 23px;
  }
}

.project-bottom_logo {
  width: 490px;
  height: 433px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  background-color: #F5F5F5;
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
  position: relative;
}

@media only screen and (max-width: 1800px) {
  .project-bottom_logo {
    width: 352px;
    height: 311px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-bottom_logo {
    width: 208px;
    height: 183px;
  }
}

@media only screen and (max-width: 750px) {
  .project-bottom_logo {
    width: 220px;
    height: 194px;
  }
}

.project-bottom_logo:after {
  content: '';
  width: 217px;
  height: 209px;
  position: absolute;
  top: -50px;
  right: -90px;
  opacity: 0.2;
}

@media only screen and (max-width: 1800px) {
  .project-bottom_logo:after {
    right: -130px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-bottom_logo:after {
    right: -380px;
    top: -280px;
    width: 77px;
    height: 150px;
  }
}

@media only screen and (max-width: 750px) {
  .project-bottom_logo:after {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .project-bottom_link {
    margin-right: -25px;
    width: 55%;
  }
}

@media only screen and (max-width: 750px) {
  .project-bottom_link {
    width: unset;
  }
}

.project-bottom_link-prev {
  color: #0B363C;
  font-size: 22px;
  line-height: 33px;
  font-family: "IBM Plex Sans SemiBold";
  margin-top: 67px;
  margin-bottom: 31px;
}

@media only screen and (max-width: 1800px) {
  .project-bottom_link-prev {
    font-size: 16px;
    line-height: 23px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-bottom_link-prev {
    margin-top: 0;
  }
}

@media only screen and (max-width: 750px) {
  .project-bottom_link-prev {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-bottom_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .project-bottom_right {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.project-item_section {
  height: auto;
  overflow: hidden;
  background: #194248;
}

.project-item {
  display: flex;
  justify-content: space-between;
  width: 1673px;
  margin: auto;
  margin-bottom: 200px;
  margin-top: 350px;
  padding-left: 128px;
}

@media only screen and (max-width: 1800px) {
  .project-item {
    width: 1200px;
    padding-left: 97px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-item {
    width: 540px;
    padding-left: 0;
    flex-direction: column !important;
    margin-bottom: 130px;
    margin-top: 170px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item {
    width: 100%;
    align-items: center;
    margin-bottom: 110px;
    margin-top: 120px;
  }
}

.project-item:first-child {
  margin-top: 200px;
}

@media only screen and (max-width: 1800px) {
  .project-item:first-child {
    margin-top: 91px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-item:first-child {
    margin-top: 0px;
  }
}

.project-item:nth-child(even) {
  flex-direction: row-reverse;
}

.project-item .project-top {
  width: 1400px;
}

@media only screen and (max-width: 1800px) {
  .project-item .project-top {
    width: 1034px;
  }
}

.project-item_image {
  width: 744px;
  height: 654px;
  position: relative;
  transition: opacity .5s;
}

@media only screen and (max-width: 1800px) {
  .project-item_image {
    width: 539px;
    height: 473px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item_image {
    width: 287px;
    height: 252px;
  }
}

@media only screen and (min-width: 1201px) {
  .project-item_image:hover {
    opacity: .7;
  }
}

.project-item_image.anim .cut-image-in {
  width: 0;
}

.project-item .scroll-rect {
  width: 665px;
  height: 508px;
  background: #4E838C;
  opacity: .2;
  position: absolute;
  top: 80px;
  left: 40px;
}

@media only screen and (max-width: 1800px) {
  .project-item .scroll-rect {
    width: 481px;
    height: 367px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item .scroll-rect {
    width: 246px;
    height: 196px;
    left: 30px;
  }
}

.project-item_content {
  padding-top: 30px;
  width: 689px;
}

@media only screen and (max-width: 1800px) {
  .project-item_content {
    width: 528px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .project-item_content {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item_content {
    width: 251px;
  }
}

.project-item_sub-title {
  color: #4E838C;
  font-size: 24px;
  letter-spacing: -0.33px;
  text-transform: uppercase;
  line-height: 45px;
  margin-top: 12px;
}

@media only screen and (max-width: 1800px) {
  .project-item_sub-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item_sub-title {
    font-size: 14px;
    line-height: 35px;
    margin-top: 0px;
  }
}

.project-item .title-h2 {
  font-size: 48px;
  line-height: 70px;
  margin-left: 65px;
}

@media only screen and (max-width: 1800px) {
  .project-item .title-h2 {
    font-size: 42px;
    margin-left: 55px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item .title-h2 {
    font-size: 26px;
    line-height: 36px;
    margin-left: 14px;
  }
}

.project-item .title-h2:before {
  height: 100%;
  min-height: 130px;
}

@media only screen and (max-width: 750px) {
  .project-item .title-h2:before {
    min-height: 104px;
  }
}

.project-item .title-h2 br {
  display: none;
}

.project-item .btn, .project-item .btn_white, .project-item .btn_prev, .project-item .btn_next {
  margin-left: 60px;
}

@media only screen and (max-width: 1800px) {
  .project-item .btn, .project-item .btn_white, .project-item .btn_prev, .project-item .btn_next {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-item .btn, .project-item .btn_white, .project-item .btn_prev, .project-item .btn_next {
    margin-left: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item .btn, .project-item .btn_white, .project-item .btn_prev, .project-item .btn_next {
    margin: auto;
  }
}

.project-item_flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  margin-top: 90px;
  transition: opacity 0.5s;
}

@media only screen and (max-width: 1800px) {
  .project-item_flex {
    margin-bottom: 50px;
    margin-top: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item_flex {
    flex-direction: column;
    align-items: center;
  }
}

.project-item_flex.anim {
  opacity: 0;
}

.project-item_row {
  background: #133B41;
  width: 340px;
  height: 278px;
  font-size: 20px;
  line-height: 30px;
  font-family: "IBM Plex Sans Medium";
  padding: 54px 66px;
}

@media only screen and (max-width: 1800px) {
  .project-item_row {
    width: 251px;
    height: 194px;
    font-size: 14px;
    line-height: 21px;
    padding: 34px 39px;
  }
}

@media only screen and (max-width: 750px) {
  .project-item_row:nth-child(2) {
    margin-top: 21px;
  }
}

.project-item_label {
  color: #fff;
  margin-bottom: 30px;
  font-size: 16px;
}

@media only screen and (max-width: 1800px) {
  .project-item_label {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 21px;
  }
}

.project-item_text {
  color: #4E838C;
}
