@font-face {
  font-family: 'Roboto Mono Thin';
  src: url('../fonts/RobotoMono-Thin.eot');
  src: url('../fonts/RobotoMono-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-Thin.woff') format('woff'),
  url('../fonts/RobotoMono-Thin.ttf') format('truetype');
  //font-weight: 100;
  //font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src: url('../fonts/IBMPlexSans-Bold.eot');
  src: url('../fonts/IBMPlexSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Bold.woff') format('woff'),
  url('../fonts/IBMPlexSans-Bold.ttf') format('truetype');
  //font-weight: bold;
  //font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans Thin';
  src: url('../fonts/IBMPlexSans-Thin.eot');
  src: url('../fonts/IBMPlexSans-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Thin.woff') format('woff'),
  url('../fonts/IBMPlexSans-Thin.ttf') format('truetype');
  //font-weight: 100;
  //font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono ThinItalic';
  src: url('../fonts/RobotoMono-ThinItalic.eot');
  src: url('../fonts/RobotoMono-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-ThinItalic.woff') format('woff'),
  url('../fonts/RobotoMono-ThinItalic.ttf') format('truetype');
  //font-weight: 100;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans Medium';
  src: url('../fonts/IBMPlexSans-Medium.eot');
  src: url('../fonts/IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Medium.woff') format('woff'),
  url('../fonts/IBMPlexSans-Medium.ttf') format('truetype');
  //font-weight: 500;
  //font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono MediumItalic';
  src: url('../fonts/RobotoMono-MediumItalic.eot');
  src: url('../fonts/RobotoMono-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-MediumItalic.woff') format('woff'),
  url('../fonts/RobotoMono-MediumItalic.ttf') format('truetype');
  //font-weight: 500;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans Italic';
  src: url('../fonts/IBMPlexSans-Italic.eot');
  src: url('../fonts/IBMPlexSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Italic.woff') format('woff'),
  url('../fonts/IBMPlexSans-Italic.ttf') format('truetype');
  //font-weight: normal;
  //font-style: italic;
}

@font-face {
  font-family: 'Roboto Mono LightItalic';
  src: url('../fonts/RobotoMono-LightItalic.eot');
  src: url('../fonts/RobotoMono-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-LightItalic.woff') format('woff'),
  url('../fonts/RobotoMono-LightItalic.ttf') format('truetype');
  //font-weight: 300;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans ExtraLightItalic';
  src: url('../fonts/IBMPlexSans-ExtraLightItalic.eot');
  src: url('../fonts/IBMPlexSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-ExtraLightItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-ExtraLightItalic.ttf') format('truetype');
  //font-weight: 200;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans SemiBoldItalic';
  src: url('../fonts/IBMPlexSans-SemiBoldItalic.eot');
  src: url('../fonts/IBMPlexSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-SemiBoldItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
  //font-weight: 600;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans SemiBold';
  src: url('../fonts/IBMPlexSans-SemiBold.eot');
  src: url('../fonts/IBMPlexSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-SemiBold.woff') format('woff'),
  url('../fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
  //font-weight: 600;
  //font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono Medium';
  src: url('../fonts/RobotoMono-Medium.eot');
  src: url('../fonts/RobotoMono-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-Medium.woff') format('woff'),
  url('../fonts/RobotoMono-Medium.ttf') format('truetype');
  //font-weight: 500;
  //font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono Italic';
  src: url('../fonts/RobotoMono-Italic.eot');
  src: url('../fonts/RobotoMono-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-Italic.woff') format('woff'),
  url('../fonts/RobotoMono-Italic.ttf') format('truetype');
  //font-weight: normal;
  //font-style: italic;
}

@font-face {
  font-family: 'Roboto Mono Light';
  src: url('../fonts/RobotoMono-Light.eot');
  src: url('../fonts/RobotoMono-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-Light.woff') format('woff'),
  url('../fonts/RobotoMono-Light.ttf') format('truetype');
  //font-weight: 300;
  //font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono Regular';
  src: url('../fonts/RobotoMono-Regular.eot');
  src: url('../fonts/RobotoMono-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-Regular.woff') format('woff'),
  url('../fonts/RobotoMono-Regular.ttf') format('truetype');
  //font-weight: normal;
  //font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono BoldItalic';
  src: url('../fonts/RobotoMono-BoldItalic.eot');
  src: url('../fonts/RobotoMono-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-BoldItalic.woff') format('woff'),
  url('../fonts/RobotoMono-BoldItalic.ttf') format('truetype');
  //font-weight: bold;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans ThinItalic';
  src: url('../fonts/IBMPlexSans-ThinItalic.eot');
  src: url('../fonts/IBMPlexSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-ThinItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-ThinItalic.ttf') format('truetype');
  //font-weight: 100;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans BoldItalic';
  src: url('../fonts/IBMPlexSans-BoldItalic.eot');
  src: url('../fonts/IBMPlexSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-BoldItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-BoldItalic.ttf') format('truetype');
  //font-weight: bold;
  //font-style: italic;
}

@font-face {
  font-family: 'Roboto Mono Bold';
  src: url('../fonts/RobotoMono-Bold.eot');
  src: url('../fonts/RobotoMono-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoMono-Bold.woff') format('woff'),
  url('../fonts/RobotoMono-Bold.ttf') format('truetype');
  //font-weight: bold;
  //font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans MediumItalic';
  src: url('../fonts/IBMPlexSans-MediumItalic.eot');
  src: url('../fonts/IBMPlexSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-MediumItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-MediumItalic.ttf') format('truetype');
  //font-weight: 500;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans ExtraLight';
  src: url('../fonts/IBMPlexSans-ExtraLight.eot');
  src: url('../fonts/IBMPlexSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-ExtraLight.woff') format('woff'),
  url('../fonts/IBMPlexSans-ExtraLight.ttf') format('truetype');
  //font-weight: 200;
  //font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans Regular';
  src: url('../fonts/IBMPlexSans.eot');
  src: url('../fonts/IBMPlexSans.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans.woff') format('woff'),
  url('../fonts/IBMPlexSans.ttf') format('truetype');
  //font-weight: normal;
  //font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans LightItalic';
  src: url('../fonts/IBMPlexSans-LightItalic.eot');
  src: url('../fonts/IBMPlexSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-LightItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-LightItalic.ttf') format('truetype');
  //font-weight: 300;
  //font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans Light';
  src: url('../fonts/IBMPlexSans-Light.eot');
  src: url('../fonts/IBMPlexSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Light.woff') format('woff'),
  url('../fonts/IBMPlexSans-Light.ttf') format('truetype');
  //font-weight: 300;
  //font-style: normal;
}

$FontIBMPlexSansBold: 'IBM Plex Sans Bold';

$FontIBMPlexSansThin: 'IBM Plex Sans Thin';

$FontIBMPlexSansMedium: 'IBM Plex Sans Medium';

$FontIBMPlexSansItalic: 'IBM Plex Sans Italic';

$FontIBMPlexSansExtraLightItalic: 'IBM Plex Sans ExtraLightItalic';

$FontIBMPlexSansSemiBoldItalic: 'IBM Plex Sans SemiBoldItalic';

$FontIBMPlexSansSemiBold: 'IBM Plex Sans SemiBold';

$FontIBMPlexSansThinItalic: 'IBM Plex Sans ThinItalic';

$FontIBMPlexSansBoldItalic: 'IBM Plex Sans BoldItalic';

$FontIBMPlexSansMediumItalic: 'IBM Plex Sans MediumItalic';

$FontIBMPlexSansExtraLight: 'IBM Plex Sans ExtraLight';

$FontIBMPlexSansRegular: 'IBM Plex Sans Regular';

$FontIBMPlexSansLightItalic: 'IBM Plex Sans LightItalic';

$FontIBMPlexSansLight: 'IBM Plex Sans Light';

//$FontRobotoMonoThin: 'Roboto Mono Thin';
//
//$FontRobotoMonoThinItalic: 'Roboto Mono ThinItalic';
//
//$FontRobotoMonoMediumItalic: 'Roboto Mono MediumItalic';
//
//$FontRobotoMonoLightItalic: 'Roboto Mono LightItalic';
//
//$FontRobotoMonoMedium: 'Roboto Mono Medium';
//
//$FontRobotoMonoItalic: 'Roboto Mono Italic';
//
//$FontRobotoMonoLight: 'Roboto Mono Light';
//
//$FontRobotoMonoRegular: 'Roboto Mono Regular';
//
//$FontRobotoMonoBoldItalic: 'Roboto Mono BoldItalic';
//
//$FontRobotoMonoBold: 'Roboto Mono Bold';
