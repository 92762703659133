.section {
  height: 100vh;
  position: relative;
  min-height: 720px;
  @include respond-to(t) {
    min-height: auto;
    height: auto;
  }
}

.home-project {
  //position: fixed; // ярослава попросила сделать статичным
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;

  pointer-events: none;
  @include respond-to(desc) {
    &:not(.active) {
      .cut-image-in {
        width: 0;
      }
    }
  }
  @include respond-to(t) {
    pointer-events: auto;
    justify-content: center;
    position: relative;
    padding-left: 270px;
  }
  @include respond-to(m) {
    padding-left: 0;
    padding-bottom: 160px;
  }

  .cut-image {
    transition: opacity .5s;
  }
  &_wrap {
    position: relative;
    height: 725px;
    width: 740px;
    transition: all .5s;
    @include respond-to(d) {
      height: 470px;
      width: 450px;
    }
    @include respond-to(m) {
      height: 290px;
      width: 221px;
    }
    @include hover {
      .cut-image {
        opacity: .7;
      }
      .home-project {
        &_text {
          opacity: .7;

        }
      }
    }
  }
  &_content {
    position: absolute;
    bottom: 0;
    right: 100%;
    text-align: right;
    color: rgba(255, 255, 255, 0);
    transition: color .5s, padding .5s;
    padding-right: 65px;
    //pointer-events: none;
    @include respond-to(d) {
      padding-right: 50px;
    }
    @include respond-to(t) {
      color: #fff;
    }
    @include respond-to(m) {
      right: 0;
      top: 100%;
    }
  }
  &_number {
    font-family: $FontIBMPlexSansBold;
    font-size: 23px;
    position: relative;
    line-height: 30px;
    margin-bottom: 20px;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 20px;
    }
    @include respond-to(m) {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(100% + 30px);
      height: 3px;
      width: 0;
      margin-top: -2px;
      background: #fff;
      transition: width .5s;
      @include respond-to(d) {

        left: calc(100% + 20px);
      }
      @include respond-to(t) {
        width: 35px;
        left: calc(100% + 25px);
      }
      @include respond-to(m) {
        left: calc(100% + 20px);
        width: 25px;

      }
    }
  }
  &_text {
    font-size: 20px;
    opacity: .23;
    font-family: $FontIBMPlexSansSemiBold;
    line-height: 25px;
    white-space: nowrap;
    transition: opacity .5s;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 22.4px;
    }
    @include respond-to(m) {
      margin-top: 0;
      white-space: unset;
    }
  }
  &_bg {
    position: absolute;
    top: 0;
    left: -95px;
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
    pointer-events: none;
    svg {
      opacity: 0.05;
      fill: #FEFEFE;
    }
    @include respond-to(m) {
      left: 0;
    }
  }
  .scroll-rect-r {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.active {
    z-index: 6;
    pointer-events: auto;
    .home-project {
      &_content {
        color: #fff;
        transition: color .5s .3s, padding .5s;
      }
      &_number {
        &:after {
          width: 65px;
          transition: width .5s .3s;
        }
        @include respond-to(d) {
          &:after {
            width: 55px;
          }
        }

      }
    }
  }
}

.project-2 {
  .home-top_scroll-project {
    width: 276px;
    height: 1001px;
    opacity: .6;
    background: #082F35;
    left: 320px;
    top: -110px;
    @include respond-to(d) {
      width: 186px;
      height: 599px;
      left: 210px;
    }
    @include respond-to(m) {
      width: 86px;
      height: 277px;
      left: 130px;
      top: -20px;
    }
  }
}

.dark {
  background: #0B363C;
  color: #fff;
}

.home-top {
  width: 1530px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  @include respond-to(d) {
    width: 1020px;
  }
  @include respond-to(t) {
    width: 560px;
    height: 550px;
    padding-top: 160px;
  }
  @include respond-to(m) {
    padding: 47px;
    width: 100%;
    height: 360px;
    padding-bottom: 0;
    padding-top: 70px;
    max-width: 400px;
    z-index: 8;
  }
  &_bg {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    &:after {
      @extend %bg-dot;
      width: 100%;
      height: 296px;
      z-index: -1;
      bottom: 50%;
      opacity: 0.4;
      margin-bottom: -360px;
      pointer-events: none;
    }
  }
  .title-h1 {
    @include respond-to(m) {
      br {
        display: none;
      }
    }
  }
  &_text {
    font-size: 20px;
    font-family: $FontIBMPlexSansMedium;
    line-height: 30px;
    width: 360px;
    margin-top: 110px;
    opacity: 1;
    transition: opacity .5s, transform .5s;
    @include respond-to(d) {
      font-size: 17px;
      line-height: 25.5px;
      width: 270px;
      margin-top: 70px;
    }
    @include respond-to(m) {
      width: 100%;
      font-size: 14px;
      line-height: 22.4px;
      margin-top: 20px;
    }
    &.anim {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  &_scroll-title {
    position: absolute;
    background: #194349;
    opacity: 0.1;
    width: 82px;
    height: 191px;
    left: 240px;
    top: 250px;
    z-index: -1;
    @include respond-to(d) {
      width: 59px;
      height: 138px;
      left: 155px;
      top: 170px;
    }

    @include respond-to(t) {
      width: 48px;
      height: 112px;
      left: -30px;
      top: 170px;
    }
    @include respond-to(m) {
      width: 28px;
      height: 70px;
      left: 33px;
      top: 120px;
    }
  }
  &_scroll-text {
    position: absolute;
    background: #194349;
    opacity: 0.1;
    width: 66px;
    height: 156px;
    left: 550px;
    top: 430px;
    z-index: -1;
    @include respond-to(d) {
      width: 48px;
      height: 113px;
      left: 390px;
      top: 290px;
    }
    @include respond-to(t) {
      width: 39px;
      height: 91px;
      left: 140px;
      top: 480px;
    }
    @include respond-to(m) {
      width: 22px;
      height: 57px;
      left: 100px;
      top: 250px;
    }
  }
  &_scroll-project {
    position: absolute;
    background: #194349;
    opacity: 0.2;
    width: 239px;
    height: 782px;
    left: 120px;
    top: -10px;
    z-index: -1;
    @include respond-to(d) {
      width: 172px;
      height: 566px;
      left: 81px;
    }
    @include respond-to(t) {
      width: 139px;
      height: 458px;
      left: 261px;
      top: -220px;

    }
    @include respond-to(m) {
      width: 105px;
      height: 196px;
      right: -20px;
      top: -120px;
      left: auto;
    }
  }
  &_scroll-content {
    position: absolute;
    background: #194349;
    opacity: 0.3;
    width: 95px;
    height: 373px;
    right: 190px;
    top: 470px;
    z-index: -1;
    @include respond-to(d) {
      width: 64px;
      height: 274px;
      right: 90px;
      top: 340px;
    }
    @include respond-to(t) {
      width: 45px;
      height: 222px;
      right: -20px;
      top: 50px;
    }
    @include respond-to(t) {
      width: 0px;
      height: 0px;
      right: 0px;
      top: 0px;
    }

  }
  &_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0B363C;
    background-size: cover;
    background-position: center;
    transition: top 1s ease;
    z-index: 1;
  }
}

.home-top-section {
  &.anim {
    background-position: center -100px;
    &:before, .home-top_image {
      top: -100px
    }

  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #0B363C 10%, rgba(25, 67, 73, 0) 76%);
    transition: top 1s ease;
    z-index: 2;
  }
}

.home-dev {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &_content {
    opacity: 1;
    transition: opacity .5s;
    &.anim {
      opacity: 0;
    }
    @include respond-to(t) {
      padding-top: 100px;
      padding-bottom: 160px;
    }
    @include respond-to(m) {
      padding-top: 0px;
      padding-bottom: 108px;
    }
  }
  &_text {
    font-size: 20px;
    font-family: $FontIBMPlexSansRegular;
    line-height: 30px;
    width: 510px;
    margin-top: 130px;
    margin-bottom: 80px;
    opacity: .43;
    transition: opacity .5s;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 22.4px;
      width: 360px;
      margin-top: 105px;
      margin-bottom: 55px;
    }
    @include respond-to(m) {
      width: 100%;
      margin-top: 35px;
      margin-bottom: 45px;
    }

  }
}

.home-dev-section {
  background: #0B363C url("../img/general/background/home-dev.png");
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  @include respond-to(t) {
    background-position: center bottom;
  }

  &:after {
    @extend %bg-dot;
    width: 321px;
    height: 303px;
    top: 100px;
    opacity: 1;
    right: 347px;
    z-index: 12;
    pointer-events: none;
  }

}

.home-why {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  opacity: 1;
  transition: opacity .5s;
  &.anim {
    opacity: 0;
  }
  @include respond-to(t) {
    padding-top: 170px;
    padding-bottom: 90px;
  }
  @include respond-to(m) {
    padding-top: 0;
    padding-bottom: 30px;
  }
  &_list {
    margin-top: 130px;
    width: 750px;
    position: relative;
    @include respond-to(d) {
      margin-top: 100px;
      width: 535px;
    }
    @include respond-to(t) {
      margin-top: 70px;
    }
    @include respond-to(m) {
      width: 100%;
      margin-top: 50px;
    }
  }

  .list {
    @include respond-to(m) {
      padding-left: 20px;
    }
    &_title {
      @include respond-to(m) {
        &:before {
          left: -20px;
        }
      }
      
    }
  }

  &_scroll-1 {
    position: absolute;
    top: -300px;
    left: 0;
    width: 99px;
    height: 360px;
    background: #000000;
    opacity: 0.05;
    @include respond-to(d) {
      width: 76px;
      height: 274px;
      top: -220px;
      left: 0;
    }
    @include respond-to(t) {
      width: 76px;
      height: 274px;
      top: -220px;
      left: 0;
    }
    @include respond-to(m) {
      display: none;
    }
  }
  &_scroll-2 {
    position: absolute;
    top: 180px;
    left: 240px;
    width: 77px;
    height: 185px;
    background: #000000;
    opacity: 0.05;

    @include respond-to(d) {
      width: 59px;
      height: 141px;
      top: 110px;
      left: 160px;
    }
    @include respond-to(t) {
      width: 59px;
      height: 141px;
      top: 110px;
      left: 160px;
    }
    @include respond-to(m) {
      display: none;
    }
  }
  &_scroll-3 {
    position: absolute;
    top: -160px;
    left: 500px;
    width: 77px;
    height: 249px;
    background: #000000;
    opacity: 0.05;

    @include respond-to(d) {
      width: 59px;
      height: 190px;
      top: -140px;
      left: 370px;
    }
    @include respond-to(t) {
      width: 59px;
      height: 190px;
      top: -140px;
      left: 370px;
    }
    @include respond-to(m) {
      display: none;
    }
  }
}

%link {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 120px;
  align-items: center;
}

.home-map {
  position: relative;
  pointer-events: none;
  &_bottom {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 100px;
    @include respond-to(d) {
      bottom: 45px;
    }
    @include respond-to(t) {
      bottom: 55px;
    }
    @include respond-to(m) {
      bottom: 35px;
    }
    .home-map_link {
      display: none;
      @include respond-to(m) {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 35px;
      }
    }
    .wrapper {
      @include respond-to(t) {
        width: 550px;
      }
      @include respond-to(m) {
        width: 100%;
      }
    }
  }
  &_link {
    pointer-events: auto;
    @extend %link;
    @include respond-to(t) {
      right: -50px;
      height: 100px;

    }
    @include respond-to(m) {
      display: none;
      right: auto;
    }
  }
  &_slider {
    position: relative;
    width: 1420px;
    @include respond-to(d) {
      width: 920px;
    }
    @include respond-to(t) {
      width: 100%;
    }
  }
  &_slider-js {

  }
  &_slide {
    width: 275px;
    height: 200px;
    background: #000;
    background-size: cover;
    position: relative;
    margin: 0 4px;
    cursor: pointer;
    @include respond-to(d) {
      width: 178px;
      height: 130px;
      margin: 0 3px;
    }
    @include respond-to(m) {
      width: 109px;
      height: 80px;
      margin: 0 3px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(1, 36, 41, 0.75);
      opacity: 1;
      transition: opacity .5s;
    }
    &.slick-center {
      &:after {
        opacity: 0;
      }
    }
    @include hover {
      &:after {
        opacity: 0;
      }
    }
  }
  &_controls {
    @include respond-to(m) {
      display: none !important;
    }
    .btn_prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -50px;
    }
    .btn_next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -50px;
    }
  }
}

.home-why-section {
  @include respond-to(t) {
    padding-bottom: 150px;
  }
  @include respond-to(m) {
    padding-bottom: 0;
  }

  &:after {
    @extend %bg-dot;
    width: 321px;
    height: 303px;
    bottom: 0px;
    opacity: 1;
    right: 347px;
    z-index: 12;
    pointer-events: none;
  }
}

.home-map-section {
  padding-top: 130px;
  @include respond-to(d) {
    padding-top: 70px;
  }
  @include respond-to(t) {
    padding-top: 90px;
    min-height: 978px;
  }
  @include respond-to(m) {
    min-height: 768px;
  }
}

#home-map {
  background: #092124;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(90% 120%, rgba(9, 34, 38, 0.00) 00%, #092124 100%);
  }

}


