// Common styles of the project

html, body {
  height: 100%;
  font-family: $FontIBMPlexSansRegular;
  font-size: 20px;
  color: #546467;
  @include respond-to(m) {
    // overflow-x: hidden;
  }
}

body {
  &.hidden {
    overflow: hidden;
  }
}

.content {
  min-height: 50%;
}

.wrapper {
  width: 1340px;
  margin: auto;
  @include respond-to(d) {
    width: 885px;
  }
  @include respond-to(t) {
    width: 470px;
  }
  @include respond-to(m) {
    width: 220px;
  }
}

.wrapper-top {
  height: 100%;
  display: flex;
  align-items: center;
  width: 1286px;

  margin: auto;
  @include respond-to(d) {
    width: 912px;
  }
  @include respond-to(t) {
    width: 540px;
  }
  @include respond-to(m) {
    width: 240px;
  }
}

.p404_section {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  @include respond-to(d) {
    padding-top: 0;
    padding-bottom: 100px;
  }
  @include respond-to(t) {
    height: 670px !important;
    padding-top: 100px;
    padding-bottom: 0;
  }
  @include respond-to(m) {
    padding-top: 10px;
    padding-bottom: 0;
    height: 460px !important
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #194248 15%, rgba(25, 67, 73, 0) 100%);
    transition: top 1s ease;
    z-index: 2;

  }
}

.p404 {
  color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  .btn {
    width: 320px;
    @include respond-to(d) {
      width: 230px;
    }
  }
}

.p404-status {
  font-size: 300px;
  font-family: $FontIBMPlexSansSemiBold;
  @include respond-to(d) {
    font-size: 200px;
  }
  @include respond-to(m) {
    font-size: 140px;

  }
}

.p404-text {
  font-size: 22px;
  font-family: $FontIBMPlexSansSemiBold;
  margin-top: 30px;
  margin-bottom: 150px;
  @include respond-to(d) {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 80px;
  }
  @include respond-to(m) {
    margin-top: 0px;
    margin-bottom: 60px;
  }
}