.content-about {
  // overflow-x: hidden;
}
.about-top-section {
  background-size: cover;
  background-position: center;
  min-height: auto;
  @include respond-to(t) {
    height: 100vh;
    min-height: 100vw;
  }
  @include respond-to(m) {
    min-height: 680px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #194248 10%, rgba(25, 67, 73, 0) 76%);
    transition: top 1s ease;
    z-index: 2;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 80vw;
    height: 37vh;
    background: url(../img/general/background/bgDot.png) left top repeat;
  }
}

.about-info-section {
  height: auto;
  z-index: 100;
  top: -100px;
  @include respond-to(dm) {
    min-height: 520px;
  }
  @include respond-to(m) {
    top: 0;
  }
  .logo-img_left {
    @include respond-to(t) {
      margin-left: -12%;
    }
  }
}
.about-top {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1280px;
  margin: auto;
  @include respond-to(d) {
    width: 890px;
  }
  @include respond-to(t) {
    width: 550px;
    justify-content: flex-end;
  }
  @include respond-to(m) {
    width: 220px;
    justify-content: center;
  }
  &_section {
    background-size: cover;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(16, 41, 53, 0.5);
    }
  }
  &_content {
    position: absolute;
    right: -130px;
    bottom: -205px;
    width: 620px;
    height: 60vh;
    transition: opacity 1s, transform .5s;
    @include respond-to(d) {
      width: 530px;
      right: -65px;
      bottom: -125px;
    }
    @include respond-to(t) {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin-top: 90px;
      margin-bottom: -95px;
    }
    @include respond-to(m) {
      width: 280px;
      margin-left: -30px;
      margin-top: 90px;
      margin-bottom: -175px;
    }
    &.anim {
      opacity: 0;
    }
  }
  &_scroll-content {
    background: #194349;
    position: absolute;
    top: -450px;
    left: 210px;
    width: 239px;
    height: 782px;
    opacity: .3;
    z-index: -1;
    @include respond-to(d) {
      top: -230px;
      left: 250px;
      width: 195px;
      height: 638px;
    }

    @include respond-to(t) {
      top: -450px;
      left: 460px;
      width: 153px;
      height: 502px;
    }
    @include respond-to(m) {
      display: none;
    }
  }
  &_scroll-content-bottom {
    background: #194349;
    position: absolute;
    top: -110px;
    right: -10px;
    width: 66px;
    height: 156px;
    opacity: .3;
    z-index: -1;
    @include respond-to(d) {
      top: 40px;
      right: -40px;
      width: 54px;
      height: 127px;
    }
    @include respond-to(t) {
      display: none;
    }
  }
}

.about-sliders {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1280px;
  margin: auto;
  // overflow: hidden;
  padding-top: 100px;
  top: -200px;
  @include respond-to(dm) {
    width: 1090px;
    padding-left: 100px;
  }
  @include respond-to(t) {
    width: 100%;
    top: -400px;
  }
  &-section {
    background: #194248;
    height: auto;
    top: -2px;
    @include respond-to(dm) {
      max-height: 1000px;
    }
    @include respond-to(t) {
      max-height: 1100px;
    }
    @include respond-to(m) {
      max-height: 880px;
    }
  }
  .about_slide {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    margin-bottom: 100px;
    @include respond-to(t) {
      flex-direction: column;
      height: auto;
      margin-bottom: 200px;
    }
    
    .title-h2 {
      position: absolute;
      top: -75px;
      @include respond-to(t) {
        top: 0;
        left: 15%;
      }
    }
    &:not(.slick-active) {
      .cut-image-in {
        width: 0;
      }
    }
    &-content {
      width: 60%;
      display: flex;
      padding-top: 190px;
      line-height: 1.4;
      font-family: $FontIBMPlexSansMedium;
      @include respond-to(d) {
        padding-top: 150px;
      }
      @include respond-to(t) {
        width: 510px;
        margin: auto;
        flex-direction: column;
        margin-bottom: 150px;
      }
      @include respond-to(m) {
        width: 90%;
        padding-top: 120px;
        margin: 0;
        margin-left: 25px;
        min-height: 500px;
      }
      .about-subtitle {
        width: 20%;
        position: relative;
        color: #fff;
        font-size: 24px;
        @include respond-to(dm) {
          font-size: 16px;
          width: 21%;
        }
        @include respond-to(t) {
          font-size: 16px;
          width: 100%;
          margin-bottom: 25px;
        }
        &:before {
          content: '';
          position: absolute;
          top: 10px;
          height: 16px;
          width: 16px;
          background: #779EB5;
          left: -35px;
          @include respond-to(dm) {
            height: 10px;
            width: 10px;
          }
          @include respond-to(t) {
            height: 10px;
            width: 10px;
          }
          @include respond-to(m) {
            height: 8px;
            width: 8px;
            top: 7px;
            left: -25px;
          }
        }
      }
      .about-text {
        width: 80%;
        padding: 0 80px;
        color: #fff;
        opacity: .43;
        font-size: 20px;
        font-family: $FontIBMPlexSansRegular;
        @include respond-to(dm) {
          font-size: 14px;
          padding: 0 40px;
        }
        @include respond-to(t) {
          font-size: 14px;
          width: 100%;
          padding: 0;
        }
      }
    }
    &_image {
      width: 744px;
      height: 654px;
      position: relative;
      transition: opacity .5s;
      @include respond-to(d) {
        width: 539px;
        height: 473px;
        margin: auto;
      }
      @include respond-to(m) {
        width: 287px;
        height: 252px;
      }
      @include hover {
        opacity: .7;
      }
      &.anim {
        .cut-image-in {
          width: 0;
        }
      }
    }
    .scroll-rect {
      width: 665px;
      height: 508px;
      background: #4E838C;
      opacity: .2;
      position: absolute;
      top: 80px;
      left: 40px;
      @include respond-to(m) {
        top: 60px;
        left: 0;
        width: 280px;
        height: 220px;
      }
    }
    
  }
  .about_slide-js {
    overflow: hidden;
  }
}
.about-slider_one { 
  position: relative;
  
  @include respond-to(m) {
    width: 220px;
    margin: auto;
  }
  .show-title {
    @include respond-to(t) {
      width: 510px;
      margin: 0 auto; 
    }
    @include respond-to(m) {
      width: 100%;
    }
    
  }
  .nav-block {
    width: 200px;
    left: 250px;
    bottom: 0;
    position: absolute; 
    margin: 0;
    margin-bottom: 100px;
    @include respond-to(d) {
      width: 200px;
      left: 180px;
      bottom: 100px;
      position: absolute;
      margin: 0;
    }
    @include respond-to(t) {
      position: absolute;
      top: 450px;
      right: 20%;
      left: unset;
      margin: 0;
    }
    @include respond-to(m) {
      top: 500px;
      right: 0;
      left: 0;
    }
  }
  .slick-list {
    overflow: visible;
  }
}
.about-slider_two {
  height: 300px;
  width: 100%;
  margin-bottom: 150px;
  @include respond-to(m) {
    height: 150px;
    width: 100%;
    margin-bottom: 0;
  }
  .partner-item {
    height: 300px;
    margin: 0 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include respond-to(dm) {
      height: 150px;
      margin-bottom: 50px;
    }
    @include respond-to(t) {
      height: 100px;
      margin: 0 30px;
    }
    @include respond-to(m) {
      margin: 0 5px;
      height: 60px;
    }
  }
}
.about_quote {
  margin-bottom: 80px;
  position: relative;
  font-size: 22px;
  line-height: 1.4;
  font-family: $FontIBMPlexSansSemiBold;
  color: #0B363C;
  @include respond-to(dm) {
    font-size: 16px;
  }
  @include respond-to(t) {
    font-size: 16px;
  }
  @include respond-to(m) {
    text-align: center;
    margin-bottom: 50px;
  }
  &:before {
    content: url('/static/img/general/background/quote-icon.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 0;
    height: 25px;
    width: 25px;
    left: -35px;
    color: #0B363C;
    opacity: .3;
    @include respond-to(dm) {
      height: 15px;
      width: 15px;
      left: -25px;
    }
    @include respond-to(t) {
      height: 15px;
      width: 15px;
      left: -25px;
    }
    @include respond-to(m) {
      height: 20px;
      width: 20px;
      left: 47%;
      top: -30px;
    }
  }
}
.about-medal {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  margin: auto;
  @include respond-to(dm) {
    width: 870px;
  }
  @include respond-to(t) {
    width: 100%;
    flex-direction: column;
  }
  @include respond-to(m) {
    padding-top: 70px;
  }
  &_content {
    width: 50%;
    max-width: 500px;
    height: 80%;
    @include respond-to(d) {
      padding-right: 75px;
    }
    @include respond-to(t) {
      height: auto;
      max-width: unset;
      width: 470px;
      padding-right: 0;
    }
    @include respond-to(m) {
      width: 220px;
    }
    .medal_content-header {
      margin-bottom: 80px;
      @include respond-to(m) {
        margin-bottom: 32px;
      }
    }
    .medal_content-text {
      margin-bottom: 80px;
      font-size: 20px;
      line-height: 1.2;
      font-family: $FontIBMPlexSansRegular;
      color: #546467;
      @include respond-to(dm) {
        font-size: 14px;
      }
      @include respond-to(t) {
        font-size: 14px;
      }
    }

  }
  &_slider {
    width: 60%;
    max-width: 720px;
    height: 800px;
    background: url('/static/img/general/background/about1.jpg');
    background-position: 65px center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: -100px;
    @include respond-to(dm) {
      height: 600px;
      background-position: 50px center;
    }
    @include respond-to(t) {
      height: 600px;
      max-width: unset;
      width: 600px;
      position: relative;
      right: -30px;
    }
    @include respond-to(m) {
      width: 100%;
      right: 0;
      height: 300px;
      background-position: center;
    }
    .nav-block {
      margin-top: 56px;
      padding-left: 200px;
      @include respond-to(m) {
        padding-left: 0px;
        margin-top: -20px;
      }
      .controls {
        justify-content: center;
      }

    }
    .social-resp_bg {
      position: absolute;
      margin-top: 24%;
      left: -40%;
      width: 90%;
      height: 100%;
      display: block;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      pointer-events: none;
      padding-bottom: 100%;
      opacity: .5;
    }
  } 
  .medal_slider {
    height: 100%;
    padding: 40px 0;
    @include respond-to(m) {
      padding: 0;
      position: relative;
      height: 130%;
      top: -15%;
    }
    
  }
  .medal-item {
    height: 240px;
    display: flex;
    align-items: center;
    position: relative;
    @include respond-to(dm) {
      height: 180px;
    }
    @include respond-to(t) {
      height: 180px;
    }
    @include respond-to(m) {
      flex-direction: column;
      height: 100%;
    }
    .medal-logo {
      width: 130px;
      height: auto;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @include respond-to(dm) {
        width: 100px;
      }
      @include respond-to(t) {
        width: 100px;
      }
      @include respond-to(m) {
        margin-top: 5px;
        width: 80px;
      }
    }
    .medal-subscribe {
      width: 82%;
      padding: 100px;
      padding-right: 120px;
      line-height: 1.4;
      @include respond-to(dm) {
        padding: 60px;
        padding-right: 80px;
      }
      @include respond-to(t) {
        padding: 60px;
        padding-right: 80px;
      }
      @include respond-to(m) {
        padding: 30px;
        padding-left: 60px;
        width: 100%;
      }
      .medal-header {
        font-family: $FontIBMPlexSansSemiBold;
        font-size: 22px;
        color: #fff;
        position: relative;
        @include respond-to(dm) {
          font-size: 16px;
        }
        @include respond-to(t) {
          font-size: 16px;
        }
        @include respond-to(t) {
          margin-bottom: 22px;
        }
        &:before {
          content: '';
          position: absolute; 
          top: 10px;
          height: 11px;
          width: 11px;
          background: #779EB5;
          left: -35px;
        }
      }
      .medal-text {
        font-family: $FontIBMPlexSansMedium;
        font-size: 20px;
        color: #779093;
        @include respond-to(dm) {
          font-size: 14px;
        }
        @include respond-to(t) {
          font-size: 14px;
        }
      }
    }
  }
}

.document-list {
  position: relative;
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 1170px;
  margin: auto;
  padding-top: 300px;
  @include respond-to(dm) {
    width: 870px;
    padding-top: 220px;
  }
  @include respond-to(t) {
    width: 540px;
    padding-top: 350px;
  }
  @include respond-to(m) {
    width: 100%;
  }
  &_section {
    min-height: 100vh;
    height: auto;
  }
  &_header {
    position: absolute;
    left: 0;
    top: 0;
    @include respond-to(t) {
      top: 15%;
      left: 30px;
    }
    @include respond-to(m) {
      top: 21%;
      padding-left: 50px;
      left: 30px;
      width: 230px;
    }
  }
}
.doc-list {
  position: relative;
  width: 1060px;
  margin-bottom: 150px;
  @include respond-to(dm) {
    width: 680px;
    margin-right: 100px;
  }
  @include respond-to(t) {
    width: 100%;
  }
  @include respond-to(m) {
    width: 94%;
    margin: auto;
    margin-bottom: 100px;
  }

  &_title {
    font-family: $FontIBMPlexSansSemiBold;
    font-size: 30px;
    margin-bottom: 50px;
    color: #0B363C;
    @include respond-to(dm) {
      font-size: 24px;
      margin-bottom: 40px;
    }
    @include respond-to(t) {
      font-size: 24px;
      margin-bottom: 40px;
    }
    @include respond-to(m) {
      margin-left: 20px;
      font-size: 16px;
    }
  }
  &_wrap {
    overflow: hidden;
    height: auto;
    transition: height 1s ease;
    &.close {
      height: 224px;
      transition: height 1s ease;
      @include respond-to(dm) {
        height: 160px;
      }
      @include respond-to(t) {
        height: 160px;
      }
    }
  }
  &_item {
    height: 112px;
    width: 100%;
    background-color: rgba(#4E838C, .05);
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 88px;
    transition: all .5s ease;
    color: #546467;
    @include respond-to(dm) {
      font-size: 16px;
      height: 80px;
      padding-left: 55px;
    }
    @include respond-to(t) {
      font-size: 16px;
      height: 80px;
      padding-left: 55px;
    }
    &:hover {
      background-color: #EAF1F3;
      &:after {
        opacity: .3;
      }
    }
    &:before {
      content: '';
      position: absolute;
      height: 10px;
      width: 10px;
      background: #779EB5;
      left: 60px;
      top: 45%;
      @include respond-to(dm) {
        left: 34px;
      }
      @include respond-to(t) {
        left: 34px;
      }
    }
    &:after {
      content: url('/static/img/general/background/arrow-right.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      height: 25px;
      width: 25px;
      top: 36%;
      right: 5%;
      transform: scale(0.7);
      transition: all .5s ease;
      opacity: 0;
    }
  }
  .btn__open-list {
    position: absolute;
    right: 0;
    margin-top: 40px;
    width: 70px;
    height: 70px;
    background: #326B74;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .1s ease;
    transform: rotateX(0deg);
    &:hover {
      background: rgba(#326B74, 0.3);
    }
    &.close {
      transform: rotateX(180deg);
    }
    @include respond-to(d) {
      width: 54px;
      height: 54px;
    }
    @include respond-to(t) {
      margin-top: 0;
    }
    svg {
      @include respond-to(d) {
        width: 25px;
        height: 25px;
      }
    }
    
  }
}

