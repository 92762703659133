
article {
  width: 555px;
  margin: auto;
  padding-bottom: 120px;
  padding-top: 120px;
  @include respond-to(d) {
    padding-bottom: 70px;
    padding-top: 70px;
  }
  @include respond-to(t) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  *:first-child {
    margin-top: 0 !important;
  }
  @include respond-to(d) {
    width: 420px;
  }
  @include respond-to(m) {
    width: 240px
  }
  h1, h2, h3, h4, h5, h6 {
    font-size: 24px;
    line-height: 36px;
    margin: 60px 0;
    position: relative;
    font-family: $FontIBMPlexSansSemiBold;
    color: #0B363C;
    @include respond-to(d) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
    }
    @include respond-to(m) {
      padding-left: 20px;
    }
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 8px;
      top: 0;
      left: -35px;
      background: #DDE4E3;
      @include respond-to(m) {
        left: 0;
      }
    }

  }
  p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 21px;
    }
  }
  b, strong {
    font-family: $FontIBMPlexSansSemiBold;
  }
  i {
    font-family: $FontIBMPlexSansItalic;
  }
  ul {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 21px;
    }
    li {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: #779EB5;
        margin-right: 20px;
        margin-left: 0;
        flex: 0 0 auto;
        @include respond-to(d) {
          width: 6px;
          height: 6px;
        }
      }
    }
  }

  ol {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 21px;
    }

    li {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      counter-increment: item;
      &:before {
        content: counter(item) ".";
        width: 28px;
        height: 8px;
        margin-left: 0;
        flex: 0 0 auto;
        font-family: $FontIBMPlexSansBold;
        font-size: .8em;
        @include respond-to(d) {
          width: 26px;
          height: 6px;
        }

      }
    }
  }
  a {
    color: #78B4BD;
    text-decoration: underline;
    transition: color .5s;
    @include hover {
      color: #12474F;
    }
  }
  img {
    // max-width: 130%;
    // max-height: 100%;
    display: block;
    margin: 50px auto;
    position: relative;
    // left: -13%;
    width: 100%;
  }
  .slider {
    margin: 120px 0 100px;
    width: 100%;
    @include respond-to(d) {
      // margin: 70px -100px 50px;
    }
    @include respond-to(m) {
      // margin: 50px -20px 50px;
    }
  }
  .slider-item {
    height: 450px;
    @include respond-to(d) {
      height: 350px;
    }
    @include respond-to(m) {
      height: 170px;
    }
    img {
      display: block;
      margin: auto;
      width: 100%;
      left: unset;
    }
  }
  .slider-control {
    justify-content: center;
    padding-left: 260px;
    // margin-top: 50px;
    margin-bottom: 50px;
    @include respond-to(d) {
      margin-top: 0;
      margin-bottom: 50px;
    }
    @include respond-to(m) {
      //justify-content: flex-end;
      padding-right: 20px;
      padding-left: 20px;
    }
    .btn_prev, .btn_next {
      cursor: pointer;
    }
  }
}

.news-head {

  margin: auto;
  margin-top: 300px;
  width: 1417px;
  padding-left: 67px;

  @include respond-to(d) {
    width: 890px;
    padding-left: 0;
    margin-top: 160px;
  }
  @include respond-to(t) {
    width: 616px;

  }
  @include respond-to(m) {
    width: 100%;

    margin-top: 100px;
  }
  &_title {
    padding-left: 100px;
    position: relative;
    padding-right: 200px;
    @include respond-to(d) {
      padding-left: 50px;
    }
    @include respond-to(t) {
      margin-right: -30px;
      padding-right: 0;
    }
    @include respond-to(m) {
      padding-right: 55px;
      padding-left: 55px;
      margin: 0 20px;
      min-height: 120px;
    }
  }
  &_category {
    color: #546467;
    opacity: .44;
    font-size: 20px;
    line-height: 25px;
    font-family: $FontIBMPlexSansSemiBold;
    margin-bottom: 30px;
    @include respond-to(d) {
      margin-bottom: 10px;
    }
    @include respond-to(m) {
      font-size: 16px;
    }
  }
  &_date {

    width: 206px;
    height: 40px;
    font-size: 16px;
    font-family: $FontIBMPlexSansBold;
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 13px;
    position: absolute;
    top: 10px;
    left: 0px;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: left top;
    background: #BBADA0;
    @include respond-to(d) {
      left: -25px;
      width: 134px;
      height: 34px;
      font-size: 13px;
    }
    @include respond-to(m) {
      left: 0;
      width: 116px;
      font-size: 13px;
    }
  }
  .scroll-rect {
    width: 1494px;
    height: 429px;
    position: absolute;
    background: #A0AAB2;
    opacity: 0.2;
    z-index: -1;
    top: 50px;
    left: -40px;
    @include respond-to(d) {
      width: 986px;
      height: 283px;
      left: -30px;
    }
    @include respond-to(t) {
      width: 682px;
      height: 195px;
    }
    @include respond-to(t) {
      display: none;
    }
  }
  &_imege {
    width: 100%;
    height: 590px;
    background-position: center;
    background-size: cover;
    margin-top: 190px;
    position: relative;
    @include respond-to(d) {
      height: 390px;
      margin-top: 95px;
    }
    @include respond-to(t) {
      height: 270px;
    }
    @include respond-to(m) {
      height: 200px;
      margin-top: 45px;
    }
  }
}

.news-bottom {
  border-top: 1px #E9E8E4 solid;

  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 115px;
  width: 870px;
  @include respond-to(d) {
    width: 720px;
    height: 100px;
  }
  @include respond-to(t) {
    width: 670px;
  }
  @include respond-to(m) {
    width: 290px;
    height: 60px;
  }
  .ico svg {
    fill: #B4BBBC;
  }
  &_date {
    font-size: 20px;
    color: #B4BBBC;
    font-family: $FontIBMPlexSansSemiBold;
    @include respond-to(d) {
      font-size: 16px;
    }
    @include respond-to(m) {
      font-size: 15px;
    }
  }
}

.news-form {
  background: #175059;
  margin: auto;
  width: 870px;
  padding: 107px 119px 112px 135px;
  position: relative;
  color: #fff;
  margin-bottom: 110px;
  margin-top: 70px;
  @include respond-to(d) {
    width: 720px;
    margin-top: 40px;
    margin-bottom: 130px;
    padding: 107px 99px 102px 105px;

  }
  @include respond-to(t) {
    width: 670px;
    padding: 107px 79px 102px 77px;
    margin-bottom: 0;
  }
  @include respond-to(m) {
    width: 290px;
    padding: 50px 35px 50px 35px;
    margin-top: 30px;
  }

  &_right {
    display: flex;
    justify-content: flex-end;
  }
  .form-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 76px;
    margin-bottom: 74px;
    @include respond-to(d) {
      margin-bottom: 55px;
      margin-top: 70px;
    }
    @include respond-to(m) {
      margin: 0px;
      display: block;
    }
    .form-group {
      width: 310px;
      @include respond-to(d) {
        width: 300px;
      }
      @include respond-to(m) {
        width: 100%;
      }
    }
  }
  .form-group {
    width: 100%;
    position: relative;
    @include respond-to(m) {
      margin-bottom: 47px;
    }
    &.tel {
      width: 242px;
      @include respond-to(d) {
        width: 180px;
      }
      @include respond-to(m) {

      }
    }
  }
  .help-block {
    position: absolute;
    top: 100%;
    left: 0;
    color: #ED8080;
    font-size: 14px;
    font-family: $FontIBMPlexSansMedium;
    opacity: 0;
    transition: opacity .5s;
    background: rgba(255, 14, 14, 0.2);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 19px;
    margin-top: 8px;
    @include respond-to(d) {
      font-size: 13px;
      height: 40px;
    }
    @include respond-to(m) {
      font-size: 11px;
      margin-top: 4px;
      height: 30px;
    }
  }
  .has-error {
    .help-block {
      opacity: 1;
    }
  }
  .form-control {
    width: 100%;
    border-bottom: 4px rgba(78, 131, 140, 0.2) solid;
    background: none;
    color: #fff;
    font-size: 20px;
    font-family: $FontIBMPlexSansMedium;
    height: 50px;
    @include respond-to(d) {
      font-size: 16px;
      height: 35px;
      border-bottom: 3px rgba(78, 131, 140, 0.2) solid;
    }
    &::placeholder {
      color: #4E838C;
    }
    &:focus {
      border-bottom: 3px #4E838C solid;
    }
  }
  .success-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 115px 130px 130px 130px;
    background: #124851;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    @include respond-to(d) {
      padding: 75px 95px 95px 95px;
    }
    @include respond-to(m) {
      padding: 56px 30px 70px 30px;
    }
  }
  .btn {
    @include respond-to(d) {
      width: 200px;
    }
    @include respond-to(m) {
      margin: auto;
    }
  }
}