.client-top_section {
  background-size: cover;
  display: flex;
  align-items: center;
  padding-top: 80px;
  @include respond-to(d) {
    padding-top: 0px;
    padding-bottom: 120px;
  }
  @include respond-to(t) {
    height: 730px;
    padding-bottom: 0;
  }
  @include respond-to(m) {
    height: 510px;
    padding-bottom: 120px;

    padding-top: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #153E44 15%, rgba(25, 67, 73, 0) 100%);
    transition: top 1s ease;
    z-index: 2;

  }
  .home-top_bg {
    height: 50%;
  }
}

.client-top {
  z-index: 2;
  @include respond-to(t) {
    width: 550px;
  }
  @include respond-to(m) {
    width: 230px;
  }
}

.client-terms_section {
  background: #153E44;
  padding: 1px;
  z-index: 3;
  height: auto;
  padding-bottom: 110px;
  min-height: auto;
  @include respond-to(d) {
    padding-bottom: 100px;
  }
}

.client-terms {
  display: flex;
  justify-content: space-between;
  margin-top: -110px;
  @include respond-to(d) {
    margin-top: -165px;
  }
  @include respond-to(t) {
    flex-direction: column;
  }
  &_bg {
    position: absolute;
    top: 0;
    left: 35px;
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
    pointer-events: none;
    svg {
      opacity: 0.05;
      fill: #FEFEFE;
    }
    @include respond-to(m) {
      left: 0;
    }
  }
  &_left {
    width: 795px;
    padding-left: 28px;
    @include respond-to(d) {
      width: 625px;
    }
    @include respond-to(t) {
      width: 565px;
      margin-left: -15px;
      padding-left: 0;
    }
    @include respond-to(m) {
      width: 220px;
      margin-left: 0;
      .list_title:before {
        display: none;
      }
    }
  }
  &_list {
    margin-top: 130px;
    color: #fff;
    @include respond-to(d) {
      margin-top: 70px;
    }
    .list_text {
      width: 100%;
      //@include respond-to(d) {
      //  width: 260px;
      //}
    }
    .list_li {
      width: 50%;
      padding-right: 60px;
      @include respond-to(d) {
        
      }
      @include respond-to(m) {
        width: 100%;
        padding: 0;
      }
    }
  }
  &_scroll {
    position: absolute;
    width: 517px;
    height: 495px;
    opacity: 0.1;
    background: #4E838C;
    left: -40px;
    top: 40px;
    @include respond-to(d) {
      width: 341px;
      height: 326px;
    }
    @include respond-to(d) {
      width: 225px;
      height: 214px;
    }
  }
  &_right {
    width: 639px;
    height: 654px;
    position: relative;
    transition: opacity .5s;
    margin-right: -180px;
    z-index: 2;
    @include respond-to(d) {
      width: 539px;
      height: 473px;
      margin-right: -110px;
    }
    @include respond-to(t) {
      margin-bottom: -210px;
      margin-top: 40px;
    }
    @include respond-to(m) {
      width: 287px;
      height: 252px;
      margin-right: 0;
      margin-left: -30px;
      margin-bottom: -130px;

    }

    &.anim {
      .cut-image-in {
        width: 0;
      }
    }
  }
}

.client-dbl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 1240px;
  padding-right: 80px;
  @include respond-to(d) {
    padding-right: 0;
    padding-left: 70px;
    width: 870px;
  }
  @include respond-to(t) {
    flex-direction: column;
    padding-left: 0;
    width: 520px;
  }
  @include respond-to(m) {
    width: 250px;
  }
  .title-h2 {
    font-size: 48px;
    letter-spacing: -0.67px;
    line-height: 62.4px;
    @include respond-to(d) {
      font-size: 36px;
      letter-spacing: -0.5px;
      line-height: 46.8px;
    }
    @include respond-to(t) {
    }
    @include respond-to(m) {
      font-size: 26px;
      letter-spacing: -0.36px;
      line-height: 33.8px;
    }
  }
  &_text {
    width: 469px;
    line-height: 30px;
    font-size: 20px;
    margin-top: 55px;
    margin-bottom: 65px;
    @include respond-to(d) {
      font-size: 14px;
      line-height: 21px;
      width: 340px;
      margin-top: 40px;
      margin-bottom: 45px;
    }
    @include respond-to(m) {
      width: 240px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  &_left {
    position: relative;
    &:before {
      @extend %bg-dot;
      content: '';
      position: absolute;
      left: -300px;
      top: 80px;
      width: 217px;
      height: 209px;
      opacity: 0.2;
      @include respond-to(d) {
        width: 150px;
        height: 150px;
      }
      @include respond-to(m) {
        display: none;
      }
    }
    @include respond-to(t) {
      margin-top: 280px;
      align-self: flex-start;
    }
    @include respond-to(m) {
      margin-top: 160px;
    }
  }
  &_right {
    margin-bottom: -290px;
    position: relative;
    &:before {
      @extend %bg-dot;
      content: '';
      position: absolute;
      right: -220px;
      bottom: 20px;
      width: 217px;
      height: 209px;
      opacity: 0.2;
      @include respond-to(d) {
        width: 150px;
        height: 150px;
        right: -120px;
        bottom: 50px;
      }
      @include respond-to(m) {
        display: none;
      }
    }
    @include respond-to(t) {
      margin-top: 180px;
      margin-bottom: 0px;
      align-self: flex-end;
    }
    @include respond-to(m) {
      margin-top: 95px;
      align-self: flex-start;
    }
  }
  .scroll-rect {
    width: 493px;
    height: 545px;
    background: #EDF2F3;
    position: absolute;
    top: -77px;
    left: 63px;
    z-index: -1;
    @include respond-to(d) {
      width: 340px;
      height: 390px;
      top: -57px;
      left: 23px;
    }
    @include respond-to(m) {
      width: 250px;
      height: 330px;
      top: -57px;
      left: 23px;
    }
  }
  &_scroll-right {
    background: #EAF3F7 !important;
  }

}

.client-why {
  @include respond-to(t) {
    width: 510px;
    margin-bottom: 160px;
  }
  @include respond-to(m) {
    width: 240px;
    margin-bottom: 70px;
  }
}